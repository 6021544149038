import { AgeRange } from '../shared/models/age-range';
import { Package } from '../shared/models/package/package';
import { PackagePrice } from '../shared/models/package/package-price';
import { Participant } from '../shared/models/participant/participant';
import { DateUtils } from './date-utils';

export class PackageUtils {
  public static ageRange(packages: Package[]) {
    const ageRanges = packages.map((pck) => pck.ageRange);
    const minAge =
      ageRanges.reduce((ageRange1, ageRange2) =>
        ageRange1.start < ageRange2.start ? ageRange1 : ageRange2
      ).start || 0;
    const maxAge =
      ageRanges.reduce((ageRange1, ageRange2) =>
        ageRange1.end > ageRange2.end ? ageRange1 : ageRange2
      ).end || 0;

    return `${minAge} - ${maxAge} ans`;
  }

  public static ageMin(packages: Package[]) {
    if (!packages.length) {
      return 0;
    }

    const ageRanges = packages.map((pck) => pck.ageRange);
    return ageRanges.reduce((ageRange1, ageRange2) =>
      ageRange1.start < ageRange2.start ? ageRange1 : ageRange2
    ).start;
  }

  public static getAgeRangeForParticipant(
    ageRanges: AgeRange[],
    participant: Participant
  ) {
    const age = participant.age;
    return ageRanges.find(
      (ageRange) => ageRange.start <= age && ageRange.end >= age
    )?.name;
  }

  public static ageMax(packages: Package[]) {
    if (!packages.length) {
      return 0;
    }

    const ageRanges = packages.map((pck) => pck.ageRange);
    return ageRanges.reduce((ageRange1, ageRange2) =>
      ageRange1.end > ageRange2.end ? ageRange1 : ageRange2
    ).end;
  }

  public static minPriceString(
    packages: Package[],
    participants: Participant[]
  ) {
    const pcksFilteredByParticipants = this.filterPackagesByParticipants(
      packages,
      participants
    );

    if (!pcksFilteredByParticipants.length) {
      return 0;
    }

    const minPricePackage = this.findMinPricePackage(
      pcksFilteredByParticipants
    );
    return this.findMinPriceForParticipantsRange(minPricePackage, participants);
  }

  public static minPriceForAllParticipantsString(
    packages: Package[],
    participants: Participant[]
  ) {
    if (!participants?.length) {
      return 0;
    }

    return participants
      .map((participant) => {
        const pcksFilteredByParticipants = this.filterPackagesByParticipants(
          packages,
          [participant]
        );

        if (!pcksFilteredByParticipants.length) {
          return 0;
        }

        const minPricePackage = this.findMinPricePackage(
          pcksFilteredByParticipants
        );
        return this.findMinPriceForParticipantsRange(
          minPricePackage,
          participants
        );
      })
      .reduce((prev, curr) => prev + curr);
  }

  public static sortByAges(packages: Package[], participants: Participant[]) {
    return packages
      .filter((pck) => this.getParticipantsForPackage(pck, participants).length)
      .sort((prev, curr) =>
        prev.ageRange.start < curr.ageRange.start ? -1 : 1
      );
  }

  public static getParticipantsForPackage(
    pck: Package,
    participants: Participant[]
  ) {
    return participants
      .filter((participant) =>
        DateUtils.isSameCategoryByBirthdate(participant.birthdate, pck.ageRange)
      )
      .sort((prev, curr) => (prev.index > curr.index ? 1 : -1));
  }

  public static getMinParticipants(pcks: Package[]) {
    return pcks
      .map((pck) => pck.prices)
      .reduce((prev, curr) => [...prev, ...curr])
      .reduce((prev, curr) => (prev.nbMin <= curr.nbMin ? prev : curr)).nbMin;
  }

  public static getMaxParticipants(pcks: Package[]) {
    return pcks
      .map((pck) => pck.prices)
      .reduce((prev, curr) => [...prev, ...curr])
      .reduce((prev, curr) => (prev.nbMax >= curr.nbMax ? prev : curr)).nbMax;
  }

  public static filterPackagesByParticipants(
    packages: Package[],
    participants: Participant[]
  ) {
    return packages
      .filter((pck) => this.getParticipantsForPackage(pck, participants).length)
      .filter((pck) => pck.minPrice > 0);
  }

  public static findMinPricePackage(packages: Package[]) {
    return packages.reduce((pck1, pck2) =>
      pck1.minPrice < pck2.minPrice ? pck1 : pck2
    );
  }

  public static findMinPrice(prices: PackagePrice[]) {
    return prices.reduce((prev, curr) =>
      prev.price < curr.price ? prev : curr
    ).price;
  }

  public static findMinPriceForParticipantsRange(
    minPackage: Package,
    participants: Participant[]
  ) {
    const participantsForPackage = this.getParticipantsForPackage(
      minPackage,
      participants
    );

    const prices = minPackage.prices.filter(
      (price) => participantsForPackage.length >= price.nbMin
    );

    if (!prices.length) {
      return minPackage.prices.reduce((prev, curr) =>
        prev.price < curr.price ? prev : curr
      ).price;
    }

    return prices.reduce((prev, curr) =>
      prev.price < curr.price ? prev : curr
    ).price;
  }

  public static filterCheapestPackagesByParticipants(
    packages: Package[],
    participants: Participant[]
  ) {
    return PackageUtils.findMinPricePackage(
      PackageUtils.filterPackagesByParticipants(packages, participants)
    );
  }

  public static findMinPriceforMaxPackage(
    packages: Package[],
    participants: Participant[]
  ) {
    const eligiblePackages = this.filterPackagesByParticipants(
      packages,
      participants
    );

    const packagesToCheck = eligiblePackages?.length
      ? eligiblePackages
      : packages;

    const maxPackage = packagesToCheck.reduce((prev, curr) =>
      prev.minPrice > curr.minPrice ? prev : curr
    );

    return this.findMinPriceForParticipantsRange(maxPackage, participants);
  }

  public static countEligibleParticipants(
    packages: Package[],
    participants: Participant[]
  ): number {
    const eligibleParticipants = participants.filter((participant) =>
      packages.some((pck) =>
        DateUtils.isSameCategoryByBirthdate(participant.birthdate, pck.ageRange)
      )
    );

    return eligibleParticipants.length;
  }
}
