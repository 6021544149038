import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { AtomicLibModule } from 'atomic-lib';
import { FooterModule } from '../footer/footer.module';
import { SharedModule } from '../shared/shared.module';
import { NgxTranslateModule } from '../store/translate.module';
import { CartContentComponent } from './cart-content/cart-content.component';
import { CartRoutingModule } from './cart-routing.module';
import { CartComponent } from './cart.component';
import { CartState } from './cart.state';

@NgModule({
  declarations: [CartComponent, CartContentComponent],
  imports: [
    NgxTranslateModule,
    SharedModule,
    FooterModule,
    CartRoutingModule,
    CommonModule,
    AtomicLibModule,
    NgOptimizedImage
  ],
  exports: [CartComponent, CartContentComponent],
  providers: [CartState]
})
export class CartModule {}
