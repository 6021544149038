<div *ngIf="promoCode"
     class="flex flex-row {{ !paymentPage || !windowResource.isDesktop() ? 'justify-center' : 'justify-end' }} items-center">
  <ng-container *ngIf="isPromoCodeApplied() as text">
    <p *ngIf="!paymentPage || !windowResource.isDesktop()" class="{{ colorMsg }} ">{{ text }}</p>
  </ng-container>
  <atc-tag [color]="colorMsg">{{ promoCode?.name }} : {{ promoCode?.displayAppliedNumber }}</atc-tag>
  <atc-button (click)="deletePromoCode()"
              color="simple"
              icon="trash">
  </atc-button>
</div>

<ng-container *ngIf="!promoCode">
  <div class="vsk__inline">
    <atc-input (enterKeyPressed)="addPromoCode()"
               [control]="control"
               placeholder="Code promotionnel">
    </atc-input>
    <atc-button (click)="addPromoCode()"
                color="ternary"
                icon="add">
    </atc-button>
  </div>
</ng-container>
<ng-container *ngIf="isPromoCodeApplied() as text">
  <p *ngIf="paymentPage && windowResource.isDesktop()"
     class="{{ colorMsg }} flex-1 text-right mt-[-0.5rem]">{{ text }}</p>
</ng-container>
