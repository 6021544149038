import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { FiltersState } from '../filters.state';
import { AccommodationService } from '../service/accommodation.service';
import { ExperienceGroupService } from '../service/experience-group.service';
import { ExperienceService } from '../service/experience.service';
import { SkiEquipmentService } from '../service/ski-equipment.service';
import { TemporaryCartExperience } from '../shared/interface/temporary-cart-experience';
import { Establishment } from '../shared/models/accommodation/establishment';
import { Activity } from '../shared/models/activity/activity';
import { Experience } from '../shared/models/activity/experience';
import { ExperienceGroup } from '../shared/models/activity/experience-group';
import { ActivityFilters } from '../shared/models/const/activity-filters';
import { Package } from '../shared/models/package/package';
import { EquipmentResponse } from '../shared/models/ski-equipment/equipment-response';
import { Pack } from '../shared/models/ski-equipment/pack';
import {
  FetchEquipmentPacks,
  FetchEstablishments,
  FetchExperience,
  FetchExperiences,
  FetchExperiencesGroup,
  FetchSimilarExperiences,
  FiltersResortChangeOrderBy,
  ResetStoreState,
  ResortName,
  SetNavbarSticky,
  TemporaryExperienceCart
} from './resort.action';

export interface ResortStateModel {
  experience: Experience | null;
  allExperiences: Experience[];
  experiencesGroup: ExperienceGroup[];
  similarExperiences: Experience[];
  packages: Package[];
  name: string | undefined;
  establishments: Establishment[];
  filtersResortChangeOrderBy: ActivityFilters;
  activities: Activity[];
  equipmentPacks: Pack[];
  temporaryCartExperience: TemporaryCartExperience | null;
  navbarSticky: boolean;
}

@State<ResortStateModel>({
  name: 'resort',
  defaults: {
    allExperiences: [],
    experiencesGroup: [],
    experience: null,
    similarExperiences: [],
    packages: [],
    name: undefined,
    establishments: [],
    filtersResortChangeOrderBy: ActivityFilters.PERTINENCE,
    activities: [],
    equipmentPacks: [],
    temporaryCartExperience: null,
    navbarSticky: false
  }
})
@Injectable()
export class ResortState {
  constructor(
    private experienceService: ExperienceService,
    private experienceGroupService: ExperienceGroupService,
    private accommodationService: AccommodationService,
    private skiEquipmentService: SkiEquipmentService,
    private store: Store
  ) {}

  @Selector()
  static experience(state: ResortStateModel): Experience | null {
    return state.experience;
  }

  @Selector()
  static experiences(state: ResortStateModel): Experience[] {
    return state.allExperiences;
  }

  @Selector()
  static experiencesGroup(state: ResortStateModel): ExperienceGroup[] {
    return state.experiencesGroup;
  }

  @Selector()
  static establishments(state: ResortStateModel): Establishment[] {
    return state.establishments;
  }

  @Selector()
  static resortName(state: ResortStateModel): string | undefined {
    return state.name;
  }

  @Selector()
  static equipmentPacks(state: ResortStateModel): Pack[] {
    return state.equipmentPacks;
  }

  @Selector()
  static temporaryCartExperience(
    state: ResortStateModel
  ): TemporaryCartExperience | null {
    return state.temporaryCartExperience;
  }

  @Selector()
  static navbarSticky(state: ResortStateModel): boolean {
    return state.navbarSticky;
  }

  @Selector()
  static similarExperiences(state: ResortStateModel): Experience[] {
    return state.similarExperiences;
  }

  @Action(ResortName)
  resortName(ctx: StateContext<ResortStateModel>, action: ResortName): void {
    ctx.patchState({
      name: action.name
    });
  }

  @Action(TemporaryExperienceCart)
  temporaryCartExperience(
    ctx: StateContext<ResortStateModel>,
    action: TemporaryExperienceCart
  ): void {
    ctx.patchState({
      temporaryCartExperience: {
        ...ctx.getState().temporaryCartExperience,
        ...action.temporaryCartExperience
      }
    });
  }

  @Action(FetchExperiences)
  fetchExperiences(
    ctx: StateContext<ResortStateModel>,
    action: FetchExperiences
  ) {
    return this.experienceService
      .getExperiencesByResortName(
        action.criteria,
        ctx.getState().filtersResortChangeOrderBy
      )
      .pipe(
        tap((experiences) => {
          ctx.patchState({
            allExperiences: experiences
          });
        })
      );
  }

  @Action(FetchSimilarExperiences)
  fetchSimilarExperiences(
    ctx: StateContext<ResortStateModel>,
    action: FetchSimilarExperiences
  ) {
    const criteria = this.store.selectSnapshot(FiltersState.criteria);
    return this.experienceService
      .getSimilarExpByActivity({
        ...criteria,
        activities: [action.activityId],
        isValid: true
      })
      .pipe(
        tap((experiences) => {
          ctx.patchState({
            similarExperiences: experiences
          });
        })
      );
  }

  @Action(FetchExperiencesGroup)
  fetchExperiencesGroup(
    ctx: StateContext<ResortStateModel>,
    action: FetchExperiencesGroup
  ) {
    return this.experienceGroupService
      .getByCriteria(
        action.filtersExperienceSearch,
        ctx.getState().filtersResortChangeOrderBy,
        action.type,
        action.page
      )
      .pipe(
        tap((experiencesGroup) => {
          const expGroups = action.init
            ? experiencesGroup
            : [...ctx.getState().experiencesGroup, ...experiencesGroup];
          ctx.patchState({
            experiencesGroup: expGroups
          });
        })
      );
  }

  @Action(FetchExperience)
  fetchExperience(
    ctx: StateContext<ResortStateModel>,
    action: FetchExperience
  ) {
    if (action.experienceId) {
      this.experienceService
        .getExperienceById(
          this.store.selectSnapshot(FiltersState.criteria),
          action.experienceId
        )
        .subscribe((experience) =>
          ctx.patchState({
            experience
          })
        );
    }
  }

  @Action(FetchEstablishments)
  fetchEstablishments(
    ctx: StateContext<ResortStateModel>,
    action: FetchEstablishments
  ) {
    return this.accommodationService
      .getEstablishmentsByFilters(action.filters, action.page)
      .pipe(
        tap((establishments) => {
          const establishmentsResult = action.reset
            ? [...establishments]
            : [...ctx.getState().establishments, ...establishments];
          ctx.patchState({
            establishments: establishmentsResult
          });
        })
      );
  }

  @Action(ResetStoreState)
  resetState(ctx: StateContext<ResortStateModel>) {
    ctx.setState({
      activities: [],
      filtersResortChangeOrderBy: ActivityFilters.PERTINENCE,
      experience: null,
      allExperiences: [],
      experiencesGroup: [],
      similarExperiences: [],
      packages: [],
      name: undefined,
      establishments: ctx.getState().establishments,
      equipmentPacks: [],
      temporaryCartExperience: null,
      navbarSticky: false
    });
  }

  @Action(FetchEquipmentPacks)
  fetchEquipmentPacks(
    ctx: StateContext<ResortStateModel>,
    action: FetchEquipmentPacks
  ) {
    return this.skiEquipmentService
      .getPacksByStationAndDuration(
        action.resortName ? action.resortName : (ctx.getState().name as string),
        action.duration,
        action.firstSkiDate
      )
      .pipe(
        tap((response: EquipmentResponse) => {
          ctx.patchState({
            equipmentPacks: response.packs
          });
        })
      );
  }

  @Action(FiltersResortChangeOrderBy)
  filtersResortChangeOrderBy(
    ctx: StateContext<ResortStateModel>,
    action: FiltersResortChangeOrderBy
  ) {
    ctx.patchState({
      filtersResortChangeOrderBy: action.activityFilters
    });

    return this.fetchExperiences(
      ctx,
      new FetchExperiences(this.store.selectSnapshot(FiltersState.criteria))
    );
  }

  @Action(SetNavbarSticky)
  setNavbarSticky(
    ctx: StateContext<ResortStateModel>,
    action: SetNavbarSticky
  ) {
    ctx.patchState({
      navbarSticky: action.sticky
    });
  }
}
