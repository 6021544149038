<ng-container *ngIf="getCart(cart$ | async, propositionCart$ | async) as cart">
  <ng-container *ngIf="showCart">
    <atc-side-drawer (closeChange)="closeCart(cart)"
                     bgColor="white"
                     title="Mon panier">
      <!-- body -->
      <div class="flex flex-col pb-4" header>
        <img alt="Logo"
             class="m-auto mt-4 h-[30px] w-[50px]"
             srcset="assets/images/logo-mobile.svg" />
        <h2>
          <ng-container *ngIf="!cart.isProposition">Mon panier</ng-container>
          <ng-container *ngIf="cart.isProposition">Proposition de séjour</ng-container>
          <ng-container *ngIf="isUserAdmin$ | async">• {{ sessionId$ | async }}</ng-container>
        </h2>

        <h3 *ngIf="period$ | async as period">
          <span *ngIf="cart.getResort()">{{ cart.getResort() }} •</span>
          {{ period?.startDate?.format('DD MMMM') }} au
          {{ period?.endDate?.format('DD MMMM') }}
        </h3>
      </div>

      <!-- body -->
      <div body class="p-4 pt-0 xl:px-8">
        <vsk-cart-content (editExperienceChange)="editExperience($event)"
                          (redirectToEstablishmentChanged)="redirectToEstablishment($event, cart.isProposition)"
                          (redirectToExperienceChanged)="redirectToExperience($event, cart.isProposition)"
                          (redirectToMapChanged)="goToMap()"
                          [canDeleteItem]="canDeleteItem"
                          [canEditItem]="canEditItem"
                          [cart]="cart"
                          [isEcoTourismeChecked]="isEcoTourismeChecked$ | async"
                          [loading]="loading">
        </vsk-cart-content>
      </div>

      <!-- footer -->
      <div class="w-full" footer>
        <div
          *ngIf="(windowResource.width() | async | desktop) && !cart.isProposition"
          class="phone">
          <svg>
            <use [attr.xlink:href]="'assets/icons/icons.svg#phone'"></use>
          </svg>
          <p><b>Une question ?</b> Faites-vous accompagner par notre équipe au
            <a class="font-bold" href="tel:+33465847469">04 65 84 74 69</a>
          </p>
        </div>

        <div *ngIf="!cart.isEmpty" class="flex flex-col gap-2 p-4">
          <div class="flex flex-col gap-x-4 xl:mb-4 xl:flex-row">
            <vsk-promo-code
              *ngIf="canAddPromoCode"
              [cart]="cart"
              [promoCode]="cart.promoCode"
              class="xl:flex-1">
            </vsk-promo-code>

            <div class="bg-neutral-20 flex flex-row items-center justify-between rounded-[10px] px-4 py-2 xl:flex-[2]">
              <h3 class="uppercase"> Total du panier </h3>
              <h3>
                <vsk-price [cart]="cart"></vsk-price>
              </h3>
            </div>
          </div>

          <div *ngIf="cart.isProposition" class="flex flex-col gap-y-2">
            <h2>Sélectionnez les éléments qui vous intéressent et ajoutez les à
              votre panier</h2>

            <atc-button (click)="addToCartAndGoToPayment(cart)"
                        [disabled]="cart.getPriceWithPromo() === 0"
                        [fullWidth]="true"
                        [label]="getLabelWithPrice(cart, 'Ajouter et passer au paiement')"
                        class="flex"
                        color="secondary"
                        icon="credit-card">
            </atc-button>

            <atc-button (click)="addToCartAndCloseCart(cart)"
                        [disabled]="cart.getPriceWithPromo() === 0"
                        [fullWidth]="true"
                        [label]="getLabelWithPrice(cart, 'Ajouter au panier')"
                        class="flex"
                        color="ternary"
                        icon="cart">
            </atc-button>
          </div>

          <div *ngIf="!cart.isProposition" class="flex flex-col gap-y-2">
            <atc-button (click)="goToPayment()"
                        *ngIf="canDeleteItem"
                        [fullWidth]="true"
                        [label]="getLabelWithPrice(cart)"
                        class="flex"
                        color="secondary"
                        icon="cart">
            </atc-button>

            <p class="m-0 p-4">
              Annulation gratuite jusqu'à <b>30 jours</b> avant votre séjour.
              Paiement jusqu'à <b>3 fois sans frais.</b>
            </p>
          </div>
        </div>
      </div>
    </atc-side-drawer>
  </ng-container>

  <vsk-experience-drawer (showAlertSuccessChange)="deleteItemEdited()"
                         *ngIf="experienceToEdit && resortExperienceToEdit"
                         [(showDrawer)]="showExperienceDrawer"
                         [cart]="cart"
                         [currentDay]="currentDay"
                         [experience]="experienceToEdit"
                         [participants]="participants$ | async"
                         [period]="period$ | async"
                         [resort]="resortExperienceToEdit">
  </vsk-experience-drawer>
</ng-container>
