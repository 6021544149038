import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild
} from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { WindowResource } from 'atomic-lib';
import { filter } from 'rxjs/operators';
import { FiltersService } from '../../service/filters.service';
import { MetaDescriptionService } from '../../service/meta-description.service';
import { AccommodationTemplateComponent } from '../../shared/component/accommodation-template.component';

@Component({
  selector: 'vsk-resort-accommodation',
  templateUrl: './resort-accommodation.component.html',
  styleUrls: ['./resort-accommodation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResortAccommodationComponent extends AccommodationTemplateComponent {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;

  constructor(
    public activatedRoute: ActivatedRoute,
    public store: Store,
    protected filtersService: FiltersService,
    public windowResource: WindowResource,
    protected changeRef: ChangeDetectorRef,
    public elRef: ElementRef,
    private metaDescriptionService: MetaDescriptionService
  ) {
    super(activatedRoute, store, filtersService, windowResource, changeRef);
    this.pageResort = true;

    this.register(
      this.resort$.pipe(filter((resort) => !!resort)).subscribe((resort) => {
        const title = `Location à ${resort.name} · VeryMountain`;
        const description = `Réservez votre location à ${resort.name} pour un séjour mémorable. Hébergements avec piscine, chalets, appartements, résidences all-inclusive...`;
        const url = `station/hebergements`;
        this.metaDescriptionService.updateMeta(title, description, url);
      })
    );
  }
}
