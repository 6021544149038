import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { Router, RouterEvent } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { WindowResource } from 'atomic-lib';
import moment, { Moment } from 'moment';
import { Observable } from 'rxjs';
import { FiltersState } from 'src/app/filters.state';
import { FetchCart } from '../../cart/cart.action';
import { SetChangingFilters } from '../../filters.action';
import { NavigateService } from '../../service/navigate.service';
import { StationService } from '../../service/station.service';
import { RxjsComponent } from '../../shared/component/rxjs.component';
import { ActivitiesByCategory } from '../../shared/models/activity/activities-by-category';
import { Activity } from '../../shared/models/activity/activity';
import { Participant } from '../../shared/models/participant/participant';
import { Period } from '../../shared/models/period';
import { Resort } from '../../shared/models/resort/resort';
import {
  ActivityPickerOpen,
  AddParticipants,
  ChangeDates,
  ParticipantPickerOpen,
  Search
} from '../navbar.action';
import { NavbarState } from '../navbar.state';

@Component({
  selector: 'vsk-form-date-range-picker',
  templateUrl: './form-date-range-picker.component.html',
  styleUrls: ['./form-date-range-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormDateRangePickerComponent extends RxjsComponent {
  @Select(FiltersState.isFoncia) isFoncia$: Observable<boolean>;

  showGoToResort = false;
  isSearchAccommodation = false;

  @Input() minDate: Moment = moment();
  @Input() maxDate: Moment = moment().add(1, 'year');
  @Input() validationName = '';
  @Input() isInNavbar = false;
  @Input() isDesktop = true;
  @Input() period: Period;
  @Input() hasAlterParticipants = false;
  @Input() activities: Activity[];
  @Input() activitiesByCategories: ActivitiesByCategory[];
  @Input() selectedActivities: Activity[];
  @Input() participants: Participant[];
  @Input() resorts: Resort[];
  @Input() showSearchPopup = false;
  @Input() showDates = false;
  @Input() showActivities = false;
  @Input() showParticipants = false;
  @Input() showFilters = false;
  @Input() withAccommodation = true;
  @Input() withSkiPackage = true;
  @Input() withMaterial = true;
  @Input() isHome = false;
  @Input() searchMode: 'accommodation' | 'all' | 'experience' = 'all';
  @Input() currentPage: 'Home' | 'Map' | 'Resort' | 'none' = 'none';

  @Output() validateSearch = new EventEmitter<void>();

  constructor(
    private store: Store,
    private router: Router,
    private resortService: StationService,
    private navigateService: NavigateService,
    private changeRef: ChangeDetectorRef,
    public windowResource: WindowResource
  ) {
    super();
    this.router.events.subscribe((val) => {
      if (val instanceof RouterEvent) {
        this.showGoToResort =
          val.url.indexOf('/details-station') !== -1 ||
          val.url.indexOf('/resort') !== -1;
        this.isSearchAccommodation =
          val.url.indexOf('/recherche-hebergements') !== -1;
        this.setResorts();
      }
    });
  }

  setDates(dates: Period): void {
    if (!dates) {
      return;
    }

    this.store.dispatch(
      new ChangeDates(
        moment(dates.startDate.set('hour', 0).toDate()),
        moment(dates.endDate.set('hour', 0).toDate())
      )
    );
  }

  openActivityPicker() {
    if (this.isDesktop) {
      this.store.dispatch(new ActivityPickerOpen(true));
      this.store.dispatch(new SetChangingFilters(true));
    }
  }

  setActivities(): void {
    if (this.isDesktop) {
      this.store.dispatch(new ParticipantPickerOpen(true));
      this.store.dispatch(new ActivityPickerOpen(false));
    }
  }

  openParticipantPicker(): void {
    if (this.isDesktop) {
      this.store.dispatch(new ParticipantPickerOpen(true));
    }
  }

  validate(): void {
    const participants = this.store.selectSnapshot(NavbarState.participants);
    participants.forEach((participant) => {
      participant.firstname = participant.firstname?.length
        ? participant.firstname
        : 'Voyageur ' + participant.index;
    });

    this.store.dispatch(new ParticipantPickerOpen(false));
    this.store.dispatch(new ActivityPickerOpen(false));
    this.store.dispatch(new AddParticipants(participants));
    this.validateSearch.emit();

    this.store.dispatch(new Search()).subscribe(() => {
      if (!this.isDesktop) {
        this.store.dispatch(new SetChangingFilters(false));
        this.store.dispatch(new FetchCart());

        if (this.searchMode === 'accommodation') {
          this.navigateService.navigateWithQueryParams(
            '/recherche-hebergements'
          );
          return;
        }

        if (this.searchMode === 'experience') {
          this.navigateService.navigateWithQueryParams('/experiences');
          return;
        }

        if (this.store.selectSnapshot(FiltersState.isFoncia)) {
          this.navigateService.navigateWithQueryParams(
            this.router.url.split('?')[0]
          );
          return;
        }

        this.navigateService.navigateWithQueryParams('/map');
        return;
      }

      this.validateSearch.emit();
    });
  }

  setShowSearch(value: boolean) {
    this.store.dispatch(new SetChangingFilters(value));
  }

  private setResorts() {
    if (this.isSearchAccommodation && !this.resorts?.length) {
      this.resortService.getAllStations().subscribe((resorts) => {
        this.resorts = resorts;
        this.changeRef.markForCheck();
      });
    }
  }
}
