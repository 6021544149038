import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { OptionElement, Range, WindowResource } from 'atomic-lib';
import { AccommodationType } from '../../../shared/models/accommodation/accommodation-type.enum';
import { FiltersStation } from '../../../shared/models/filters/filters-station';

@Component({
  selector: 'vsk-map-filters',
  templateUrl: './map-filters.component.html',
  styleUrls: ['./map-filters.component.scss']
})
export class MapFiltersComponent implements OnChanges {
  @Input() filters: FiltersStation;
  @Input() accommodationTypeFilter: OptionElement<AccommodationType>[];
  @Input() regionsFilter: OptionElement<number>[];
  @Input() tripIncludeFilter: OptionElement<number>[];
  @Input() equipmentsFilter: OptionElement<number>[];
  @Input() accessFilter: OptionElement<number>[];
  @Input() servicesFilter: OptionElement<number>[];
  @Input() tagsFilter: OptionElement<number>[];
  @Input() resortLabelsFilter: OptionElement<number>[];

  @Output() refreshChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() clearFiltersChanged: EventEmitter<void> = new EventEmitter<void>();

  maxValueRange = 1000;
  minValueRange = 0;

  selectedMaxValue: number;
  selectedMinValue: number;

  constructor(public windowResource: WindowResource) {}

  ngOnChanges(): void {
    if (this.filters.accommodationsDisplayed) {
      this.maxValueRange = this.maxValueRange + 10000;
    }

    if (
      this.filters.priceRange?.min != 0 ||
      this.filters.priceRange?.max != 0
    ) {
      this.selectedMaxValue = this.filters.priceRange.max;
      this.selectedMinValue = this.filters.priceRange.min;
    } else {
      this.selectedMaxValue = this.maxValueRange;
      this.selectedMinValue = this.minValueRange;
    }
  }

  optionReceived(filter: Range) {
    this.filters.priceRange.min = filter.min;
    this.filters.priceRange.max = filter.max;
  }

  getControl(control: FormControl<boolean | null> | undefined) {
    return control as FormControl<boolean | null>;
  }
}
