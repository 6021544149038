<div class="flex flex-col justify-between w-[100%] relative p-4
            xl:flex-col xl:px-0 gap-y-4 md:gap-[0px]
            sm:px-[0px] content sm:py-0 py-4">
  <p *ngIf="!loaderExperiences && !filtersInfo?.total" class="w-full text-center p-4">
    Oops ! Aucun forfait ne concorde avec vos dates actuelles.
  </p>

  <div *ngIf="loaderExperiences" class="flex flex-col gap-[20px] pt-4 w-full mx-auto
                                        sm:gap-2 sm:pt-0 sm:px-4
                                        xl:w-[1350px]">
    <div class="sm:gap-2 slg:flex slg:flex-col md:gap-4 md:pb-[10rem] w-[100%]
                lg:pb-[8rem] lg:flex-col gap-4 grid-cols-1 lg:grid-cols-1 lg:grid
                xl:flex-1 xl:grid-cols-1 2xl:grid-cols-1 xl:grid xl:gap-y-8 xl:gap-x-4 xl:h-[400px] xl:pb-[50px] relative">
      <ng-container *ngFor="let nb of [0,1,2,3]">
        <div class="rounded-[20px] h-[950px] w-[100%]" vskSkeletonLoad></div>
      </ng-container>
    </div>
  </div>
  <h1 *ngIf="!loaderExperiences && filtersInfo.total"
      class="sm:text-center md:text-center sm:py-[10px] md:py-[10px]">
    Réservez simplement vos forfaits ·
    <ng-container *ngIf="resort$ | async as resort">
      {{ resort.name }}
    </ng-container>
  </h1>
  <div *ngIf="!loaderExperiences && filtersInfo.total"
       class="flex flex-row gap-x-4 justify-between w-full min-h-full xl:w-[1350px]">


    <div (scrolled)="loadMoreExperiences()"
         [infiniteScrollContainer]="elRef.nativeElement.parentElement"
         [infiniteScrollDistance]="1.5"
         [infiniteScrollThrottle]="300"
         class="w-[100%] max-h-full pb-[35rem]
                    sm:pb-[10rem] sm:gap-2 sm:px-[1rem]
                    md:flex md:flex-col md:gap-4 md:px-[1rem] md:pb-[10rem]
                    lg:flex-col lg:gap-4 grid-cols-1 lg:grid-cols-1 lg:grid
                    xl:flex-1 xl:grid-cols-1
                    2xl:grid-cols-1 xl:grid xl:gap-y-4 gap-x-4"
         infiniteScroll>
      <ng-container *ngFor="let group of experienceGroups">
        <atc-card-resort-scrollable (mouseenter)="overMarker(group.marker)"
                                    (mouseleave)="outMarker(group.marker)"
                                    (openPictures)="pictures = group.pictures"
                                    [cardsLink]="group.linksCard"
                                    [nbElements]="group.experiences.length"
                                    [nbPhotos]="group.pictures.length"
                                    [pictures]="group.pictures"
                                    [subtitle]="group.partnerName"
                                    [title]="group.activityName"
                                    [withHeart]="group.heart">
        </atc-card-resort-scrollable>
      </ng-container>
    </div>

    <google-map
      (zoomChanged)="setZoom()"
      *ngIf="windowResource.width() | async | screen: 'small-desktop-above'"
      [center]="center"
      [options]="options"
      [zoom]="zoom"
      class="flex-1 sticky map-packages"
      height="500px">
      <map-marker
        #mapMarker="mapMarker"
        (mapClick)="openInfoWindow(marker, mapMarker)"
        (mapMouseout)="outMarker(marker)"
        (mapMouseover)="overMarker(marker)"
        *ngFor="let marker of markers"
        [icon]="marker.getIcon()!"
        [label]="marker.getLabel()!"
        [options]="getOptions(marker)"
        [position]="marker.getPosition()!"
        [title]="marker.getTitle()!">
      </map-marker>
      <map-marker
        *ngIf="markerResort"
        [icon]="markerResort.getIcon()!"
        [options]="getOptions(markerResort)"
        [position]="markerResort.getPosition()!"
        [title]="markerResort.getTitle()!">
      </map-marker>

      <map-info-window>
        <atc-card-resort-scrollable (openPictures)="pictures = pinpoint.pictures"
                                    *ngIf="pinpoint"
                                    [cardsLink]="pinpoint.linksCard"
                                    [nbElements]="pinpoint.experiences.length"
                                    [nbPhotos]="pinpoint.pictures.length"
                                    [nbRatings]="pinpoint.nbReviews"
                                    [pictures]="pinpoint.pictures"
                                    [pinpoint]="true"
                                    [starRating]="pinpoint.review"
                                    [subtitle]="pinpoint.partnerName"
                                    [title]="pinpoint.activityName"
                                    [withHeart]="pinpoint.heart">
        </atc-card-resort-scrollable>
      </map-info-window>
    </google-map>
  </div>

  <atc-popup (closeChanged)="pictures = []"
             *ngIf="pictures.length"
             [showBody]="false"
             [slides]="pictures">
  </atc-popup>
</div>

<vsk-footer [isHome]="false"></vsk-footer>
