import { AccommodationPartner } from '../accommodation/accommodation-partner';
import { Experience } from '../activity/experience';
import { Location } from '../location';
import { ResortLabel } from './resort-label';

export class Resort {
  id: number;
  name: string;
  urlLogo: string;
  urlsCover: string[];
  regionLabel: string;
  location: Location;
  experiences: Experience[] = [];
  accommodations: AccommodationPartner[];
  description: string;
  labels: ResortLabel[];
  priceSkiPass: number;
  withAccommodation: boolean;

  constructor(obj: Partial<Resort>) {
    Object.assign(this, obj);
    this.experiences = this.experiences?.map(
      (experience) => new Experience(experience)
    );

    if (this.location) {
      this.location = new Location({ ...this.location });
    }

    if (this.labels) {
      this.labels = this.labels?.map((label) => new ResortLabel({ ...label }));
    }

    this.accommodations = this.accommodations?.map(
      (accommodation) => new AccommodationPartner(accommodation)
    );
  }
}
