import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule } from '@angular/router';
import { AtomicLibModule, ScreenPipe } from 'atomic-lib';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CartModule } from '../cart/cart.module';
import { FooterModule } from '../footer/footer.module';
import { SharedModule } from '../shared/shared.module';
import { NgxTranslateModule } from '../store/translate.module';
import { ResortAccommodationComponent } from './resort-accommodation/resort-accommodation.component';
import { ResortEstablishmentComponent } from './resort-accommodation/resort-establishment/resort-establishment.component';
import { DetailsExperienceComponent } from './resort-activity/details-experience/details-experience.component';
import { ResortActivityComponent } from './resort-activity/resort-activity.component';
import { ResortInformationComponent } from './resort-information/resort-information.component';
import { ResortMaterialIntersportComponent } from './resort-material-intersport/resort-material-intersport.component';
import { ResortMaterialDrawerComponent } from './resort-material/resort-material-drawer/resort-material-drawer.component';
import { ResortMaterialComponent } from './resort-material/resort-material.component';
import { ResortPackageComponent } from './resort-package/resort-package.component';
import { ResortRoutingModule } from './resort-routing.module';
import { ResortSkiClassComponent } from './resort-ski-class/resort-ski-class.component';
import { ResortComponent } from './resort.component';

@NgModule({
  declarations: [
    ResortComponent,
    ResortInformationComponent,
    ResortSkiClassComponent,
    ResortAccommodationComponent,
    ResortMaterialComponent,
    ResortActivityComponent,
    ResortPackageComponent,
    ResortEstablishmentComponent,
    ResortMaterialDrawerComponent,
    DetailsExperienceComponent,
    ResortMaterialIntersportComponent
  ],
  imports: [
    SharedModule,
    NgxTranslateModule,
    FooterModule,
    CommonModule,
    RouterModule,
    ResortRoutingModule,
    AtomicLibModule,
    CartModule,
    GoogleMapsModule,
    InfiniteScrollModule,
    ScreenPipe
  ]
})
export class ResortModule {}
