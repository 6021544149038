import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { Store } from '@ngxs/store';
import {
  StripeElements,
  StripeElementsOptionsClientSecret,
  StripePaymentElement
} from '@stripe/stripe-js';
import { WindowResource } from 'atomic-lib';
import { StripeService } from 'ngx-stripe';
import { environment } from '../../../environments/environment';
import { CartState } from '../../cart/cart.state';
import { FiltersState } from '../../filters.state';
import { PaymentService } from '../../service/payment.service';
import { RxjsComponent } from '../../shared/component/rxjs.component';

@Component({
  selector: 'vsk-step-payment',
  templateUrl: './step-payment.component.html',
  styleUrls: ['./step-payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepPaymentComponent extends RxjsComponent {
  @Input() error: string | undefined;
  @Input() set initForm(init: boolean) {
    if (init) {
      this.loading.emit(true);
      const promoCode = this.store.selectSnapshot(CartState.promoCode);
      const sessionId = this.store.selectSnapshot(FiltersState.sessionId);
      const ecoTourismChart = this.store.selectSnapshot(
        CartState.isEcoTourismChartChecked
      );
      this.paymentService
        .pay(promoCode?.name as string, sessionId, ecoTourismChart)
        .subscribe(
          (result) => {
            if (result) {
              this.clientSecret = result.tokenPayment;
              const options: StripeElementsOptionsClientSecret = {
                clientSecret: this.clientSecret,
                appearance: {
                  theme: 'stripe'
                },
                locale: 'fr-FR'
              };

              this.stripeService.elements(options).subscribe(
                (elements) => {
                  this.elements = elements;
                  this.paymentElement = this.elements.create('payment', {
                    paymentMethodOrder: [
                      'card',
                      'apple_pay',
                      'google_pay',
                      'klarna'
                    ]
                  });
                  this.paymentElement.mount('#payment-element');
                  setTimeout(() => {
                    this.loading.emit(false);
                  }, 400);
                },
                () => window.location.reload()
              );
            }
          },
          (error) => {
            if (error) {
              // Error creating the token
              this.error = error?.message;
            }
          }
        );
    }
  }

  @Output() loading = new EventEmitter<boolean>();
  @Output() previous = new EventEmitter<boolean>();
  @Output() paymentSuccess = new EventEmitter<boolean>();

  isDesktop = true;
  showCart = false;
  clientSecret: string;
  elements: StripeElements;
  paymentElement: StripePaymentElement;

  constructor(
    private stripeService: StripeService,
    private paymentService: PaymentService,
    public windowResource: WindowResource,
    private store: Store
  ) {
    super();
  }

  createToken(): void {
    this.error = '';
    this.loading.emit(true);
    this.pay();
  }

  pay(): void {
    const promoCode = this.store.selectSnapshot(CartState.promoCode);
    const sessionId = this.store.selectSnapshot(FiltersState.sessionId);
    let args = '?sessionIdPaid=' + sessionId;

    if (promoCode) {
      args += '&promoCode=' + promoCode.name;
    }

    this.elements.submit().then((value) => {
      if (value.error) {
        this.error = value.error.message;
        this.loading.emit(false);
        return;
      }

      this.register(
        this.stripeService
          .confirmPayment({
            elements: this.elements,
            clientSecret: this.clientSecret,
            confirmParams: {
              return_url: environment.redirectPayment + args
            }
          })
          .subscribe(
            (value) => {
              this.error = value.error.message;
              this.loading.emit(false);
            },
            () => this.handleErrorPackages()
          )
      );
    });
  }

  handleErrorPackages(): void {
    this.loading.emit(false);
    this.error =
      'Une erreur est survenue lors du paiement, veuillez réessayer dans quelques instants';
  }
}
