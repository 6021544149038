<div class="flex flex-col justify-between w-[100%] max-w-[1350px] mx-auto sxl:px-[30px]
            xl:flex-col gap-[20px] lg:gap-[30px] md:gap-[0px]">

  <ng-container *ngIf="!loading">
    <ng-container *ngIf="resort$ | async as resort">
      <ng-container *ngIf="equipmentPacks$ | async as packs">
        <div *ngIf="packs && packs.length > 0; else noPacksTemplate"
             class="pb-[50px] flex flex-col w-[100%] xl:flex-col
                    lg:gap-[0px] md:gap-[0px] lg:pb-[150px]">

          <!-- Du matériel pour -->
          <div class="card max-w-[1350px]">
            <div class="flex flex-col md:mb-3 md:pb-[20px] md:px-[1rem] flex-1 custom-content">

              <h2 class="w-full py-[20px] sm:px-[20px] "> Location de matériel </h2>

              <div class="w-full pb-[1rem]">
                <h3>1. Du matériel pour :</h3>
                <div class="toggle-participant grid justify-items-start	gap-[1rem] justify-start mb-[1rem]">
                  <ng-container *ngFor="let participant of participants$ | async">
                    <atc-button-toggle (click)="onAgeGroupSelected(participant, packs)"
                                       [icon]="'person'"
                                       [selected]="isSelected(participant)" [size]="'tiny'"
                                       [subtitle]="participant.firstname + ' - ' + participant.ageGroup"
                    >
                    </atc-button-toggle>
                  </ng-container>
                </div>
              </div>

              <!-- Louez votre matériel -->
              <div class="w-full">
                <h3 class="border-top">2. Durée minimum de location : 2 jours</h3>
                <atc-date-timeline-selector (dateRangeSelected)="onDateRangeSelected($event)" [endDate]="endDate" [selectedDate]="startDate"
                                            [selectionMode]="'range'"
                                            [startDate]="startDate">
                </atc-date-timeline-selector>
                <p class="pt-4 font-light">Durée de location : <span>{{ duration }} jours</span></p>
              </div>
            </div>

            <div class="flex flex-col flex-2">
              <atc-tag
                atcTooltip
                class="w-full py-4 m-0"
                color="mandatory"
                icon="fat-exclamation-circle"
                size="medium"
                tooltip="Ajoutez d'abord un hébergement à votre panier pour louer du matériel.">Hébergement nécessaire
              </atc-tag>
              <div class="w-full pb-[1rem] md:px-[1rem]">
                <h3 class="border-top">3. Nos packs au meilleur prix</h3>
                <div class="flex">
                  <div *ngFor="let groupName of uniqueGroupNames" class="mb-6 mr-4">
                    <atc-select-button (click)="onGroupNameSelected(groupName, packs)" [label]="groupName"
                                       [selected]="groupName === selectedGroupName">
                    </atc-select-button>
                  </div>
                </div>
              </div>

              <div class="ski-cards">
                <atc-card-resort-skiset
                  (paramsSelected)="onParamsSelected($event, pack)"
                  *ngFor="let pack of filteredPacks"
                  [description]="pack.offer.equipments[0]?.description"
                  [imageLink]="pack.offer.equipments[0]?.photoUrl"
                  [isSki]="pack.isSki"
                  [level]="pack.offer.minAge && pack.offer.maxAge ? pack.offer.minAge + ' - ' + pack.offer.maxAge + ' ans' : 'Adulte'"
                  [packName]="pack.offer.category"
                  [priceHelmet]="pack.priceHelmet"
                  [priceShoes]="pack.priceShoes"
                  [price]="pack.netPrice"
                  [publicPrice]="pack.publicPrice"
                  [shoesIncluded]="pack.isWithShoes"
                  class="xl:h-[400px]">
                </atc-card-resort-skiset>
              </div>
            </div>
          </div>
        </div>
        <vsk-resort-material-drawer *ngIf="packs?.length > 0"
                                    [(showDrawer)]="showSkiEquipmentDrawer"
                                    [hasAccommodation]="(cart$ | async)?.quantityAccommodation > 0"
                                    [resortId]="resort.id"
                                    [selectedEquipment]="paramsSelected">
        </vsk-resort-material-drawer>
      </ng-container>
    </ng-container>

    <ng-template #noPacksTemplate>
      <p class="w-full text-center p-4 max-w-[1350px] mx-auto">Aucun pack de matériel disponible.</p>
    </ng-template>
  </ng-container>

  <div *ngIf="loading" class="flex w-full h-full justify-center items-center">
    <atc-loader color="secondary"></atc-loader>
  </div>
</div>

<vsk-footer [isHome]="false"></vsk-footer>
