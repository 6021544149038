import { TranslateService } from '@ngx-translate/core';
import { CardLinkUnderCard } from 'atomic-lib';
import { v4 as uuidv4 } from 'uuid';
import { PackageUtils } from '../../../utils/package-utils';
import { ToHourPipe } from '../../pipe/to-hour.pipe';
import { Location } from '../location';
import { MarkerWrapper } from '../marker-wrapper';
import { Participant } from '../participant/participant';
import { Experience } from './experience';

export class ExperienceGroup {
  id = uuidv4();
  activityName: string;
  partnerName: string;
  review: number;
  nbReviews: number;
  address: string;
  withAccommodation: boolean;
  heart: boolean;
  location: Location;
  experiences: Experience[] = [];
  linksCard: CardLinkUnderCard<Experience>[];
  resort: string;
  marker?: MarkerWrapper<ExperienceGroup>;

  constructor(obj: Partial<ExperienceGroup>) {
    Object.assign(this, obj);

    if (this.experiences) {
      this.experiences = this.experiences.map(
        (experience) => new Experience({ ...experience })
      );
    }
  }

  get pictures() {
    if (!this.experiences?.length) {
      return [] as string[];
    }

    return this.experiences
      .map((experience) => experience.pictures)
      .reduce((prev, curr) => [...prev, ...curr]);
  }

  getMinPrice() {
    if (!this.linksCard?.length) {
      return 0;
    }

    return this.linksCard
      .map((card) => card.price || card.maxPrice)
      .reduce((prev, curr) => (prev < curr ? prev : curr));
  }

  mapLinkCard(
    participants: Participant[],
    hourPipe: ToHourPipe,
    translate: TranslateService,
    resortName: string
  ) {
    this.linksCard = [];
    this.experiences.forEach((experience) =>
      this.linksCard.push(
        this.toCardLink(
          experience,
          hourPipe.transform(experience.duration),
          translate.instant(
            'detail-station.activities.difficulty.' + experience.difficulty
          ),
          PackageUtils.minPriceForAllParticipantsString(
            experience.packages,
            participants
          ),
          PackageUtils.countEligibleParticipants(
            experience.packages,
            participants
          ),
          PackageUtils.findMinPriceforMaxPackage(
            experience.packages,
            participants
          ),
          `${PackageUtils.ageMin(experience.packages)}-${PackageUtils.ageMax(experience.packages)} ans`,
          resortName || this.resort
        )
      )
    );
  }

  toCardLink(
    experience: Experience,
    duration: string,
    difficulty: string,
    price: number,
    nbParticipants: number,
    maxPrice: number,
    age: string,
    resort: string
  ): CardLinkUnderCard<Experience> {
    return {
      value: experience,
      link: '/station/fiche-activite',
      params: {
        experienceId: experience.id,
        station: resort
      },
      price,
      nbParticipants: nbParticipants,
      maxPrice: maxPrice,
      size: 'small',
      activityTag: [
        {
          icon: 'time',
          label: duration
        },
        {
          icon: 'group',
          label: age
        },
        {
          icon: 'chart-bar',
          label: difficulty
        }
      ],
      roomTag: [],
      equipments: [],
      percentage: 0,
      publicPrice: 0,
      withDiscount: false,
      txtDetail: experience.name,
      display: true
    };
  }
}
