<ng-container *ngIf="windowResource.width() | async | screen: 'tablet-above'">
  <atc-select (selectChange)="selectChange()"
              *ngIf="filterByResorts"
              [checkbox]="true"
              [fitLabelWidth]="true"
              [optionElements]="resortsElements"
              [searchable]="true"
              label="Station">
  </atc-select>

  <atc-select (selectChange)="selectChange()"
              *ngIf="filterByRegions"
              [checkbox]="true"
              [optionElements]="regionsElements"
              label="Massif">
  </atc-select>

  <atc-select (selectChange)="selectChange()"
              [checkbox]="true"
              [optionElements]="accommodationTypesElements"
              label="Type d'hébergement">
  </atc-select>

  <atc-select (selectChange)="selectChange()"
              *ngIf="windowResource.width() | async | screen: 'small-desktop-above'"
              [checkbox]="true"
              [optionElements]="equipmentsFilter"
              label="Équipement">
  </atc-select>

  <atc-select (selectChange)="selectChange()"
              *ngIf="windowResource.width() | async | screen: 'small-desktop-above'"
              [checkbox]="true"
              [optionElements]="servicesFilter"
              label="Service">
  </atc-select>

  <atc-select (selectChange)="selectChange()"
              *ngIf="windowResource.width() | async | screen: 'desktop'"
              [checkbox]="true"
              [optionElements]="accessFilter"
              label="Accessibilité">
  </atc-select>

  <atc-select (selectChange)="selectChange()"
              *ngIf="windowResource.width() | async | screen: 'desktop'"
              [customDropdown]="true"
              [valuesSelected]="hasChangedPricing()"
              label="Prix">
    <atc-range-input (valueChange)="selectChangePrices($event)"
                     [displayedMaxValue]="currentFilters.priceRange?.max || 0"
                     [displayedMinValue]="currentFilters.priceRange?.min || 0"
                     [maxValue]="maxPriceDisplay"
                     [minValue]="minPriceDisplay"
                     [typeOfValues]="'price'"
                     class="mt-4">
    </atc-range-input>
  </atc-select>

  <atc-input-checkbox [control]="promoForm">
    <div class="flex flex-row gap-x-1 items-center">
      <label>Promo</label>
      <svg class="max-h-6 w-6 my-auto stroke-green">
        <use [attr.xlink:href]="'assets/icons/icons.svg#gift'"></use>
      </svg>
    </div>
  </atc-input-checkbox>

  <atc-button (click)="openPopup()"
              [fitContent]="true"
              class="relative"
              color="ternary"
              icon="filters"
              label="Plus de filtres"
              size="medium">
  </atc-button>
  <atc-button (click)="clearFilters()"
              color="ternary"
              icon="trash">
  </atc-button>
</ng-container>

<div *ngIf="!(windowResource.width() | async | screen: 'tablet-above')" class="flex gap-x-4 w-full">
  <atc-button (click)="openPopup()"
              [color]="totalFilters() ? 'clicked' : 'ternary'"
              [fullWidth]="true"
              [text]="totalFilters()"
              atcBadge
              class="flex w-full relative"
              icon="filters"
              label="Voir les filtres"
              positionBadge="topRight"
              size="medium">
  </atc-button>
  <atc-button (click)="clearFilters()"
              color="ternary"
              icon="trash">
  </atc-button>
</div>

<vsk-filters-accommodation-popup (applyFilters)="selectChangePopup($event)"
                                 (closeChanged)="closePopup()"
                                 *ngIf="isPopupOpen"
                                 [accessFilter]="accessFilter"
                                 [accommodationTypesElements]="accommodationTypesElements"
                                 [currentFilters]="currentFilters"
                                 [equipmentsFilter]="equipmentsFilter"
                                 [filterByRegions]="filterByRegions"
                                 [filterByResorts]="filterByResorts"
                                 [maxPriceDisplay]="maxPriceDisplay"
                                 [minPriceDisplay]="minPriceDisplay"
                                 [nbParticipants]="nbParticipants"
                                 [regionsElements]="regionsElements"
                                 [resortsElements]="resortsElements"
                                 [servicesFilter]="servicesFilter">
</vsk-filters-accommodation-popup>
