<atc-popup (closeChanged)="closeChanged.emit()"
           [height]="windowResource.isDesktop ? '600px' : ''"
           [width]="windowResource.isDesktop ? '600px' : ''"
           [withImg]="false"
           title="Filtres">
  <div *ngIf="filters" body class="flex flex-col gap-y-4 px-4">
    <h1 class="text-center mb-4">Filtres</h1>

    <hr *ngIf="filterResorts">
    <div *ngIf="filterResorts && resortsElements.length" class="flex flex-col gap-y-4">
      <h2>Stations</h2>
      <div class="grid grid-cols-2 gap-y-4">
        <atc-input-checkbox *ngFor="let element of resortsElements"
                            [control]="getControl(element.control)">
          <p>{{ element.label }}</p> <span *ngIf="element.quantity">{{ element.quantity }}</span>
        </atc-input-checkbox>
      </div>
    </div>

    <hr>
    <div *ngIf="activitiesElements.length" class="flex flex-col gap-y-4">
      <h2>Activité</h2>
      <div class="grid grid-cols-2 gap-y-4">
        <atc-input-checkbox *ngFor="let element of activitiesElements"
                            [control]="getControl(element.control)">
          <p>{{ element.label }}</p> <span *ngIf="element.quantity">{{ element.quantity }}</span>
        </atc-input-checkbox>
      </div>
    </div>

    <!--    <hr>-->
    <!--    <div class="price-range mb-4">-->
    <!--      <h2 class="mb-6">Prix</h2>-->
    <!--      <atc-range-input-->
    <!--        [displayedMaxValue]=filters.priceRange.max-->
    <!--        [displayedMinValue]=filters.priceRange.min-->
    <!--        [maxValue]=maxValueRangePrice-->
    <!--        [minValue]=minValueRangePrice-->
    <!--        [typeOfValues]="'price'"-->
    <!--        class="w-[80%] md:w-[100%] block mx-auto">-->
    <!--      </atc-range-input>-->
    <!--    </div>-->
    <!--    <hr>-->
    <!--    <div class="price-range my-4">-->
    <!--      <h2 class="mb-6">Durée </h2>-->
    <!--      <atc-range-input-->
    <!--        [displayedMaxValue]=filters.durationRange.max-->
    <!--        [displayedMinValue]=filters.durationRange.min-->
    <!--        [maxValue]=maxValueRangeDuration-->
    <!--        [minValue]=minValueRangeDuration-->
    <!--        [typeOfValues]="'duration'"-->
    <!--        class="w-[80%] md:w-[100%] block mx-auto">-->
    <!--      </atc-range-input>-->
    <!--    </div>-->

    <hr>
    <div class="flex flex-col gap-y-4">
      <h2>Difficulté</h2>
      <div class="grid grid-cols-2 gap-y-4">
        <atc-input-checkbox *ngFor="let element of experienceDifficulties"
                            [control]="getControl(element.control)">
          <p>{{ element.label }}</p> <span *ngIf="element.quantity">{{ element.quantity }}</span>
        </atc-input-checkbox>
      </div>
    </div>

    <!--    <hr>-->
    <!--    <h2>Autres</h2>-->
    <!--    <atc-input-checkbox [control]="heartForm">-->
    <!--      <div class="flex flex-row gap-x-1 items-center">-->
    <!--        <label>Coup de</label>-->
    <!--        <svg class="max-h-6 w-6 my-auto stroke-red">-->
    <!--          <use [attr.xlink:href]="'assets/icons/icons.svg#heart-fill'"></use>-->
    <!--        </svg>-->
    <!--      </div>-->
    <!--    </atc-input-checkbox>-->
  </div>

  <div class="text-center m-4" footer>
    <atc-button (click)="validateFiltersChanged.emit()"
                [fullWidth]="true"
                class="flex flex-1"
                color="secondary"
                label="Appliquer"></atc-button>
  </div>

</atc-popup>
