<ng-container *ngIf="(windowResource.width() | async | screen: 'tablet-above')">
  <div class="flex flex-row items-center gap-x-2">
    <atc-select (selectChange)="applyFilter()"
                [checkbox]="true"
                [optionElements]="tripIncludeFilter"
                label="Inclus dans mon séjour">
    </atc-select>
    <atc-select (selectChange)="applyFilter()"
                [checkbox]="true"
                [optionElements]="regionsElements"
                label="Massif">
    </atc-select>
  </div>

  <div *ngIf="_filters.accommodationsDisplayed"
       class="flex flex-row items-center gap-x-2">
    <atc-select (selectChange)="applyFilter()"
                [checkbox]="true"
                [optionElements]="accommodationTypeElements"
                label="Type d'hébergement">
    </atc-select>
    <atc-select (selectChange)="applyFilter()"
                *ngIf="(windowResource.width() | async | screen: 'small-desktop-above')"
                [checkbox]="true"
                [optionElements]="equipmentsFilter"
                label="Équipement">
    </atc-select>
    <atc-select (selectChange)="applyFilter()"
                *ngIf="(windowResource.width() | async | screen: 'small-desktop-above')"
                [checkbox]="true"
                [optionElements]="servicesFilter"
                label="Service">
    </atc-select>
  </div>
</ng-container>

<ng-container *ngIf="!(windowResource.width() | async | screen: 'tablet-above')">
  <atc-button (click)="openMapFiltersPopUp()"
              [color]="totalFilters() ? 'clicked' : 'ternary'"
              [fullWidth]="true"
              [text]="totalFilters()"
              atcBadge
              class="flex w-full"
              color="ternary"
              icon="filters"
              label="Voir les filtres"
              size="medium">
  </atc-button>
</ng-container>

<div class="flex flex-row xl:gap-x-2 items-center">
  <atc-button (click)="openMapFiltersPopUp()"
              *ngIf="(windowResource.width() | async | screen: 'tablet-above')"
              [clicked]="openMapFilters"
              [color]="totalFilters() ? 'clicked' : 'ternary'"
              [fitContent]="true"
              [fullWidth]="true"
              [positionBadge]="'topRight'"
              [text]="totalFilters()"
              atcBadge
              class="relative"
              color="ternary"
              icon="filters"
              label="Plus de filtres"></atc-button>
  <atc-button (click)="reset()"
              [fitContent]="true"
              color="simple"
              icon="trash"></atc-button>
</div>

<vsk-map-filters (clearFiltersChanged)="reset()"
                 (refreshChanged)="closePopup($event)"
                 *ngIf="openMapFilters"
                 [accessFilter]="accessFilter"
                 [accommodationTypeFilter]="accommodationTypeElements"
                 [equipmentsFilter]="equipmentsFilter"
                 [filters]="_filters"
                 [regionsFilter]="regionsElements"
                 [resortLabelsFilter]="resortLabelsFilter"
                 [servicesFilter]="servicesFilter"
                 [tagsFilter]="tagsFilter"
                 [tripIncludeFilter]="tripIncludeFilter"
                 class="absolute">
</vsk-map-filters>
