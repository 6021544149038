import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngxs/store';
import { filter } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import {
  SetAccommodation,
  SetMaterial,
  SetSkiPackage
} from '../../navbar.action';

@Component({
  selector: 'vsk-more-filters',
  templateUrl: './more-filters.component.html',
  styleUrls: ['./more-filters.component.scss']
})
export class MoreFiltersComponent {
  accommodationForm: FormControl<boolean | null> = new FormControl<boolean>(
    true
  );
  skiPackageForm: FormControl<boolean | null> = new FormControl<boolean>(true);
  materialForm: FormControl<boolean | null> = new FormControl<boolean>(true);

  @Input() set withAccommodation(withAcc: boolean) {
    this.accommodationForm.setValue(withAcc);
  }

  @Input() set withSkiPackage(skiPackage: boolean) {
    this.skiPackageForm.setValue(skiPackage);
  }

  @Input() set withMaterial(material: boolean) {
    this.materialForm.setValue(material);
  }

  @Input() responsive = false;
  @Input() isHome = false;

  constructor(private store: Store) {
    this.accommodationForm.valueChanges
      .pipe(
        filter((value) => value !== null),
        distinctUntilChanged()
      )
      .subscribe((value) =>
        this.store.dispatch(new SetAccommodation(value as boolean))
      );

    this.skiPackageForm.valueChanges
      .pipe(
        filter((value) => value !== null),
        distinctUntilChanged()
      )
      .subscribe((value) =>
        this.store.dispatch(new SetSkiPackage(value as boolean))
      );

    this.materialForm.valueChanges
      .pipe(
        filter((value) => value !== null),
        distinctUntilChanged()
      )
      .subscribe((value) =>
        this.store.dispatch(new SetMaterial(value as boolean))
      );
  }
}
