import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import moment, { Moment } from 'moment/moment';
import { Observable } from 'rxjs';
import { URL_SEARCH_TRIP } from '../../../app-routing.module';
import { SetChangingFilters } from '../../../filters.action';
import { FiltersState } from '../../../filters.state';
import { ActivitiesByCategory } from '../../../shared/models/activity/activities-by-category';
import { Activity } from '../../../shared/models/activity/activity';
import { Participant } from '../../../shared/models/participant/participant';
import { Period } from '../../../shared/models/period';
import { Resort } from '../../../shared/models/resort/resort';
import { InitNavbar } from '../../navbar.action';

export enum SearchMobileStep {
  DATES,
  ACTIVITIES,
  PARTICIPANTS
}

@Component({
  selector: 'vsk-search-popup-mobile',
  templateUrl: './search-popup-mobile.component.html',
  styleUrls: ['./search-popup-mobile.component.scss']
})
export class SearchPopupMobileComponent implements OnDestroy {
  @Select(FiltersState.isFoncia) isFoncia$: Observable<boolean>;

  @Input() currentStep: SearchMobileStep;
  @Input() period: Period = new Period({});
  @Input() minDate: Moment | undefined = moment();
  @Input() maxDate: Moment | undefined = moment().add(1, 'year');
  @Input() activities: Activity[] = [];
  @Input() activitiesByCategory: ActivitiesByCategory[] = [];
  @Input() participants: Participant[] = [];
  @Input() resorts: Resort[] = [];
  @Input() hasAlterParticipants = false;
  @Input() withAccommodation = true;
  @Input() withSkiPackage = true;
  @Input() withMaterial = true;
  @Input() searchMode: 'accommodation' | 'all' | 'experience' = 'all';
  @Input() currentPage: 'Home' | 'Map' | 'Resort' | 'none' = 'Home';
  @Output() datesChanged = new EventEmitter<Period>();
  @Output() validate = new EventEmitter<void>();
  protected readonly SearchMobileStep = SearchMobileStep;
  protected readonly URL_SEARCH_TRIP = URL_SEARCH_TRIP;

  constructor(
    private store: Store,
    private router: Router
  ) {
    document.documentElement.style.overflow = 'hidden';
  }

  get subtitleMoreFilters() {
    const filters = [
      { name: 'Hébergement', value: this.withAccommodation },
      { name: 'Forfait', value: this.withSkiPackage },
      { name: 'Matériel', value: this.withMaterial }
    ];

    const activeFilters = filters
      .filter((filter) => filter.value)
      .map((filter) => filter.name);

    return activeFilters.length > 0
      ? activeFilters.join(' & ')
      : 'Affiner ma recherche';
  }

  ngOnDestroy(): void {
    document.documentElement.style.overflow = 'auto';
  }

  nextStep() {
    switch (this.currentStep) {
      case SearchMobileStep.DATES:
        {
          if (this.period.isValid) {
            this.currentStep++;
          }
        }
        break;
      default:
        this.currentStep++;
    }
  }

  isCurrentStep(step: SearchMobileStep) {
    return this.currentStep === step;
  }

  subtitlesDates() {
    if (this.period?.isValid) {
      return (
        this.period.startDate.locale('fr').format('DD MMM') +
        ' - ' +
        this.period.endDate.locale('fr').format('DD MMM')
      );
    }

    return 'Choisir des dates';
  }

  subtitlesActivities() {
    const activitiesSelected = this.activities?.filter((act) => act.selected);
    if (activitiesSelected?.length) {
      return '';
    }

    return 'Ajouter des activités';
  }

  subtitlesParticipants() {
    const participants = this.participants?.filter((participant) =>
      participant.isValid()
    );
    if (participants?.length) {
      return participants.length > 1
        ? participants.length + ' voyageurs'
        : '1 voyageur';
    }

    return 'Ajouter des voyageurs';
  }

  changeStep(step: SearchMobileStep, isExpanded: boolean) {
    if (
      step === SearchMobileStep.ACTIVITIES &&
      this.preventExpandActivities()
    ) {
      this.currentStep = SearchMobileStep.DATES;
      return;
    }

    if (isExpanded) {
      this.currentStep = step;
    }
  }

  goBack() {
    this.store.dispatch(new SetChangingFilters(false)).subscribe(() => {
      const participants = this.store.selectSnapshot(FiltersState.participants);
      const activities = this.store.selectSnapshot(FiltersState.activities);
      const period = this.store.selectSnapshot(FiltersState.period);
      const filters = this.store.selectSnapshot(FiltersState.filtersStation);
      this.store.dispatch(
        new InitNavbar(
          participants,
          activities,
          filters.resorts,
          period,
          filters.accommodationsDisplayed,
          filters.packagesDisplayed,
          filters.materialDisplayed
        )
      );
    });
  }

  search() {
    this.validate.emit();
  }

  preventExpandActivities() {
    return !this.activities?.length;
  }

  activitiesNamesSelected() {
    if (!this.activities) {
      return [];
    }

    return this.activities.filter((act) => act.selected).map((act) => act.name);
  }

  getLabel() {
    if (this.store.selectSnapshot(FiltersState.isFoncia)) {
      return 'Trouver mon expérience';
    }

    if (this.currentPage === 'Map') {
      return 'Affiner ma recherche';
    }
    if (this.currentPage === 'Resort') {
      return (
        'Affiner ma recherche sur ' +
        this.store.selectSnapshot(FiltersState.resort)?.name
      );
    }

    switch (this.searchMode) {
      case 'all':
        return 'Trouver mon séjour';
      case 'accommodation':
        return 'Trouver mon hébergement';
      case 'experience':
        return 'Trouver mon expérience';
    }
  }

  goTo(destination: string) {
    this.validate.emit();
    this.router.navigate([destination]);
  }

  getSearchModeLabel() {
    if (this.store.selectSnapshot(FiltersState.isFoncia)) {
      return 'Rechercher une expérience';
    }

    if (!this.searchMode) {
      return 'Rechercher un séjour';
    }

    switch (this.searchMode) {
      case 'all':
        return 'Rechercher un séjour';
      case 'accommodation':
        return 'Rechercher un hébergement';
      case 'experience':
        return 'Rechercher une expérience';
    }
  }
}
