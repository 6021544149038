import { Activity } from 'atomic-lib';
import { Resort } from './resort';

export class ResortMap extends Resort {
  marker?: google.maps.Marker;
  totalPrice: number;
  totalPricePerPerson: number;
  priceActivity: number;
  priceAccommodation: number;
  priceMaterial: number;
  totalRooms: number;
  totalActivities: number;
  affinity: number;
  activitiesSelected: Partial<Activity>[];

  constructor(obj: Partial<ResortMap>) {
    super(obj);
    this.addMarker(this);
  }

  static getIcon(
    url: string,
    height = 50,
    width = 50,
    x = 25,
    y = 25
  ): google.maps.Icon {
    return {
      url,
      scaledSize: {
        height,
        width,
        equals(other: google.maps.Size): boolean {
          return other.height === this.height && other.width === this.width;
        }
      },
      labelOrigin: {
        x,
        y,
        equals(other: google.maps.Point): boolean {
          return other.x === this.x && other.y === this.y;
        }
      }
    };
  }

  addMarker(station: ResortMap): void {
    const text = station.totalPrice
      ? Math.round(station.totalPrice) + ' €'
      : station.name;
    this.marker = new MarkerVerySKI(station, {
      clickable: true,
      position: {
        lat: station.location.lat,
        lng: station.location.lon
      },
      label: {
        color: '#1f1f1f',
        text: text,
        fontWeight: '500',
        fontSize: '11px',
        className: 'pinpoint',
        fontFamily: 'General'
      },
      title: station.name,
      icon: {
        url: '../../../../assets/svg/pinpoint.svg',
        scaledSize: new google.maps.Size(text.length * 6 + 40, 36, 'px', 'px'),
        origin: new google.maps.Point(-13, -5)
      },
      anchorPoint: new google.maps.Point(0, 0),
      optimized: false,
      zIndex: 100
    });
  }
}

export class MarkerVerySKI extends google.maps.Marker {
  station: ResortMap;

  constructor(station: ResortMap, opts?: google.maps.MarkerOptions) {
    super(opts);
    this.station = station;
  }
}
