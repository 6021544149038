import { CardLinkUnderCard } from 'atomic-lib';
import moment, { Moment } from 'moment';
import { AccommodationUtils } from '../../../utils/accommodation-utils';
import { Location } from '../location';
import { MarkerWrapper } from '../marker-wrapper';
import { Resort } from '../resort/resort';
import { AccommodationAgeRange } from './accommodation-age-range';
import { Room } from './room';

export class Establishment {
  id: number;
  partnerCode: string;
  partnerName: string;
  name: string;
  address: string;
  city: string;
  zip: string;
  location: Location;
  description: string;
  access: string;
  facilities: string;
  information: string;
  openingTime: Moment;
  closingTime: Moment;
  pictures: string[];
  ages: AccommodationAgeRange[];
  minPrice: number;
  totalRooms: number;
  resorts: Resort[] = [];
  rooms: Room[] = [];
  linksCard: CardLinkUnderCard<Room>[];
  marker?: MarkerWrapper<Establishment>;
  pool: boolean;
  spa: boolean;
  sauna: boolean;
  hammam: boolean;
  balconyTerrace: boolean;
  tv: boolean;
  chimney: boolean;
  bbq: boolean;
  distanceEsf: number;
  distanceSlopes: number;
  distanceLifts: number;
  distanceNursery: number;
  distanceShops: number;
  pmr: boolean;
  parking: boolean;
  animalsAdmitted: boolean;
  wifi: boolean;
  childrenClub: boolean;

  constructor(obj: Partial<Establishment>) {
    Object.assign(this, obj);

    if (this.openingTime) {
      this.openingTime = moment(this.openingTime, 'HH:mm');
    }

    if (this.closingTime) {
      this.closingTime = moment(this.closingTime, 'HH:mm');
    }

    if (this.rooms) {
      this.rooms = this.rooms.map((room) => new Room({ ...room }));
      this.mapLinkCard();
    }

    if (this.resorts) {
      this.resorts = this.resorts.map((resort) => new Resort({ ...resort }));
    }
  }

  mapLinkCard() {
    this.linksCard = [];
    this.rooms.forEach((room) => this.linksCard.push(this.toCardLink(room)));
  }

  toCardLink(room: Room): CardLinkUnderCard<Room> {
    let price = 0;
    let publicPrice = 0;
    let promotion = false;
    let promoPercentage = 0;
    const roomTag = [];
    if (room.surface > 0) {
      roomTag.push({ icon: 'surface', label: `${room.surface} m²` });
    }
    if (room.maxPeople > 0) {
      roomTag.push({ icon: 'group', label: `${room.maxPeople}` });
    }

    const equipments = [
      ...AccommodationUtils.getFeaturesEstablishment(this),
      ...AccommodationUtils.getFeaturesRoom(room)
    ];

    if (room.proposals?.length) {
      price = room.proposals[0].price;
      publicPrice = room.proposals[0].publicPrice;
      promotion = room.proposals[0].publicPrice !== room.proposals[0].price;
      promoPercentage = Math.round(
        ((room.proposals[0].publicPrice - room.proposals[0].price) /
          room.proposals[0].publicPrice) *
          100
      );
    }

    return {
      value: room,
      link: '/station/logement',
      params: {
        station: this.resorts[0]?.name,
        establishmentId: this.id,
        roomCode: room.codeRoom,
        partnerCode: this.partnerCode
      },
      price,
      maxPrice: price,
      publicPrice,
      size: 'small',
      activityTag: [],
      roomTag,
      equipments,
      percentage: promoPercentage,
      txtDetail: room.name,
      withDiscount: promotion,
      display: true
    };
  }
}
