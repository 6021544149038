import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Select } from '@ngxs/store';
import { WindowResource } from 'atomic-lib';
import { Observable, filter } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FiltersState } from '../../filters.state';
import { MapConfiguration } from '../../map/map.component';
import { ResortState } from '../../resort/resort.state';
import { MarkerWrapper } from '../models/marker-wrapper';
import { Resort } from '../models/resort/resort';
import { RxjsComponent } from './rxjs.component';

export interface Position {
  id: number | string;
}

@Component({ standalone: true, template: '' })
export abstract class MapComponent<T extends Position>
  extends RxjsComponent
  implements AfterViewInit
{
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  map: GoogleMap;

  @Select(FiltersState.resort) resort$: Observable<Resort>;
  @Select(ResortState.navbarSticky) sticky$: Observable<boolean>;

  refreshOnMoveMap: FormControl<boolean | null> = new FormControl<boolean>(
    true
  );
  fitBoundsInit = false;
  focusOnPinpoint = true;
  pinpoint: T | undefined;
  markers: MarkerWrapper<T>[];
  markerResort: MarkerWrapper<Resort> | undefined;
  zoom = 12;
  center: google.maps.LatLngLiteral = {
    lat: 45.052825927734375,
    lng: 2.7113842964172363
  };
  options: MapConfiguration = {
    scaleControl: true,
    streetViewControl: false,
    fullscreenControl: false,
    zoomControl: this.windowResource.isDesktop,
    mapTypeControl: false,
    maxZoom: 20,
    minZoom: 6,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    clickableIcons: false,
    styles: [
      {
        featureType: 'road',
        elementType: 'all',
        stylers: [{ visibility: 'on' }]
      },
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'on' }]
      }
    ]
  };

  constructor(public windowResource: WindowResource) {
    super();
    this.resort$.pipe(filter((resort) => !!resort)).subscribe((resort) => {
      this.markerResort = new MarkerWrapper<Resort>(resort, {
        clickable: true,
        position: {
          lat: resort.location.lat,
          lng: resort.location.lon
        },
        icon: {
          url: '../../../../assets/svg/pinpoints/station-circle.svg',
          scaledSize: new google.maps.Size(30, 30, 'px', 'px'),
          origin: new google.maps.Point(0, 0)
        },
        title: resort.name,
        optimized: false,
        zIndex: 2000
      });
      this.markerResort.set('options', { zIndex: 2000 });
      this.markerResort.set('zIndex', 1000);
      this.markerResort.setZIndex(2000);

      this.center = {
        lat: resort.location.lat,
        lng: resort.location.lon
      };
    });
  }

  ngAfterViewInit() {
    if (!this.map) {
      setTimeout(() => this.ngAfterViewInit(), 800);
      return;
    }

    if (this.focusOnPinpoint) {
      this.placeMarkersOnMap();
    }

    this.map.zoomChanged.pipe(debounceTime(800)).subscribe(() => {
      this.zoom = this.map.getZoom() as number;
      this.boundsChanged();
      this.placeMarkersOnMap();
    });
  }

  setZoom(): void {
    this.zoom = this.map.getZoom() as number;
  }

  getOptions(marker: google.maps.Marker) {
    return (marker as any).options;
  }

  openInfoWindow(marker: MarkerWrapper<T>, markerMap: MapMarker): void {
    if (!marker.value) {
      return;
    }

    if (marker.value === this.pinpoint) {
      this.outMarker(marker);
      this.pinpoint = undefined;
      this.infoWindow.close();
    } else {
      this.pinpoint = marker.value;
      this.overMarker(marker);
      this.infoWindow.open(markerMap);
    }
  }

  overMarker(marker: MarkerWrapper<T> | undefined): void {
    if (marker) {
      const label: google.maps.MarkerLabel =
        marker?.getLabel() as google.maps.MarkerLabel;
      marker.setLabel({
        color: 'white',
        text: label.text,
        fontWeight: '500',
        fontSize: '11px',
        className: 'pinpoint-hover',
        fontFamily: 'General'
      });

      marker.set('options', { zIndex: 1000 });
      marker.set('zIndex', 1000);
    }
  }

  outMarker(marker: MarkerWrapper<T> | undefined): void {
    if (marker?.value.id === this.pinpoint?.id) {
      return;
    }

    if (marker) {
      const label: google.maps.MarkerLabel =
        marker?.getLabel() as google.maps.MarkerLabel;
      marker.setLabel({
        color: '#1f1f1f',
        text: label.text,
        fontWeight: '500',
        fontSize: '11px',
        className: 'pinpoint',
        fontFamily: 'General'
      });

      marker.set('options', { zIndex: 100 });
      marker.setZIndex(100);
    }
  }

  boundsChanged() {
    const bounds = this.map.getBounds()?.toJSON();

    if (bounds && this.refreshOnMoveMap.value) {
      this.boundsChangedAction(bounds, this.refreshOnMoveMap.value);
    }
  }

  protected abstract boundsChangedAction(
    bounds: google.maps.LatLngBoundsLiteral,
    refresh: boolean
  ): void;

  protected placeMarkersOnMap() {
    if (!this.map || !this.markers?.length) {
      return;
    }

    const bounds = new google.maps.LatLngBounds();
    this.markers.forEach((marker) => {
      const position = (marker as any).position;
      if (position.lat() !== 0 || position.lng() !== 0) {
        bounds.extend(position);
      }
    });

    if (!this.fitBoundsInit) {
      this.map.fitBounds(bounds);
      this.fitBoundsInit = true;
    }
  }
}
