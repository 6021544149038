<atc-button (click)="showCart = true"
            *ngIf="!(windowResource.width() | async | screen : 'small-desktop-above')"
            [fullWidth]="true"
            class="flex flex-1 mb-4"
            color="ternary"
            label="Voir le détail de mon panier"
            size="small">
</atc-button>

<div class="px-8 py-6 mb-4 bg-white rounded-[30px] flex flex-col gap-4">
  <h3 class="text-center">Mode de paiement</h3>
  <p class="text-center">Payez par carte ou en 3 fois sans frais avec Klarna</p>
  <p *ngIf="error" class="error">{{ error }}</p>
  <div id="payment-element"></div>
</div>

<div class="flex flex-row gap-2">
  <atc-button (click)="previous.emit()"
              color="ternary"
              icon="left-arrow">
  </atc-button>

  <atc-button (click)="createToken()"
              [fullWidth]="true"
              class="flex w-full"
              color="secondary"
              icon="cart"
              id="id__button-pay"
              label="Payer">
  </atc-button>
</div>

<vsk-cart [(showCart)]="showCart"
          [canAddPromoCode]="false"
          [canDeleteItem]="false">
</vsk-cart>
