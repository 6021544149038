import { Component, ElementRef, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngxs/store';
import { OptionElement, WindowResource } from 'atomic-lib';
import { skip } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { SetFiltersExperiences } from '../../filters.action';
import { FormUtils } from '../../utils/form-utils';
import { Activity } from '../models/activity/activity';
import { FiltersExperienceSearch } from '../models/activity/filters-experience-search';
import { DifficultyExperience } from '../models/enum/difficulty-experience.enum';
import { FiltersInfoExperience } from '../models/filters/filters-info-experience';
import { Resort } from '../models/resort/resort';

@Component({
  selector: 'vsk-experience-filters',
  templateUrl: './experience-filters.component.html',
  styleUrls: ['./experience-filters.component.scss']
})
export class ExperienceFiltersComponent {
  @Input() filterByResorts = false;
  resortsElements: OptionElement<number>[] = [];
  activitiesElements: OptionElement<number>[] = [];
  heartForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  promoForm: FormControl<boolean | null> = new FormControl<boolean>(false);
  difficultiesElements: OptionElement<DifficultyExperience>[] = [
    {
      id: DifficultyExperience.FOR_ALL,
      label: 'Pour tous',
      control: new FormControl<boolean>(false)
    },
    {
      id: DifficultyExperience.EXPERIMENTED,
      label: 'Expérimenté',
      control: new FormControl<boolean>(false)
    },
    {
      id: DifficultyExperience.SPORT,
      label: 'Sportif',
      control: new FormControl<boolean>(false)
    }
  ];
  isPopupOpen = false;

  constructor(
    public windowResource: WindowResource,
    public store: Store,
    public elementRef: ElementRef
  ) {}

  @Input() set resorts(resorts: Resort[]) {
    if (resorts?.length) {
      this.resortsElements = resorts.map((resort) => {
        const filterCount = this._filtersInfo?.resorts?.find(
          (filter) => resort.id === filter.id
        );

        const form = new FormControl<boolean>(
          this._filters?.resorts?.includes(resort.id)
        );

        if (!filterCount?.quantity) {
          form.disable();
        }

        return {
          id: resort.id,
          label: resort.name,
          control: form,
          quantity: filterCount?.quantity ?? 0
        };
      });
    }
  }

  _filters: FiltersExperienceSearch;

  @Input() set filters(filters: FiltersExperienceSearch) {
    if (filters) {
      this._filters = filters;

      this.resortsElements.forEach((element) => {
        element.control?.setValue(
          filters.resorts.includes(element.id as number)
        );
      });

      this.activitiesElements.forEach((element) => {
        element.control?.setValue(
          filters.activities.includes(element.id as number)
        );
      });

      this.difficultiesElements.forEach((element) => {
        element.control?.setValue(
          filters.difficulties.includes(element.id as DifficultyExperience)
        );
      });

      this.heartForm.setValue(filters.heart);
      this.promoForm.setValue(filters.promo);
    }
  }

  _filtersInfo: FiltersInfoExperience;

  @Input() set filtersInfo(info: FiltersInfoExperience) {
    if (info) {
      this._filtersInfo = info;

      if (this.resortsElements?.length) {
        this.resortsElements.forEach((element) => {
          const filterCount = info.resorts.find(
            (filter) => filter.id === element.id
          );
          FormUtils.disabledControlByFilterCountWithNoQuantity(
            element,
            filterCount
          );
        });
      }

      if (this.activitiesElements?.length) {
        this.activitiesElements.forEach((element) => {
          const filterCount = info.activities.find(
            (filter) => filter.id === element.id
          );
          FormUtils.disabledControlByFilterCountWithNoQuantity(
            element,
            filterCount
          );
        });
      }

      this.difficultiesElements.forEach((element) => {
        const filterCount = info.difficulties.find(
          (filter) => filter.id === element.id
        );
        FormUtils.disabledControlByFilterCountWithNoQuantity(
          element,
          filterCount
        );
      });

      this.heartForm.valueChanges
        .pipe(
          skip(1),
          distinctUntilChanged((prev, curr) => prev === curr)
        )
        .subscribe(() => this.validateFilters());

      this.promoForm.valueChanges
        .pipe(
          skip(1),
          distinctUntilChanged((prev, curr) => prev === curr)
        )
        .subscribe(() => this.validateFilters());
    }
  }

  @Input() set activities(activities: Activity[]) {
    if (activities?.length) {
      this.activitiesElements = activities
        .sort((prev, curr) => (prev.name > curr.name ? 1 : -1))
        .map((activity) => {
          const filterCount = this._filtersInfo?.activities?.find(
            (filter) => activity.id === filter.id
          );

          const form = new FormControl<boolean>(
            this._filters?.activities?.includes(activity.id)
          );

          if (!filterCount?.quantity) {
            form.disable();
          }

          return {
            id: activity.id,
            label: activity.name,
            control: form,
            quantity: filterCount?.quantity ?? 0
          };
        });
    }
  }

  validateFilters() {
    this.isPopupOpen = false;

    this._filters = new FiltersExperienceSearch({
      ...this._filters,
      difficulties: this.difficultiesElements
        .filter((element) => !!element.control?.value)
        .map((element) => element.id as DifficultyExperience),
      resorts: this.resortsElements
        .filter((element) => !!element.control?.value)
        .map((element) => element.id as number),
      activities: this.activitiesElements
        .filter((element) => !!element.control?.value)
        .map((element) => element.id as number),
      heart: this.heartForm.value || false,
      promo: this.promoForm.value || false
    });

    this.store.dispatch(new SetFiltersExperiences(this._filters));
  }

  clearFilters() {
    this._filters = new FiltersExperienceSearch({
      ...this._filters,
      geoBoundsActive: true,
      mapPolygon: {
        east: 8.413687158879437,
        north: 48.70087172198533,
        south: 42.13380963168609,
        west: -2.133187841120563
      },
      priceRange: {
        min: 0,
        max: 1000
      },
      durationRange: {
        min: 0,
        max: 1000
      },
      difficulties: [],
      activities: [],
      heart: false,
      promo: false,
      resorts: []
    });

    this.store.dispatch(new SetFiltersExperiences(this._filters));
  }

  totalFilters() {
    if (!this._filters) {
      return 0;
    }

    let total = this._filters.heart ? 1 : 0;

    if (this.filterByResorts) {
      total += this._filters.resorts.length;
    }

    total += this._filters.promo ? 1 : 0;
    total += this._filters.activities.length;
    total += this._filters.difficulties.length;
    total += this._filters.durationRange.min !== 0 ? 1 : 0;
    total += this._filters.priceRange.min !== 0 ? 1 : 0;
    return total;
  }

  totalFiltersHidden() {
    if (!this._filters) {
      return 0;
    }

    let total = this._filters.durationRange.min !== 0 ? 1 : 0;
    total += this._filters.priceRange.min !== 0 ? 1 : 0;
    return total;
  }

  openPopup() {
    this.isPopupOpen = true;
    const element = this.elementRef.nativeElement.parentNode;
    const existingStyle = element.getAttribute('style') || '';
    element.setAttribute('style', `${existingStyle}; z-index: 10`);
  }

  closePopup() {
    this.isPopupOpen = false;

    if (this.filterByResorts) {
      this.elementRef.nativeElement.parentNode.setAttribute(
        'style',
        'z-index: 3'
      );
    }
  }
}
