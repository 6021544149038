<ng-container *ngIf="_cart.hasPromoCode else NormalPrice">
  <span *ngIf="promoPriceReduction" class="line-through">{{_cart.getPublicPrice() | number : '1.2-2'}} €</span>
  <span class="main-price"> {{_cart.getPriceWithPromo() | number : '1.2-2'}} € </span>
  <ng-container *ngIf="promoPriceReduction">
    <span class="percentage" *ngIf="PricingUtils.reductionPercentage(_cart.getPublicPrice(), _cart.getPriceWithPromo()) as reduction"> (-{{ reduction }} %)</span>
  </ng-container>
</ng-container>

<ng-template #NormalPrice>
  <span *ngIf="priceReduction" class="line-through">{{_cart.getPublicPrice() | number : '1.2-2'}} €</span>
  <span class="main-price"> {{_cart.getPrice() | number : '1.2-2'}} € </span>
  <ng-container *ngIf="priceReduction">
    <span *ngIf="PricingUtils.reductionPercentage(_cart.getPublicPrice(), _cart.getPrice()) as reduction" class="percentage"> (-{{ reduction }} %)</span>
  </ng-container>
</ng-template>
