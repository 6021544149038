<div *ngIf="isDesktop"
     [ngClass]="{'form-nav-for-home': isHome, 'vsk__form-date-range-navbar vsk__form-date-range relative' : !isHome}">

  <div class="vsk__dates">
    <vsk-form-date-range-period-picker (datesChanged)="setDates($event)"
                                       (nextStep)="openActivityPicker()"
                                       (partialValidate)="validate()"
                                       [currentPage]="currentPage"
                                       [isInNavbar]="isInNavbar"
                                       [maxDate]="maxDate"
                                       [minDate]="minDate"
                                       [period]="period"
                                       [searchMode]="searchMode"
                                       [showPicker]="showDates">
    </vsk-form-date-range-period-picker>
  </div>
  <vsk-form-activity (partialValidate)="validate()"
                     (validate)="setActivities()"
                     *ngIf="(searchMode !== 'accommodation' || !isHome && (isFoncia$ | async) === false) && !isSearchAccommodation"
                     [activitiesByCategories]="activitiesByCategories"
                     [activities]="activities"
                     [currentPage]="currentPage"
                     [isInNavbar]="isInNavbar"
                     [showPicker]="showActivities">
  </vsk-form-activity>

  <vsk-form-resort (validate)="openParticipantPicker()"
                   *ngIf="(searchMode === 'accommodation' && isHome) || isSearchAccommodation"
                   [resorts]="resorts"
                   [responsive]="false"
                   [currentPage]="currentPage"
                   [isInNavbar]="isInNavbar">
  </vsk-form-resort>

  <vsk-form-manage-participant (search)="validate()"
                               [activities]="selectedActivities"
                               [currentPage]="currentPage"
                               [hasAlterParticipants]="hasAlterParticipants"
                               [isInNavbar]="isInNavbar"
                               [isOpen]="showParticipants"
                               [participants]="participants">

  </vsk-form-manage-participant>

  <div [ngClass]="{'lg:px-3 lg:py-2': !isInNavbar, 'my-auto pl-4 pr-2 flex': isInNavbar}">
    <button (click)="validate()"
            class="vsk__search-button lg:px-2 lg:h-9 lg:w-9"
            id="id__button-search">
      <svg class="lg:h-[1rem] lg:w-[1rem] m-auto stroke-white">
        <use [attr.xlink:href]="'assets/icons/icons.svg#search'"></use>
      </svg>
    </button>
  </div>
</div>

<ng-container *ngIf="!(windowResource.width() | async | screen: 'small-desktop-above')">
  <vsk-search-popup-mobile (datesChanged)="setDates($event)"
                           (validate)="validate()"
                           *ngIf="showSearchPopup"
                           [activitiesByCategory]="activitiesByCategories"
                           [activities]="activities"
                           [currentPage]="currentPage"
                           [hasAlterParticipants]="hasAlterParticipants"
                           [participants]="participants"
                           [period]="period"
                           [resorts]="resorts"
                           [searchMode]="searchMode"
                           [withAccommodation]="withAccommodation"
                           [withMaterial]="withMaterial"
                           [withSkiPackage]="withSkiPackage">
  </vsk-search-popup-mobile>
</ng-container>

<vsk-search-navbar (goToSearchPopup)="setShowSearch(true)"
                   *ngIf="!(windowResource.width() | async | screen: 'small-desktop-above') && !isHome"
                   [activities]="activities"
                   [dates]="period"
                   [participants]="participants">
</vsk-search-navbar>
