import { DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Moment } from 'moment';
import { Observable, combineLatest } from 'rxjs';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { TriggerAlert } from '../app.action';
import { AppState } from '../app.state';
import {
  EstablishmentRoom,
  ExperienceLink
} from '../cart/cart-content/cart-content.component';
import { FetchCart, TimerUpdate } from '../cart/cart.action';
import { CartState } from '../cart/cart.state';
import { UpdateFilters } from '../filters.action';
import { FiltersState } from '../filters.state';
import { WindowResource } from '../resource/window.resource';
import { NavigateService } from '../service/navigate.service';
import { ShopService } from '../service/shop.service';
import { RxjsComponent } from '../shared/component/rxjs.component';
import { Alert } from '../shared/models/alert';
import { Cart } from '../shared/models/cart/cart';
import { Participant } from '../shared/models/participant/participant';
import { Period } from '../shared/models/period';
import { UserSession } from '../shared/models/user-session';

@Component({
  selector: 'vsk-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentComponent extends RxjsComponent {
  @Select(AppState.user) user$: Observable<UserSession>;
  @Select(AppState.isUserLoggedIn) isLoggedIn$: Observable<boolean>;
  @Select(FiltersState.participants) participants$: Observable<Participant[]>;
  @Select(FiltersState.startDate) startDate$: Observable<Moment>;
  @Select(FiltersState.endDate) endDate$: Observable<Moment>;
  @Select(FiltersState.sessionId) sessionId$: Observable<string>;
  @Select(FiltersState.period) period$: Observable<Period>;
  @Select(CartState.cart) cart$: Observable<Cart>;
  @Select(CartState.totalPriceDisplay) totalPriceDisplay$: Observable<string>;
  @Select(CartState.isEcoTourismChartChecked)
  isEcoTourismeChecked$: Observable<boolean>;

  readonly Step = Step;

  participantsWithItems$: Observable<Participant[]>;
  step: Step = Step.CONNEXION;
  loading = false;
  payed = false;

  error: string;

  constructor(
    private shopService: ShopService,
    private store: Store,
    private navigateService: NavigateService,
    public windowResource: WindowResource,
    private activatedRoute: ActivatedRoute,
    private decimalPipe: DecimalPipe,
    private changeDetectorRef: ChangeDetectorRef,
    router: Router
  ) {
    super();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    document.title = 'Récapitulatif de votre séjour - VeryMountain';

    // Stripe script
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/';
    head.insertBefore(script, head.firstChild);

    this.activatedRoute.queryParamMap
      .pipe(
        debounceTime(100),
        switchMap((value) =>
          this.store.dispatch(
            new UpdateFilters(value, router.url.split('?')[0] === '/')
          )
        )
      )
      .subscribe();

    this.register(
      this.cart$.pipe(tap(() => (this.loading = true))).subscribe(() => {
        this.loading = false;
        this.changeDetectorRef.markForCheck();
      })
    );

    this.register(
      combineLatest([
        this.isLoggedIn$,
        this.cart$.pipe(filter((cart) => !cart.isEmpty)),
        this.sessionId$
      ])
        .pipe(
          filter(([isLogged]) => isLogged),
          tap(() => (this.loading = true)),
          filter(() => this.step !== Step.CONFIRMATION),
          switchMap(([, cart, sessionId]) =>
            this.shopService.syncUserAndSession(sessionId, cart.getResort())
          )
        )
        .subscribe(
          () => {
            if (this.step === Step.CONNEXION) {
              this.step = Step.PARTICIPANT;
            }

            this.loading = false;
            this.changeDetectorRef.markForCheck();
          },
          () => (this.loading = false)
        )
    );

    this.participantsWithItems$ = combineLatest([
      this.participants$,
      this.cart$
    ]).pipe(
      map(([participants, cart]) => {
        const uniqueParticipantUuids = new Set<string>();

        if (cart.itemsActivity.length) {
          cart.itemsActivity
            .map((item) => item.participants)
            .reduce((prev, curr) => [...prev, ...curr])
            .map((participant) => participant.uuid)
            .forEach((uuid) => uniqueParticipantUuids.add(uuid));
        }

        if (uniqueParticipantUuids.size > 0) {
          return participants.filter((participant) =>
            uniqueParticipantUuids.has(participant.uuid)
          );
        } else if (!cart.itemsAccommodation.length) {
          return participants.filter(
            (participant) =>
              cart.getItemsActivityByParticipant(participant).length
          );
        }

        return participants;
      })
    );

    setTimeout(() => window.scrollTo(0, 0), 1000);
  }

  next(): void {
    this.step += 1;

    if (this.step === Step.PAYMENT) {
      this.loading = true;
      const priceOrigin = this.store.selectSnapshot(CartState.cart).getPrice();
      this.store.dispatch(new FetchCart());
      this.cart$
        .pipe(filter(() => this.step === Step.PAYMENT))
        .subscribe((cart) => {
          const diffPrice = priceOrigin - cart.getPrice();

          if (diffPrice > 0) {
            const priceString = this.decimalPipe.transform(
              diffPrice,
              '1.2-2',
              'fr'
            );
            this.store.dispatch(
              new TriggerAlert(
                new Alert({
                  message: `Vos forfaits ont biens été pris en compte • -${priceString} € sur votre panier`,
                  timeout: 5000,
                  level: 'success'
                })
              )
            );
          }
        });
    }
  }

  previous(): void {
    if (!this.payed) {
      this.step -= 1;
    }
  }

  isDone(step: Step) {
    return this.step >= step;
  }

  paymentSuccess() {
    this.payed = true;
    this.step = Step.CONFIRMATION;
    this.store.dispatch(new TimerUpdate(null));
  }

  changeLoading(newLoading: boolean) {
    this.loading = newLoading;
    this.changeDetectorRef.detectChanges();
  }

  goBackToResort() {
    this.navigateService.navigateWithQueryParams('/resort/activity');
  }

  redirectToMap() {
    this.navigateService.navigateWithQueryParams('/map');
  }

  redirectToEstablishment(establishmentRoom: EstablishmentRoom) {
    this.navigateService.navigateWithQueryParams('/resort/room', {
      establishmentId: establishmentRoom.establishment.id,
      roomCode: establishmentRoom.room.codeRoom,
      partnerCode: establishmentRoom.establishment.partnerCode,
      station: establishmentRoom.resort
    });
  }

  redirectToExperience(ExperienceLink: ExperienceLink) {
    this.navigateService.navigateWithQueryParams('/resort/details-activity', {
      experienceId: ExperienceLink.experienceId,
      station: ExperienceLink.resort
    });
  }
}

export enum Step {
  CONNEXION = 0,
  PARTICIPANT = 1,
  PAYMENT = 2,
  CONFIRMATION = 4
}
