import { Moment } from 'moment';
import { TemporaryCartExperience } from '../shared/interface/temporary-cart-experience';
import { FiltersExperienceSearch } from '../shared/models/activity/filters-experience-search';
import { ActivityFilters } from '../shared/models/const/activity-filters';
import { Criteria } from '../shared/models/criteria';
import { ExperienceCategoryEnum } from '../shared/models/enum/experience-category.enum';
import { FiltersAccommodationSearch } from '../shared/models/filters/filters-accommodation-search';

export class ResetStoreState {
  static readonly type = '[ResortState] Reset state';
}

export class FetchExperience {
  static readonly type = '[ResortState] Fetch experience by id';

  constructor(public experienceId: number) {}
}

export class ResortName {
  static readonly type = '[ResortState] Set current resort name';

  constructor(public name: string) {}
}

export class TemporaryExperienceCart {
  static readonly type =
    '[ResortState] Temporary instance of last experience cart';

  constructor(public temporaryCartExperience: TemporaryCartExperience | null) {}
}

export class FetchExperiences {
  static readonly type = '[ResortState] Fetch resort experiences';

  constructor(public criteria: Criteria) {}
}

export class FetchSimilarExperiences {
  static readonly type = '[ResortState] Fetch similar experiences';

  constructor(public activityId: number) {}
}

export class FetchExperiencesGroup {
  static readonly type = '[ResortState] Fetch resort experiences group';

  constructor(
    public filtersExperienceSearch: FiltersExperienceSearch,
    public page: number,
    public type: ExperienceCategoryEnum = ExperienceCategoryEnum.GENERAL,
    public init = false
  ) {}
}

export class FetchEquipmentPacks {
  static readonly type = '[ResortState] Fetch equipment packs';

  constructor(
    public firstSkiDate: Moment,
    public duration: number,
    public resortName?: string
  ) {}
}

export class FetchEstablishments {
  static readonly type = `[ResortState] Fetch establishments`;

  constructor(
    public filters: FiltersAccommodationSearch,
    public page: number,
    public reset = true
  ) {}
}

export class FiltersResortChangeOrderBy {
  static readonly type = `[ResortState] Change Filter Order By`;

  constructor(public activityFilters: ActivityFilters) {}
}

export class SetNavbarSticky {
  static readonly type = `[ResortState] Change navbar sticky`;

  constructor(public sticky: boolean) {}
}
