<atc-card-map [accommodations]="station?.totalRooms || 0"
              [activities]="station?.activitiesSelected || []"
              [affinity]="station.affinity"
              [experiences]="station?.totalActivities || 0"
              [hasMaterial]="station.priceMaterial > 0"
              [hasPackage]="filters.packagesDisplayed"
              [isDesktop]="windowResource.isDesktop()"
              [labels]="getLabels() || []"
              [link]="getLink()"
              [location]="station.regionLabel"
              [logo]="station.urlLogo"
              [minified]=cardIsMinified
              [nbNights]="nbNights"
              [ngClass]="{'is-in-map': isInMap}"
              [params]="getParams()"
              [priceAccommodation]="station.priceAccommodation"
              [priceActivity]="station.priceActivity"
              [pricePerPerson]="station.totalPricePerPerson"
              [price]="station.totalPrice"
              [src]="station.urlsCover[0]"
              [title]="station.name"
              class="vsk__card">
</atc-card-map>
