import { DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { Store } from '@ngxs/store';
import { TimeSlotDate, WindowResource } from 'atomic-lib';
import moment, { Moment } from 'moment';
import { TriggerAlert } from '../../app.action';
import { ChangeCartDrawerState } from '../../cart/cart.action';
import { TemporaryExperienceCart } from '../../resort/resort.action';
import { ResortState } from '../../resort/resort.state';
import { DateUtils } from '../../utils/date-utils';
import { PackageUtils } from '../../utils/package-utils';
import { ManageCartComponent } from '../component/manage-cart.component';
import { Experience } from '../models/activity/experience';
import { Alert } from '../models/alert';
import { Cart } from '../models/cart/cart';
import { ItemCart } from '../models/package/item-cart';
import { Package } from '../models/package/package';
import { PackageGroup } from '../models/package/package-group';
import { Participant } from '../models/participant/participant';
import { Internship } from '../models/partner/internship';
import { TimeSlotExperience } from '../models/partner/time-slot-experience';
import { Period } from '../models/period';
import { Resort } from '../models/resort/resort';

@Component({
  selector: 'vsk-experience-drawer',
  templateUrl: './experience-drawer.component.html',
  styleUrls: ['./experience-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExperienceDrawerComponent
  extends ManageCartComponent
  implements OnChanges, OnDestroy
{
  currentInternship: Internship;
  currentTimeSlotExperience: TimeSlotExperience;
  timeSlotsExperience: TimeSlotExperience[] = [];
  packages: Package[] = [];
  @Input() period: Period;
  @Input() experience: Experience;
  @Input() currentDay: Moment;
  @Input() currentTimeSlotIndex = 0;
  @Input() currentInternshipIndex = 0;
  @Input() participants: Participant[] = [];
  @Input() cart: Cart;
  @Input() resort: Resort;
  @Input() showDrawer = false;
  @Output() showDrawerChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() showAlertSuccessChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  protected readonly PackageUtils = PackageUtils;

  constructor(
    protected store: Store,
    protected decimalPipe: DecimalPipe,
    public windowResource: WindowResource,
    protected changeDetectorRef: ChangeDetectorRef
  ) {
    super(store, decimalPipe, changeDetectorRef);
    this.reset();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const tempCart = this.store.selectSnapshot(
      ResortState.temporaryCartExperience
    );
    if (
      tempCart?.idExperience === this.experience.id &&
      tempCart?.selectedDate &&
      tempCart?.participants?.length
    ) {
      this.editMode = tempCart?.isEditMode || false;
      this.dateSelectedChange(tempCart?.selectedDate);
      this.participantsTimeSlot = tempCart?.participants;
    } else {
      const period: Period = changes.period as any;
      if (
        (!period || period !== this.period) &&
        !!this.experience &&
        !!this.period
      ) {
        const timeSlots: TimeSlotExperience[] = this.experience.hasInternship
          ? this.experience.internships
              .map((internship) => internship.timeSlots)
              .reduce((prev, curr) => [...prev, ...curr])
          : this.experience.timeSlots;

        const firstDay = timeSlots
          .sort((prev, curr) =>
            prev.dateStart.isSameOrBefore(curr.dateStart, 'days') ? -1 : 1
          )
          .map((timeSlot) => timeSlot.dateStart)[0];
        this.currentDay =
          !period && this.currentDay ? this.currentDay : firstDay;
        this.getTimeSlots(this.currentDay);

        this.store.dispatch(
          new TemporaryExperienceCart({
            selectedDate: this.currentDay,
            idExperience: this.experience.id,
            participants: []
          })
        );
        this.changeDetectorRef.markForCheck();
      }
    }

    if (!!this.currentTimeSlotExperience && this.participants.length) {
      this.packages = PackageUtils.sortByAges(
        this.currentTimeSlotExperience.packages,
        this.participants
      );
      this.selectParticipants();
      this.changeDetectorRef.markForCheck();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.reset();
    this.store.dispatch(
      new TemporaryExperienceCart({
        participants: this.participantsTimeSlot
      })
    );
  }

  hasSlotsAvailable(timeSlot: TimeSlotExperience) {
    if (this.experience.hasInternship) {
      return timeSlot.slotsAvailable > 0;
    }

    return timeSlot.remainingSlots > 0;
  }

  getTimeSlots(day: Moment) {
    if (!this.experience.hasInternship) {
      this.timeSlotsExperience = this.experience?.timeSlots
        .filter((timeSlot) => timeSlot.dateStart.isSame(day, 'day'))
        .filter((timeSlot) => this.hasSlotsAvailable(timeSlot))
        .map((timeSlot) => {
          timeSlot.dateStart = timeSlot.dateStart.set({
            hours: timeSlot.hourStart.hours(),
            minutes: timeSlot.hourStart.minutes()
          });
          return timeSlot;
        })
        .sort((prev, curr) =>
          moment(prev.hourStart, 'HH:mm:ss').isSameOrBefore(
            moment(curr.hourStart, 'HH:mm:ss'),
            'minute'
          )
            ? -1
            : 1
        );

      if (this.timeSlotsExperience.length) {
        this.currentTimeSlotExperience =
          this.timeSlotsExperience[this.currentTimeSlotIndex];
        this.packages = this.currentTimeSlotExperience.packages;
      }
    } else {
      this.currentInternshipIndex = 0;
      this.currentInternship =
        this.experience.internships[this.currentInternshipIndex];
      this.currentTimeSlotExperience = this.timeSlotsExperience[0];
      this.packages = this.currentInternship.packages;
    }
  }

  dateSelectedChange(date: Moment) {
    this.itemsGroup = [];
    this.currentDay = date;
    this.getTimeSlots(date);
    this.currentTimeSlotIndex = 0;
    this.reset();
    this.selectParticipants();
    this.changeDetectorRef.markForCheck();

    this.store.dispatch(
      new TemporaryExperienceCart({
        selectedDate: date
      })
    );
  }

  selectParticipants() {
    const storeParticipants = this.store.selectSnapshot(
      ResortState.temporaryCartExperience
    )?.participants;
    if (storeParticipants?.length) {
      this.participantsTimeSlot = storeParticipants;
    } else {
      let availableParticipants: Participant[] = [];
      this.packages.forEach((pack) => {
        availableParticipants = PackageUtils.getParticipantsForPackage(
          pack,
          this.participants
        );
      });

      const limit = this.experience.hasInternship
        ? this.currentInternship.slotAvailable
        : this.currentTimeSlotExperience.remainingSlots;
      let count = 0;
      availableParticipants.forEach((participant) => {
        const isSelected = count < limit;
        this.changeParticipant(participant, isSelected);
        if (isSelected) count++;
      });
    }

    this.calculatePrice();
    this.changeDetectorRef.markForCheck();
  }

  getLabelTimeSlot(timeSlot: TimeSlotExperience) {
    return (
      timeSlot.hourStart.format('HH[h]mm') +
      ' - ' +
      timeSlot.hourEnd.format('HH[h]mm')
    );
  }

  timeSlotSelectedChange(indexSlot: number) {
    if (this.experience.hasInternship) {
      if (this.currentInternshipIndex === indexSlot) {
        return;
      }

      this.currentInternshipIndex = indexSlot;
      this.currentInternship = this.experience.internships[indexSlot];
      this.packages = this.currentInternship.packages;
    } else {
      if (this.currentTimeSlotIndex === indexSlot) {
        return;
      }

      this.itemsGroup = [];
      this.currentTimeSlotIndex = indexSlot;
      this.currentTimeSlotExperience =
        this.timeSlotsExperience[this.currentTimeSlotIndex];
      this.packages = this.currentTimeSlotExperience.packages;
    }

    this.calculatePrice();
    this.changeDetectorRef.markForCheck();
  }

  getWarningSlot(slotsAvailable: number | undefined) {
    if (!slotsAvailable || slotsAvailable > 20) {
      return '';
    }

    if (slotsAvailable === 0) {
      return `Il ne reste plus de places disponibles ...`;
    }

    const participantsAvailable: Participant[] = [];
    this.participants.forEach((participant) => {
      if (
        PackageUtils.getAgeRangeForParticipant(
          this.experience.ageRanges,
          participant
        )
      ) {
        participantsAvailable.push(participant);
      }
    });
    if (slotsAvailable < participantsAvailable.length && slotsAvailable !== 1) {
      return `Il n'y a que ${slotsAvailable} place${slotsAvailable > 1 ? 's' : ''} disponibles sur ce créneau`;
    }

    return ``;
  }

  postRegisterAction(): void {
    this.closeDrawer();
    this.showAlertSuccessChange.emit(true);
    this.store.dispatch(new ChangeCartDrawerState(true));
    this.store.dispatch(
      new TriggerAlert(
        new Alert({
          message: this.editMode
            ? 'Activité modifiée !'
            : 'Activité ajoutée au panier !',
          timeout: 3000,
          level: 'success'
        })
      )
    );
    this.editMode = false;
  }

  closeDrawer() {
    this.reset();
    this.showDrawer = !this.showDrawer;
    setTimeout(() => this.showDrawerChange.emit(this.showDrawer), 600);
  }

  isDisabled(participant: Participant) {
    if (
      !PackageUtils.getAgeRangeForParticipant(
        this.experience.ageRanges,
        participant
      )
    ) {
      return true;
    }

    if (this.isSelected(participant)) {
      return false;
    }

    if (
      this.participantsTimeSlot.length ===
      PackageUtils.getMaxParticipants(this.packages)
    ) {
      return true;
    }

    if (this.currentInternship) {
      return (
        this.currentInternship.slotAvailable ===
        this.participantsTimeSlot.length
      );
    }

    return (
      this.currentTimeSlotExperience?.remainingSlots ===
      this.participantsTimeSlot.length
    );
  }

  isSelected(participant: Participant) {
    return (
      this.participantsTimeSlot.map((p) => p.uuid).indexOf(participant.uuid) !==
      -1
    );
  }

  changeParticipant(participant: Participant, isSelected: boolean) {
    if (isSelected) {
      this.participantsTimeSlot.push(participant);
    } else {
      this.participantsTimeSlot = this.participantsTimeSlot.filter(
        (p) => p.uuid !== participant.uuid
      );
    }

    this.store.dispatch(
      new TemporaryExperienceCart({
        participants: this.participantsTimeSlot
      })
    );
    this.calculatePrice();
    this.changeDetectorRef.markForCheck();
  }

  participantIsNotAllowed(participants: Participant[]): boolean {
    return participants.some(
      (participant) => PackageUtils.ageMin(this.packages) > participant.age
    );
  }

  calculatePrice() {
    const participants = this.participantsTimeSlot;
    this.itemsToAdd = this.packages
      .filter(
        (pck) =>
          !!PackageUtils.getParticipantsForPackage(pck, participants).length
      )
      .map((pck) => {
        const date = this.experience.hasInternship
          ? this.currentInternship.dateStart.clone()
          : this.currentTimeSlotExperience.dateStart.clone();
        const endDate = this.experience.hasInternship
          ? this.currentInternship.dateEnd.clone()
          : this.currentTimeSlotExperience.dateStart.clone();

        if (!this.experience.hasInternship) {
          endDate.set({
            hour: this.currentTimeSlotExperience.hourEnd.hour(),
            minute: this.currentTimeSlotExperience.hourEnd.minute()
          });
        }

        return new ItemCart({
          item: new Package({
            ...pck,
            experience: new Experience({ id: this.experience.id })
          }),
          internshipId: this.currentInternship
            ? this.currentInternship.id || this.currentInternship.parentId
            : undefined,
          timeSlotModelId:
            this.currentTimeSlotExperience?.timeSlotModelId || undefined,
          origin: this.experience.origin,
          startDate: date,
          endDate: endDate,
          participants: PackageUtils.getParticipantsForPackage(
            pck,
            participants
          ),
          resort: this.resort.name
        });
      });

    if (this.itemsToAdd.length === 0) {
      this.reset();
      return;
    }

    if (this.itemsToAdd.length === 1) {
      this.total = this.itemsToAdd.map((item) => item.price)[0];
      this.totalPublic = this.itemsToAdd.map((item) => item.pricePublic)[0];
    }

    if (this.itemsToAdd.length > 1) {
      this.total = this.itemsToAdd
        .map((item) => item.price)
        .reduce((prev, curr) => prev + curr);

      this.totalPublic = this.itemsToAdd
        .map((item) => item.pricePublic)
        .reduce((prev, curr) => prev + curr);
    }

    const packGroups = this.experience.packagesGroup;
    const idsToGenerate = packGroups.map((packGroup) => packGroup.id);
    const allGroups = this.generate(
      idsToGenerate,
      Math.round(this.participantsTimeSlot.length / 2)
    );

    this.itemsGroup = [];

    if (allGroups?.length) {
      const availableGroupPck = this.groupCombination(
        allGroups,
        participants,
        packGroups
      );
      this.calculateBestPrice(availableGroupPck, participants);
    }
  }

  calculateBestPrice(
    availableGroupPck: PackageGroup[][],
    participants: Participant[]
  ) {
    let bestPrice: number | undefined;

    for (const groups of availableGroupPck) {
      let groupPrice = 0;
      let groupPublicPrice = 0;
      let pcksAlone: Package[] = [];
      let participantsRemaining: Participant[] = [...participants];

      for (const group of groups) {
        for (const pck of group.packages) {
          const filtered = participantsRemaining.find((p) =>
            DateUtils.isSameCategoryByBirthdate(p.birthdate, pck.ageRange)
          );

          if (filtered) {
            participantsRemaining = participantsRemaining.filter(
              (p) => p.uuid !== filtered.uuid
            );
          }
        }

        groupPrice += group.price;
        groupPublicPrice += group.publicPrice;
      }

      if (participantsRemaining.length) {
        pcksAlone = this.packages.filter(
          (pck) =>
            !!PackageUtils.getParticipantsForPackage(pck, participantsRemaining)
              .length
        );

        groupPrice += pcksAlone
          .map(
            (pck) =>
              PackageUtils.getParticipantsForPackage(pck, participantsRemaining)
                .length *
              pck.priceByParticipantSizeAndDates(
                PackageUtils.getParticipantsForPackage(
                  pck,
                  participantsRemaining
                ).length,
                this.currentTimeSlotExperience?.dateStart
              )
          )
          .reduce((prev, curr) => prev + curr);

        groupPublicPrice += pcksAlone
          .map(
            (pck) =>
              PackageUtils.getParticipantsForPackage(pck, participantsRemaining)
                .length *
              pck.priceByParticipantSizeAndDates(
                PackageUtils.getParticipantsForPackage(
                  pck,
                  participantsRemaining
                ).length,
                this.currentTimeSlotExperience?.dateStart,
                true
              )
          )
          .reduce((prev, curr) => prev + curr);
      }

      const date = this.experience.hasInternship
        ? this.currentInternship.dateStart.clone()
        : this.currentTimeSlotExperience.dateStart.clone();
      const endDate = this.experience.hasInternship
        ? this.currentInternship.dateEnd.clone()
        : this.currentTimeSlotExperience.dateStart.clone();

      if (!bestPrice || groupPrice < bestPrice) {
        bestPrice = groupPrice;
        this.total = groupPrice;
        this.totalPublic = groupPublicPrice;
        this.itemsGroup = groups;
        const items: ItemCart[] = [];
        participantsRemaining = [...participants];

        groups.forEach((group) => {
          const participantsFiltered: Participant[] = group.packages.map(
            (pck) => {
              const filtered = participantsRemaining.find((p) =>
                DateUtils.isSameCategoryByBirthdate(p.birthdate, pck.ageRange)
              ) as Participant;
              participantsRemaining = participantsRemaining.filter(
                (p) => p.uuid !== filtered.uuid
              );
              return filtered;
            }
          );

          items.push(
            new ItemCart({
              group: group,
              internshipId: this.currentInternship
                ? this.currentInternship.id || this.currentInternship.parentId
                : undefined,
              timeSlotModelId:
                this.currentTimeSlotExperience?.timeSlotModelId || undefined,
              origin: this.experience.origin,
              startDate: date,
              endDate: endDate,
              participants: participantsFiltered,
              resort: this.store.selectSnapshot(ResortState.resortName)
            })
          );
        });

        this.itemsToAdd = [
          ...items,
          ...pcksAlone.map((pck) => {
            return new ItemCart({
              item: pck,
              internshipId: this.currentInternship
                ? this.currentInternship.id || this.currentInternship.parentId
                : undefined,
              timeSlotModelId:
                this.currentTimeSlotExperience?.timeSlotModelId || undefined,
              origin: this.experience.origin,
              startDate: date,
              endDate: endDate,
              participants: PackageUtils.getParticipantsForPackage(
                pck,
                participantsRemaining
              ),
              resort: this.store.selectSnapshot(ResortState.resortName)
            });
          })
        ];
      }
    }
  }

  groupCombination(
    allGroups: number[][],
    participants: Participant[],
    packGroups: PackageGroup[]
  ) {
    const availableGroupPck: PackageGroup[][] = [];
    let combination: PackageGroup[] = [];
    let participantsRemaining: Participant[] = [];

    for (const group of allGroups) {
      participantsRemaining = [...participants];

      for (const id of group) {
        const pcks = packGroups.find((g) => g.id === id)?.packages as Package[];
        const ids = [...pcks.map((pck) => pck.id)];

        for (const pck of pcks) {
          const filtered = participantsRemaining.find((participant) =>
            DateUtils.isSameCategoryByBirthdate(
              participant.birthdate,
              pck.ageRange
            )
          );

          if (filtered) {
            participantsRemaining = participantsRemaining.filter(
              (p) => p.uuid !== filtered.uuid
            );
            ids.splice(ids.indexOf(id), 1);
          }

          if (!ids.length) {
            const item = this.experience.packagesGroup.find(
              (pckGroup) => pckGroup.id === id
            ) as PackageGroup;
            combination.push(item);
          }
        }
      }

      if (combination.length) {
        if (
          !availableGroupPck.find(
            (group) =>
              group.map((pck) => pck.id).join(',') ===
              combination.map((pck) => pck.id).join(',')
          )
        ) {
          availableGroupPck.push(combination);
        }

        combination = [];
      }
    }

    return availableGroupPck;
  }

  generate(array: number[], size: number) {
    const holdingArr: number[][] = [];
    const recursive = (singleSolution: number[]) => {
      if (singleSolution.length > size - 1) {
        const alreadyThere = !!holdingArr.find(
          (group) => group.sort().join(',') === singleSolution.sort().join(',')
        );

        if (!alreadyThere) {
          holdingArr.push(singleSolution);
        }

        return;
      }

      for (const element of array) {
        recursive(singleSolution.concat([element]));
      }
    };

    recursive([]);
    return holdingArr;
  }

  cantAddToCart() {
    if (this.experience.withAccommodation) {
      return this.total === 0 || this.cart.itemsAccommodation.length === 0;
    }
    return this.total === 0;
  }

  mapToSlots(internship: Internship): TimeSlotDate[] {
    return internship.timeSlots.map((timeSlot) => {
      return {
        hourStart: timeSlot.dateStart.clone().set({
          hour: timeSlot.hourStart.hour(),
          minute: timeSlot.hourStart.minute(),
          second: 0
        }),
        hourEnd: timeSlot.dateStart.clone().set({
          hour: timeSlot.hourEnd.hour(),
          minute: timeSlot.hourEnd.minute(),
          second: 0
        })
      };
    });
  }

  getDaysDisabled(period: Period) {
    if (!this.experience || this.experience.hasInternship || !period) {
      return [];
    }

    return DateUtils.allDatesBetween2Dates(
      period.startDate,
      period.endDate
    ).filter(
      (day) =>
        !this.experience?.timeSlots.filter((timeSlot) =>
          timeSlot.dateStart.isSame(day, 'day')
        ).length
    );
  }

  getAgeFromBirthDate(birthdate: Moment): string {
    const age: number = moment().diff(birthdate, 'years');
    if (age > 18) {
      return 'adulte';
    }
    return age.toString() + ' ans';
  }

  getCategoryPrice(pcks: Package[]): string {
    if (this.participantsTimeSlot.length > 0) {
      if (this.itemsGroup.length) {
        return (
          'Vous bénéficiez du tarif de groupe : ' +
          this.itemsGroup.map((group) => group.name) +
          ' - ' +
          this.itemsGroup.map((group) => group.price) +
          '€'
        );
      }

      if (
        pcks
          .map((pck) => pck.prices)
          .reduce((price) =>
            price.filter((price) => price.nbMin === price.nbMax)
          ).length > 1
      ) {
        const lessExpensive = pcks
          .map((pck) => pck.prices)
          .filter((pckPrice) =>
            pckPrice.map((price) => price.dateIsMatching(this.currentDay))
          )
          .reduce((prev, curr) => [...prev, ...curr])
          .reduce((prev, curr) => (prev.price <= curr.price ? prev : curr));

        return (
          "Vous bénéficiez d'un tarif dégressif pouvant aller jusqu'a " +
          lessExpensive.price +
          '€ /pers pour ' +
          lessExpensive.nbMin +
          ' personnes'
        );
      }
    }
    //tarif simple ex : 70€/ pers => on affiche rien
    return '';
  }
}
