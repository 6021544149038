import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngxs/store';
import { OptionElement } from 'atomic-lib';
import { debounceTime } from 'rxjs/operators';
import { FiltersState } from '../../../filters.state';
import { Resort } from '../../../shared/models/resort/resort';
import { SetResorts } from '../../navbar.action';

@Component({
  selector: 'vsk-form-resort',
  templateUrl: './form-resort.component.html',
  styleUrls: ['./form-resort.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormResortComponent {
  readonly LEFT = '-190px';
  readonly WIDTH = '555px';

  resortsElement: OptionElement<Resort>[] = [];
  searchForm: FormControl<string | null> = new FormControl<string>('');

  @Input() responsive = false;
  @Input() showPicker = false;
  @Output() validate = new EventEmitter<void>();
  @Input() currentPage: 'Home' | 'Map' | 'Resort' | 'none' = 'none';
  @Input() isInNavbar = false;

  constructor(
    private changeDetection: ChangeDetectorRef,
    private store: Store
  ) {
    this.searchForm.valueChanges.pipe(debounceTime(300)).subscribe((search) => {
      this.resortsElement.forEach((resortElement) => {
        if (!search?.length) {
          resortElement.classCss = undefined;
          return;
        }

        if (
          resortElement.label.toLowerCase().indexOf(search.toLowerCase()) === -1
        ) {
          resortElement.classCss = 'hide';
        } else {
          resortElement.classCss = undefined;
        }
      });

      this.changeDetection.markForCheck();
    });
  }

  @Input() set resorts(resorts: Resort[]) {
    if (resorts?.length) {
      const selectedResorts = this.store.selectSnapshot(
        FiltersState.filtersAccommodations
      ).resorts;

      this.resortsElement = resorts
        .sort((prev, curr) => (prev.name < curr.name ? -1 : 1))
        .map((resort) => {
          let form = new FormControl<boolean>(false);
          if (selectedResorts && selectedResorts.indexOf(resort.id) !== -1) {
            form = new FormControl<boolean>(true);
          }
          form.valueChanges.subscribe(this.selectedChange.bind(this));

          return {
            id: resort,
            label: resort.name,
            control: form
          };
        });
    }
  }

  getControl(control: FormControl<boolean | null> | undefined) {
    return control as FormControl<boolean | null>;
  }

  selectedChange() {
    const ids: number[] = this.resortsElement
      .filter((element) => element.control?.value)
      .map((element) => element.id?.id as number);

    this.store.dispatch(new SetResorts(ids));
  }

  resetResorts() {
    this.resortsElement.forEach((element) => element.control?.setValue(false));
  }

  validation(): void {
    this.showPicker = false;
    this.validate.emit();
  }
}
