import { Injectable } from '@angular/core';
import moment, { Moment } from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { EcoChart } from '../shared/models/cart/eco-chart';
import { CookiesType } from '../shared/models/cookies-type';
import { UserSession } from '../shared/models/user-session';

@Injectable()
export class LocalStorageResource {
  static readonly LANG = 'language';
  static readonly TOKEN_KEY = 'auth-token';
  static readonly REFRESH_TOKEN_KEY = 'auth-refresh-token';
  static readonly USER_KEY = 'auth-user';
  static readonly COOKIES_CONSENT = 'cookies';
  static readonly COOKIES_TYPE = 'cookies-type';
  static readonly SESSION_ID = 'session-id';
  static readonly LAST_SESSION_TIMESTAMP = 'last-session-timestamp';
  static readonly OLD_SESSION_ID = 'old-session-id';
  static readonly FILTER_ACCOMMODATION = 'filter-accommodation';
  static readonly FILTER_PACKAGE = 'filter-package';
  static readonly FILTER_MATERIAL = 'filter-material';
  static readonly CHARTE_ECO = 'eco-tourism-chart';

  static getConsentCookies(): boolean | null {
    if (
      window.localStorage.getItem(LocalStorageResource.COOKIES_CONSENT) === null
    ) {
      return null;
    }
    return JSON.parse(
      window.localStorage.getItem(
        LocalStorageResource.COOKIES_CONSENT
      ) as string
    ) as boolean;
  }

  static getCookiesType(): CookiesType {
    if (
      window.localStorage.getItem(LocalStorageResource.COOKIES_TYPE) === null
    ) {
      return new CookiesType({
        googleAnalytics: true,
        googleAds: true,
        hotjar: true,
        pixelFacebook: true
      });
    }
    return JSON.parse(
      window.localStorage.getItem(LocalStorageResource.COOKIES_TYPE) as string
    ) as CookiesType;
  }

  static getUser(): UserSession | null {
    if (window.localStorage.getItem(LocalStorageResource.USER_KEY) === null) {
      return null;
    }
    return new UserSession(
      JSON.parse(localStorage.getItem(LocalStorageResource.USER_KEY) as string)
    );
  }

  static getToken(): string {
    return localStorage.getItem(LocalStorageResource.TOKEN_KEY) as string;
  }

  static getRefreshToken(): string {
    return localStorage.getItem(
      LocalStorageResource.REFRESH_TOKEN_KEY
    ) as string;
  }

  static getLang(): string {
    return window.sessionStorage.getItem(LocalStorageResource.LANG) !== null
      ? (window.sessionStorage.getItem(LocalStorageResource.LANG) as string)
      : 'fr';
  }

  static getSessionId(): string {
    if (window.localStorage.getItem(LocalStorageResource.SESSION_ID) === null) {
      const sessionId = uuidv4();
      window.localStorage.setItem(LocalStorageResource.SESSION_ID, sessionId);
      return sessionId;
    }
    return localStorage.getItem(LocalStorageResource.SESSION_ID) as string;
  }

  static getLastSessionDate(): Moment | null {
    if (
      window.localStorage.getItem(
        LocalStorageResource.LAST_SESSION_TIMESTAMP
      ) === null
    ) {
      return null;
    }

    return moment(
      localStorage.getItem(
        LocalStorageResource.LAST_SESSION_TIMESTAMP
      ) as string
    );
  }

  static updateLastSessionDate() {
    window.localStorage.setItem(
      LocalStorageResource.LAST_SESSION_TIMESTAMP,
      moment().toISOString()
    );
  }

  static getOldSessionId(): string | null {
    return window.localStorage.getItem(LocalStorageResource.OLD_SESSION_ID);
  }

  static IsEcoChart(): EcoChart {
    return new EcoChart(
      window.localStorage.getItem(LocalStorageResource.CHARTE_ECO)
        ? Boolean(
            JSON.parse(
              window.localStorage.getItem(
                LocalStorageResource.CHARTE_ECO
              ) as string
            )
          )
        : // @TODO pass to false once the design is validated
          true
    );
  }

  static getFilterAccommodation(): boolean {
    if (
      window.localStorage.getItem(LocalStorageResource.FILTER_ACCOMMODATION) ===
      null
    ) {
      return true;
    }

    return Boolean(
      JSON.parse(
        localStorage.getItem(
          LocalStorageResource.FILTER_ACCOMMODATION
        ) as string
      )
    );
  }

  static getFilterSkiPackage(): boolean {
    if (
      window.localStorage.getItem(LocalStorageResource.FILTER_PACKAGE) === null
    ) {
      return false;
    }

    return Boolean(
      JSON.parse(
        localStorage.getItem(LocalStorageResource.FILTER_PACKAGE) as string
      )
    );
  }

  static getFilterMaterial(): boolean {
    if (
      window.localStorage.getItem(LocalStorageResource.FILTER_MATERIAL) === null
    ) {
      return false;
    }

    return Boolean(
      JSON.parse(
        localStorage.getItem(LocalStorageResource.FILTER_MATERIAL) as string
      )
    );
  }
}
