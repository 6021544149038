import { LocalStorageResource } from '../../../resource/local-storage.resource';
import { ItemAccommodation } from '../accommodation/item-accommodation';
import { CopyCart } from '../package/copy-cart';
import { ItemCart } from '../package/item-cart';
import { Participant } from '../participant/participant';
import { PromoCode } from '../promo-code';
import { PromoType } from '../promo-type';
import { CartItemSkiEquipment } from '../ski-equipment/cart-item-equipment';
import { EcoChart } from './eco-chart';

export class Cart {
  itemsActivity: ItemCart[] = [];
  itemsAccommodation: ItemAccommodation[] = [];
  itemsSkiEquipment: CartItemSkiEquipment[] = [];
  promoCode: PromoCode | null;
  ecoTourismChart: EcoChart;
  isProposition = false;
  sessionId: string;

  constructor(obj: Partial<Cart>) {
    Object.assign(this, obj);
    this.itemsActivity = this.itemsActivity.map((item) => new ItemCart(item));
    this.itemsAccommodation = this.itemsAccommodation.map(
      (item) => new ItemAccommodation(item)
    );
    this.itemsSkiEquipment = this.itemsSkiEquipment.map(
      (item) => new CartItemSkiEquipment(item)
    );

    if (!this.ecoTourismChart) {
      this.ecoTourismChart = LocalStorageResource.IsEcoChart();
    }
  }

  get isEmpty(): boolean {
    return (
      this.itemsActivity.length === 0 &&
      this.itemsAccommodation.length === 0 &&
      this.itemsSkiEquipment.length === 0
    );
  }

  public get quantityActivities(): number {
    return this.itemsActivity.length;
  }

  public get quantityAccommodation(): number {
    return this.itemsAccommodation.length;
  }

  public get quantitySkiEquipment(): number {
    return this.itemsSkiEquipment.length;
  }

  get hasPromoCode() {
    return !!this.promoCode && this.getPrice() >= this.promoCode.minPrice;
  }

  public getQuantity(): number {
    return (
      this.quantityActivities +
      this.quantityAccommodation +
      this.quantitySkiEquipment
    );
  }

  public getItemsActivityByParticipant(participant: Participant): ItemCart[] {
    return this.itemsActivity.filter(
      (itemCart) =>
        itemCart.participants.findIndex(
          (part) => participant.uuid === part.uuid
        ) !== -1
    );
  }

  public getItemsAccommodationByParticipant(
    participant: Participant
  ): ItemAccommodation[] {
    return this.itemsAccommodation.filter(
      (itemCart) =>
        itemCart.participants.findIndex(
          (part) => participant.uuid === part.uuid
        ) !== -1
    );
  }

  public getPrice(): number {
    return (
      (!this.ecoTourismChart.isChecked ? this.ecoTourismChart.price : 0) +
      this.priceActivities() +
      this.priceAccommodation() +
      this.priceSkiEquipment()
    );
  }

  public getPublicPrice(): number {
    return (
      (!this.ecoTourismChart.isChecked ? this.ecoTourismChart.price : 0) +
      this.priceActivities(true) +
      this.priceAccommodation(true) +
      this.priceSkiEquipment(true)
    );
  }

  public priceAccommodation(publicPrice = false): number {
    const itemsFiltered = this.itemsAccommodation.filter(
      (item) => item.selectForm.value
    );

    if (!itemsFiltered.length) {
      return 0;
    }

    return itemsFiltered
      .map((item) => (publicPrice ? item.publicPrice : item.price))
      .reduce((previousValue, currentValue) => currentValue + previousValue);
  }

  public priceSkiEquipment(publicPrice = false): number {
    const itemsFiltered = this.itemsSkiEquipment.filter(
      (item) => item.selectForm.value
    );

    if (!itemsFiltered.length) {
      return 0;
    }

    return itemsFiltered
      .map((item) => (publicPrice ? item.totalPublicPrice : item.totalPrice))
      .reduce((previousValue, currentValue) => currentValue + previousValue);
  }

  public priceActivities(publicPrice = false): number {
    const itemsFiltered = this.itemsActivity.filter(
      (item) => item.selectForm.value
    );

    if (!itemsFiltered.length) {
      return 0;
    }

    return itemsFiltered
      .map((itemCart) => (publicPrice ? itemCart.pricePublic : itemCart.price))
      .reduce((previousValue, currentValue) => currentValue + previousValue);
  }

  public getPriceWithPromo() {
    const price = this.getPrice();
    if (!this.promoCode) {
      return price;
    }

    if (this.promoCode.typePromo === PromoType.REDUCTION) {
      return price - price * this.promoCode.applyNumber;
    }

    const newPrice = price - this.promoCode.applyNumber;
    return newPrice < 0 ? 0 : newPrice;
  }

  public removeElementIfParticipantIsAbsent(participants: Participant[]): void {
    this.itemsActivity = this.itemsActivity.filter((item) => {
      return (
        item.participants.filter((participant) =>
          participants.map((prt) => prt.uuid).includes(participant.uuid)
        ).length > 0
      );
    });
  }

  getResort() {
    if (this.itemsActivity.length) {
      return this.itemsActivity[0].resort;
    }

    if (this.itemsAccommodation.length) {
      return this.itemsAccommodation[0].resort;
    }

    return '';
  }

  hasParticipant(participant: Participant) {
    if (this.getItemsActivityByParticipant(participant).length > 0) {
      return true;
    }

    return this.getItemsAccommodationByParticipant(participant).length > 0;
  }

  filterNotSelected(sessionId: string) {
    this.itemsAccommodation = this.itemsAccommodation.filter(
      (item) => item.selectForm.value
    );
    this.itemsActivity = this.itemsActivity.filter(
      (item) => item.selectForm.value
    );
    this.itemsSkiEquipment = this.itemsSkiEquipment.filter(
      (item) => item.selectForm.value
    );

    return this.mapToCopyCart(sessionId);
  }

  private mapToCopyCart(sessionId: string) {
    return new CopyCart({
      itemsAccommodation: this.itemsAccommodation.map((item) => item.id),
      itemsActivity: this.itemsActivity.map((item) => item.uuid),
      itemsEquipment: this.itemsSkiEquipment.map((item) => item.id),
      session: sessionId
    });
  }
}
