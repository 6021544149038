import {
  ChangeDetectorRef,
  Component,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { SkisetParams, WindowResource } from 'atomic-lib';
import moment, { Moment } from 'moment/moment';
import { Observable, combineLatest } from 'rxjs';
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { CartState } from '../../cart/cart.state';
import { FiltersState } from '../../filters.state';
import { RxjsComponent } from '../../shared/component/rxjs.component';
import { Cart } from '../../shared/models/cart/cart';
import { Criteria } from '../../shared/models/criteria';
import { Participant } from '../../shared/models/participant/participant';
import { Period } from '../../shared/models/period';
import { Resort } from '../../shared/models/resort/resort';
import { ParamsSelectedEquipment } from '../../shared/models/ski-equipment/equipment-selected';
import { Pack } from '../../shared/models/ski-equipment/pack';
import { FetchEquipmentPacks } from '../resort.action';
import { ResortState } from '../resort.state';

@Component({
  selector: 'vsk-resort-material',
  templateUrl: './resort-material.component.html',
  styleUrls: ['./resort-material.component.scss']
})
export class ResortMaterialComponent
  extends RxjsComponent
  implements OnChanges
{
  @Select(ResortState.equipmentPacks) equipmentPacks$: Observable<Pack[]>;
  @Select(FiltersState.participants) participants$: Observable<Participant[]>;
  @Select(FiltersState.resort) resort$: Observable<Resort>;
  @Select(FiltersState.period) period$: Observable<Period>;
  @Select(FiltersState.sessionId) sessionId$: Observable<string>;
  @Select(CartState.cart) cart$: Observable<Cart>;

  paramsSelected: ParamsSelectedEquipment;
  _period: Period;
  filteredPacks: Pack[] = [];
  uniqueGroupNames: string[];
  uniqueAgeGroups: string[];
  selectedGroupName: string;
  selectedAgeGroup: string;
  selectedParticipant: Participant;
  startDate: Moment;
  endDate: Moment;
  selectedStartDate: Moment | undefined;
  selectedEndDate: Moment | undefined;
  duration: number | undefined;
  loading = false;
  showSkiEquipmentDrawer = false;
  currentResortName: string | undefined;

  readonly AGE_LIMIT_CHILD = 13;
  readonly AGE_GROUP_CHILD = 'Child';
  readonly AGE_GROUP_ADULT = 'Adult';

  constructor(
    public windowResource: WindowResource,
    protected store: Store,
    protected changeDetectorRef: ChangeDetectorRef
  ) {
    super();
    this.register(
      this.period$.subscribe((period) => {
        this._period = period;
        if (period) {
          this.startDate = period.startDate;
          this.endDate = period.endDate;
          this.selectedStartDate = period.startDate;
          this.selectedEndDate = period.endDate;
          this.duration = period.endDate.diff(period.startDate, 'days') + 1;
          this.duration = Math.min(Math.max(this.duration, 2), 14);
        }
      })
    );

    this.register(
      combineLatest([this.equipmentPacks$, this.participants$]).subscribe(
        ([packs, participants]) => {
          this.selectedParticipant = participants[0];
          this.initializePacks(packs, participants);
        }
      )
    );

    this.register(
      combineLatest([
        this.sessionId$.pipe(filter((sessionId) => !!sessionId)),
        this.period$,
        this.resort$.pipe(filter((resort) => !!resort && !!resort.name)),
        this.participants$
      ])
        .pipe(
          debounceTime(300),
          map(
            ([sessionId, period, resort, participants]) =>
              new Criteria({
                startDate: period.startDate,
                endDate: period.endDate,
                stationName: resort.name,
                sessionId,
                participants
              })
          ),
          filter((criteria) => criteria.isValid),
          switchMap((criteria) => {
            const calculatedDuration =
              criteria.endDate.diff(criteria.startDate, 'days') + 1;
            const duration = Math.max(2, Math.min(calculatedDuration, 14));
            return this.store.dispatch(
              new FetchEquipmentPacks(
                criteria.startDate,
                duration,
                criteria.stationName
              )
            );
          })
        )
        .subscribe(() => (this.loading = false))
    );

    this.register(
      this.resort$.subscribe((resort) => {
        this.currentResortName = resort?.name;
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedStartDate'] && this.selectedStartDate) {
      this.selectedStartDate = moment(
        this.selectedStartDate.format('YYYY-MM-DD')
      );
    }

    if (changes['selectedEndDate'] && this.selectedEndDate) {
      this.selectedEndDate = moment(this.selectedEndDate.format('YYYY-MM-DD'));
    }
  }

  initializePacks(packs: Pack[], participants: Participant[]) {
    this.loading = true;
    if (!packs || packs.length === 0) {
      return;
    }

    this.filteredPacks = [...packs];

    this.uniqueGroupNames = Array.from(
      new Set(packs.map((pack) => pack.groupName))
    );
    this.uniqueAgeGroups = Array.from(
      new Set(packs.map((pack) => pack.offer.ageGroup))
    );

    if (!this.selectedAgeGroup && this.uniqueAgeGroups.length > 0) {
      this.selectedAgeGroup = this.uniqueAgeGroups[0];
    }

    if (!this.selectedGroupName && this.uniqueGroupNames.length > 0) {
      this.selectedGroupName = this.uniqueGroupNames[0];
    }

    if (!this.selectedParticipant && participants && participants.length > 0) {
      this.selectedParticipant = participants[0];
    }

    this.applyFilters(packs);
    this.loading = false;
  }

  onParamsSelected(params: SkisetParams, pack: Pack) {
    const packIds = [
      ...(!params.hasShoes ? [pack.packId] : []),
      ...(params.hasShoes ? [pack.shoesPackId] : []),
      ...(params.hasHelmet ? [pack.helmetPackId] : [])
    ];
    const participant = this.selectedParticipant;
    this.paramsSelected = new ParamsSelectedEquipment({
      selectedPack: pack,
      packIds: packIds,
      participant: participant,
      duration: this.duration,
      startDate: this.selectedStartDate,
      endDate: this.selectedEndDate,
      hasShoes: params.hasShoes,
      hasHelmet: params.hasHelmet
    });
    this.showSkiEquipmentDrawer = true;
  }

  applyFilters(packs: Pack[]) {
    this.filteredPacks =
      packs?.filter(
        (pack) =>
          pack.groupName === this.selectedGroupName &&
          pack.offer.ageGroup === this.selectedAgeGroup
      ) || [];
    this.filteredPacks.sort((a, b) => (a > b ? 1 : -1));
  }

  onGroupNameSelected(groupName: string, packs: Pack[]) {
    this.selectedGroupName = groupName;
    this.applyFilters(packs);
  }

  onAgeGroupSelected(participant: Participant, packs: Pack[]) {
    this.selectedParticipant = participant;
    this.selectedAgeGroup =
      participant.age < this.AGE_LIMIT_CHILD
        ? this.AGE_GROUP_CHILD
        : this.AGE_GROUP_ADULT;
    this.applyFilters(packs);
  }

  isSelected(participant: Participant) {
    return this.selectedParticipant === participant;
  }

  onDateRangeSelected($event: {
    startDate: Moment | undefined;
    endDate: Moment | undefined;
    duration: number | undefined;
  }) {
    this.selectedStartDate = moment($event.startDate?.format('YYYY-MM-DD'));
    this.selectedEndDate = $event.endDate;
    this.duration = $event.duration;

    if ((this.duration && this.duration < 2) || this.duration === undefined) {
      this.duration = 2;
    }

    if (this.duration > 14) {
      this.duration = 14;
    }

    combineLatest([
      this.store.dispatch(
        new FetchEquipmentPacks(
          this.selectedStartDate,
          this.duration,
          this.currentResortName
        )
      ),
      this.equipmentPacks$,
      this.participants$
    ]).subscribe(([, packs, participants]) => {
      this.initializePacks(packs, participants);
      this.changeDetectorRef.markForCheck();
    });
  }
}
