<atc-button (click)="showCart = true"
            *ngIf="!windowResource.isDesktop"
            [fullWidth]="true"
            class="flex flex-1 mb-4"
            color="ternary"
            label="Voir le détail de mon panier">
</atc-button>

<vsk-form-participant-payment (participantComplete)="addParticipantValid($event)"
                              *ngFor="let participant of participants | sortParticipants; let i = index"
                              [index]="participant.index"
                              [participantInfo]="participant"
                              [showError]="error"
                              [skiCard]="getSkiCardForParticipant(participant)"
                              [user]="userSession">
</vsk-form-participant-payment>

<div *ngIf="cart.quantityAccommodation"
     class="bg-white rounded-[30px] flex flex-col gap-4 p-8 mb-4">
  <h4>Souhaitez-vous ajouter des instructions pour votre hébergement ?</h4>
  <atc-textarea [control]="instructionsForm"
                label="Instructions complémentaires"
                placeholder="Instructions ..."
                size="small"></atc-textarea>
</div>

<p *ngIf="error" class="error mb-4">Des champs obligatoires ne sont pas saisis</p>
<div class="flex flex-row gap-2 sm:mb-[8vh]">
  <atc-button (click)="previousPage()"
              color="ternary"
              icon="left-arrow">
  </atc-button>

  <atc-button (click)="goNextStep()"
              [fullWidth]="true"
              class="flex w-full"
              color="secondary"
              icon="cart"
              id="id__button-validate-participants-information"
              label="Paiement">
  </atc-button>
</div>

<vsk-cart [(showCart)]="showCart"
          [canDeleteItem]="false">
</vsk-cart>

