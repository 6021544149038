import moment, { Moment } from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { AccommodationType } from '../accommodation/accommodation-type.enum';
import { Activity } from '../activity/activity';
import { OrderBy } from '../const/order-by';
import { Participant } from '../participant/participant';
import { FilterRange } from './filter-range';

export class FiltersStation {
  id: string;
  startDate: Moment;
  endDate: Moment;
  mapPolygon: google.maps.LatLngBoundsLiteral | undefined;
  participants: Participant[];
  activities: Activity[];
  orderBy: OrderBy = OrderBy.RELEVANCE;
  geoBoundsActive = false;
  accommodationsDisplayed: boolean;
  packagesDisplayed: boolean;
  materialDisplayed: boolean;
  priceRange: FilterRange;
  resorts: number[];
  selectedTags: number[];
  selectedLabels: number[];
  selectedRegions: number[];
  pool: boolean;
  spa: boolean;
  sauna: boolean;
  hammam: boolean;
  balconyTerrace: boolean;
  tv: boolean;
  chimney: boolean;
  bbq: boolean;
  pmr: boolean;
  parking: boolean;
  animalsAdmitted: boolean;
  wifi: boolean;
  childrenClub: boolean;
  types: AccommodationType[];

  constructor(obj: Partial<FiltersStation>) {
    Object.assign(this, obj);
    this.id = uuidv4();

    if (this.startDate) {
      this.startDate = moment(this.startDate);
    }

    if (this.endDate) {
      this.endDate = moment(this.endDate);
    }

    if (!this.priceRange) {
      this.priceRange = new FilterRange({ min: 0, max: 0 });
    }
  }

  participantsAreValid(): boolean {
    if (this.participants === undefined || this.participants.length === 0) {
      return false;
    }

    return (
      this.participants
        .map((participant) => new Participant(participant).isValid())
        .filter((isValid) => !isValid).length === 0
    );
  }
}
