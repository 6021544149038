import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Activity } from 'src/app/shared/models/activity/activity';
import { FiltersState } from '../../../filters.state';
import { WindowResource } from '../../../resource/window.resource';
import { RxjsComponent } from '../../../shared/component/rxjs.component';
import { FiltersStation } from '../../../shared/models/filters/filters-station';
import { ResortMap } from '../../../shared/models/resort/resort-map';

@Component({
  selector: 'vsk-list-station-card',
  templateUrl: './card-list-station.component.html',
  styleUrls: ['./card-list-station.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardListStationComponent extends RxjsComponent {
  @Select(FiltersState.sessionId) sessionId$: Observable<string>;

  @Input() station: ResortMap;
  @Input() isInMap = false;
  @Input() activities: Activity[] = [];
  @Input() filters: FiltersStation;
  @Input() selectedActivities: Activity[] = [];
  @Input() nbNights = 0;
  @Input() loading = true;
  @Input() cardIsMinified = false;

  constructor(public windowResource: WindowResource) {
    super();
  }

  getParams(): any {
    return {
      station: this.station?.name
    };
  }

  getLabels() {
    if (!this.station.labels) {
      return [];
    }

    return this.station?.labels.map((label) => label.name);
  }

  getLink(): string {
    if (this.cardIsMinified) {
      return '/station/informations';
    }

    if (this.filters?.accommodationsDisplayed) {
      return '/station/hebergements';
    }

    if (this.selectedActivities?.length) {
      return '/station/activites';
    }

    return '/station/informations';
  }
}
