<atc-breadcrumb (itemClick)="goToWithParams($event)"
                *ngIf="(noProposals || period) && room"
                [items]="breadcrumbItems"
                class="mx-auto mt-[10px] flex max-w-[1350px] sxl:px-8">
</atc-breadcrumb>

<div *ngIf="establishment" class="relative mx-auto mt-2 flex max-w-[1350px] flex-col gap-x-4 xl:mx-auto sxl:px-8">

  <ng-container *ngIf="room">

    <h1> {{ establishment.name }} </h1>

    <section class="feature-list flex flex-row gap-[1rem] py-[.3rem]">

      <div *ngIf="room.surface > 0" class="feature-icon">
        <svg class="h-[14px] w-[14px] stroke-black">
          <use href="assets/icons/icons.svg#surface"></use>
        </svg>
        <h3> {{ room.surface }} m² </h3>
      </div>

      <span *ngIf="room.surface > 0" class="h-[2rem] w-[1px] bg-[#7a7a7a]"></span>

      <div *ngIf="room.maxPeople > 0" class="feature-icon">
        <svg class="icon">
          <use href="assets/icons/icons.svg#group"></use>
        </svg>
        <h3> {{ room.maxPeople }} max. </h3>
      </div>

    </section>

    <atc-picture-gallery (openPictures)="openPopup($event)"
                         [sizeGrid]="getSizeGrid(windowResource.width() | async)"
                         [slides]="getPictures(establishment.pictures, room.pictures)">
    </atc-picture-gallery>
  </ng-container>

  <ng-container *ngIf="noProposals && period">
    <div class="mx-auto min-h-[35vh] mt-4 items-center flex flex-col">
      <h3 class="text-center">
        Aucun logement disponible pour les dates du
        {{ period.startDate.locale('fr').format('DD MMMM YYYY') }} au
        {{ period.endDate.locale('fr').format('DD MMMM YYYY') }} ...
      </h3>
      <a class="mx-auto mt-8"
         queryParamsHandling="merge"
         routerLink="/station/hebergements"
         routerLinkActive="true">
        <atc-button color="ternary"
                    icon="home"
                    label="Voir les autres hébergements de la station">
        </atc-button>
      </a>
    </div>
  </ng-container>

  <ng-container *ngIf="(!noProposals || !period) && !room">
    <div *ngIf="windowResource.width() | async | screen: 'small-desktop-above'"
         class="flex flex-col gap-4 mx-auto sm:gap-2
    xl:w-[1350px] w-[100%] max-w-[1350px] xl:flex-col xl:px-0 px-[30px] pt-2 pb-[4rem]">
      <div class="mx-auto min-h-[400px]">
        <h3 class="text-center">
          Nous recherchons les meilleurs prix pour votre séjour
        </h3>
      </div>
      <div class="flex w-full">
        <atc-loader class="mx-auto my-8" color="secondary"></atc-loader>
      </div>

      <div
        class="rounded-[15px] h-[35px]"
        vskSkeletonLoad>
      </div>
      <div class="rounded-[20px] h-[70px]" vskSkeletonLoad></div>
      <div
        class="flex flex-row justify-between gap-[10px] w-full">
        <div
          class="rounded-[20px] h-auto w-full" vskSkeletonLoad>
        </div>
        <div class="grid grid-cols-2 w-full gap-2">
          <div *ngFor="let nb of [0,1,2,3]"
               class="rounded-[20px] h-[250px]" vskSkeletonLoad>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!(windowResource.width() | async | screen: 'small-desktop-above')"
         class="flex flex-col gap-4 mx-auto sm:gap-2
    xl:w-[1350px] w-[100%] max-w-[1350px] xl:flex-col xl:px-0 px-[30px] pt-2 pb-[4rem]">
      <div
        class="rounded-[15px] h-[35px]"
        vskSkeletonLoad>
      </div>
      <div class="rounded-[20px] h-[70px]" vskSkeletonLoad></div>
      <div
        class="flex flex-row justify-between gap-[10px] w-full">
        <div
          class="rounded-[20px] h-[250px] w-full" vskSkeletonLoad>
        </div>
      </div>
    </div>


  </ng-container>

  <div class="mt-4 flex flex-row gap-x-6">
    <ng-container *ngIf="room">
      <div
        class="flex max-w-fit flex-[3] flex-col gap-4 sxl:max-w-[720px] lg:flex-[2] lg:pb-16 slg:max-w-full">
        <ng-container *ngIf="room">
          <ng-container *ngIf="criteria$ | async as criteria">
            <atc-card-add-to-cart (addToCartClicked)="addToCart(room, establishment, $event)"
                                  (openCartTrigger)="openCart()"
                                  (participantsChanged)="proposalsChange($event, room, criteria)"
                                  *ngIf="!(windowResource.width() | async | screen: 'small-desktop-above')"
                                  [accommodation]="true"
                                  [bookedRoom]="bookedRoom"
                                  [duration]="numberOfNights"
                                  [endDate]="period.endDate"
                                  [loading]="loading"
                                  [maxPeople]="room.maxPeople"
                                  [minPeople]="room.minPeople"
                                  [participants]="participants"
                                  [proposals]="room.proposals"
                                  [roomName]="room.name"
                                  [startDate]="period.startDate">
            </atc-card-add-to-cart>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="criteria$ | async as criteria">
          <h3><span class="mr-2">🏡</span>Votre résidence :</h3>
          <div class="p-container expand flex flex-col gap-4">
            <p [innerHTML]="establishment.description"></p>

            <h3 *ngIf="establishment.access">Accès</h3>
            <p [innerHTML]="establishment.access"></p>

            <h3 *ngIf="establishment.facilities">Facilités</h3>
            <p [innerHTML]="establishment.facilities"></p>

            <h3 *ngIf="establishment.information">Informations pratiques</h3>
            <p [innerHTML]="establishment.information"></p>
          </div>
          <ng-container *ngIf="isDescriptionDifferent()">
            <h3><span class="mr-2">🛏️</span>Votre logement</h3>
            <div class="expand flex flex-col gap-4">
              <p [innerHTML]="room.description"></p>
            </div>
          </ng-container>

          <atc-card-room [featuresEstablishment]="getFeaturesEstablishment(establishment)"
                         [featuresRoom]="getFeaturesRoom(room)">
          </atc-card-room>

          <h3>📍 Localisation</h3>

          <google-map (zoomChanged)="setZoom()"
                      [center]="center"
                      [options]="options"
                      [zoom]="14"
                      class="map-establishment flex-1"
                      height="300px">

            <map-marker *ngFor="let marker of markers"
                        [icon]="marker.getIcon()!"
                        [options]="getOptions(marker)"
                        [position]="marker.getPosition()!"
                        [title]="marker.getTitle()!">
            </map-marker>

            <map-marker *ngIf="markerResort"
                        [icon]="markerResort.getIcon()!"
                        [options]="getOptions(markerResort)"
                        [position]="markerResort.getPosition()!"
                        [title]="markerResort.getTitle()!"
            ></map-marker>
          </google-map>

          <atc-button (click)="scrollToPrice()"
                      *ngIf="!(windowResource.width() | async | screen: 'small-desktop-above')"
                      [fullWidth]="true"
                      class="mb-4 flex"
                      color="secondary"
                      label="Voir les tarifs">
          </atc-button>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="room">
      <ng-container *ngIf="criteria$ | async as criteria">
        <div *ngIf="windowResource.width() | async | screen: 'small-desktop-above'"
             class="info-content">
          <atc-card-add-to-cart (addToCartClicked)="addToCart(room, establishment, $event)"
                                (openCartTrigger)="openCart()"
                                (participantsChanged)="proposalsChange($event, room, criteria)"
                                *ngIf="windowResource.width() | async | screen: 'small-desktop-above'"
                                [accommodation]="true"
                                [bookedRoom]="bookedRoom"
                                [duration]="numberOfNights"
                                [endDate]="period.endDate"
                                [loading]="loading"
                                [maxPeople]="room.maxPeople"
                                [minPeople]="room.minPeople"
                                [participants]="participants"
                                [proposals]="room.proposals"
                                [roomName]="room.name"
                                [startDate]="period.startDate">
          </atc-card-add-to-cart>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<div *ngIf="rooms?.length" class="bg-color-neutral-15 w-full pb-16 pt-8 sxl:px-8">
  <div class="mx-auto flex max-w-[1350px] flex-col">
    <h2>Autres logements disponibles</h2>

    <h3 class="mb-4">Sur la résidence</h3>
    <atc-carousel-products
      *ngIf="roomsCarousel?.length > 2 && (windowResource.width() | async | screen: 'small-desktop-above')"
      [products]="roomsCarousel"
      target="_self">
    </atc-carousel-products>

    <div *ngIf="roomsCarousel?.length <= 2 || !(windowResource.width() | async | screen: 'small-desktop-above')"
         class="flex max-w-full flex-row gap-x-4 overflow-x-auto">
      <atc-card-product-presentation *ngFor="let room of rooms"
                                     [background]="room.pictures[0]"
                                     [description]="room.description"
                                     [params]="{ roomCode: room.codeRoom }"
                                     [price]="room.proposals?.[0].price || 0"
                                     [region]="establishment.address"
                                     [tags]="getRoomTags(room)"
                                     [title]="room.name"
                                     link="/station/logement"
                                     target="_self">
      </atc-card-product-presentation>
    </div>
  </div>
</div>

<atc-alert *ngIf="showAlert"
           [level]="error ? 'error' : 'success'"
           class="fixed left-0 right-0 top-0 z-[101] w-full">
  {{ error ? error : success }}
</atc-alert>

<atc-popup (closeChanged)="showPhotoEstablishmentPopup = false"
           *ngIf="showPhotoEstablishmentPopup"
           [showBody]="false"
           [slides]="establishment.pictures">
</atc-popup>

<atc-popup (closeChanged)="roomPictures = []"
           *ngIf="roomPictures.length"
           [currentSlide]="currentSlide"
           [showBody]="false"
           [slides]="roomPictures">
</atc-popup>

<vsk-footer [isHome]="false"></vsk-footer>
