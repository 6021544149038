import { Moment } from 'moment';

export class Account {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  birthdate: Moment;
  address: string;
  password: string;
  city: string;
  postCode: string;
  country: string;
  provider: string;
  newsletter: boolean;

  constructor(obj: Partial<Account>) {
    Object.assign(this, obj);
  }
}
