import { FormControl } from '@angular/forms';
import moment, { Moment } from 'moment';
import { Participant } from '../participant/participant';
import { CartItemSkiEquipmentPack } from './cart-item-equipment-pack';

export class CartItemSkiEquipment {
  id: number;
  participantSession: Participant;
  pack: CartItemSkiEquipmentPack;
  totalPrice: number;
  totalPublicPrice: number;
  duration: number;
  startDate: Moment;
  endDate: Moment;
  selectForm = new FormControl<boolean>(true);

  constructor(obj: Partial<CartItemSkiEquipment>) {
    Object.assign(this, obj);

    if (this.startDate) {
      this.startDate = moment(this.startDate, 'YYYY-MM-DD');
    }

    if (this.endDate) {
      this.endDate = moment(this.endDate, 'YYYY-MM-DD');
    }

    if (obj && obj.participantSession) {
      this.participantSession = new Participant(obj.participantSession);
    }

    if (obj && obj.pack) {
      this.pack = new CartItemSkiEquipmentPack(obj.pack);
    }
  }
}
