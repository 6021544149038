import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Slide, WindowResource } from 'atomic-lib';
import { Observable, combineLatest } from 'rxjs';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { FiltersState } from '../../filters.state';
import { MetaDescriptionService } from '../../service/meta-description.service';
import { StationService } from '../../service/station.service';
import { RxjsComponent } from '../../shared/component/rxjs.component';
import { Experience } from '../../shared/models/activity/experience';
import { Criteria } from '../../shared/models/criteria';
import { Participant } from '../../shared/models/participant/participant';
import { Period } from '../../shared/models/period';
import { Resort } from '../../shared/models/resort/resort';
import { FetchExperiences } from '../resort.action';
import { ResortState } from '../resort.state';

@Component({
  selector: 'vsk-resort-information',
  templateUrl: './resort-information.component.html',
  styleUrls: ['./resort-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResortInformationComponent extends RxjsComponent {
  @Select(FiltersState.sessionId) sessionId$: Observable<string>;
  @Select(FiltersState.resort) resort$: Observable<Resort>;
  @Select(ResortState.experiences) experiences$: Observable<Experience[]>;
  @Select(FiltersState.participants) participants$: Observable<Participant[]>;
  @Select(FiltersState.period) period$: Observable<Period>;
  @Select(ResortState.experience) experience$: Observable<Experience>;
  loader = true;
  resortsLinks: Slide[] = [];
  experiencesFiltered: Experience[] = [];

  constructor(
    private changeDetectionRef: ChangeDetectorRef,
    private store: Store,
    public activatedRoute: ActivatedRoute,
    private stationService: StationService,
    public windowResource: WindowResource,
    private metaDescriptionService: MetaDescriptionService
  ) {
    super();

    this.register(
      combineLatest([
        this.sessionId$.pipe(filter((sessionId) => !!sessionId)),
        this.period$,
        this.resort$.pipe(filter((resort) => !!resort)),
        this.participants$
      ])
        .pipe(
          tap(() => {
            this.loader = true;
            this.changeDetectionRef.markForCheck();
          }),
          debounceTime(300),
          map(
            ([sessionId, period, resort, participants]) =>
              new Criteria({
                startDate: period.startDate,
                endDate: period.endDate,
                stationName: resort.name,
                sessionId,
                participants
              })
          ),
          filter((criteria) => criteria.isValid),
          switchMap((criteria) =>
            this.store.dispatch(new FetchExperiences(criteria))
          )
        )
        .subscribe(() => {
          this.loader = false;
          this.changeDetectionRef.markForCheck();
        })
    );

    this.register(
      this.experiences$.subscribe((experiences) => {
        this.experiencesFiltered = [];
        const activityIds = new Set(
          experiences.map((experience) => experience.activity.id)
        );
        activityIds.forEach((activityId) => {
          const experience = experiences.find(
            (experience) => experience.activity.id === activityId
          );

          if (experience) {
            this.experiencesFiltered.push(experience);
          }
        });

        this.experiencesFiltered = this.experiencesFiltered.sort(
          (prev, curr) => (prev.activity.name > curr.activity.name ? 1 : -1)
        );
        this.changeDetectionRef.markForCheck();
      })
    );

    this.register(
      combineLatest([
        this.period$,
        this.resort$.pipe(
          filter((resort) => !!resort),
          tap((resort) => {
            const title = `${resort.name}  · VeryMountain`;
            const description = `Retrouvez toutes les informations utiles sur ${resort.name} pour planifier votre séjour : hébergements, activités familiales ou bien-être, forfaits...`;

            const url = `station/informations`;
            this.metaDescriptionService.updateMeta(title, description, url);
          })
        ),
        this.participants$
      ])
        .pipe(
          debounceTime(300),
          switchMap(() => this.stationService.getAllStations()),
          map((resorts: Resort[]) => {
            let resortFiltered = resorts.sort((prev, curr) =>
              prev.name < curr.name ? -1 : 1
            );
            resortFiltered = this.randomResort(resortFiltered);
            return resortFiltered.splice(0, 6);
          })
        )
        .subscribe((resorts) => {
          this.resortsLinks = resorts.map((resort) => {
            return {
              src: resort.urlsCover[0],
              link: '/station/informations',
              srcResort: resort.urlLogo,
              params: {
                station: resort.name
              },
              target: '_self'
            } as Slide;
          });
          this.changeDetectionRef.markForCheck();
        })
    );
  }

  randomResort(array: Resort[]): Resort[] {
    for (let i = array.length - 1; i > 0; i--) {
      const randomIndex = Math.floor(Math.random() * (i + 1));
      [array[i], array[randomIndex]] = [array[randomIndex], array[i]];
    }
    return array;
  }

  getParams(params: Params, experienceId: number) {
    return {
      ...params,
      experienceId
    };
  }

  openItinerary(latitude: number, longitude: number) {
    const destination = `${latitude},${longitude}`;
    const googleMapUrl = `https://www.google.com/maps/search/?api=1&query=${destination}`;
    window.open(googleMapUrl, '_blank');
  }
}
