import { Injectable } from '@angular/core';
import { ParamMap, Router } from '@angular/router';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import moment, { Moment } from 'moment';
import { EMPTY, Observable, from } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { TriggerAlert } from './app.action';
import {
  CreateCartMaster,
  FetchCart,
  FetchPropositionCart,
  RemoveItemsWithNoParticipant
} from './cart/cart.action';
import {
  AddParticipants,
  Bounds,
  DeleteParticipant,
  FiltersNavbarChanged,
  FiltersStationChangeOrderBy,
  GenerateNewSessionId,
  GeoBoundsActiveChange,
  SetChangingFilters,
  SetFiltersAccommodations,
  SetFiltersExperiences,
  SetResort,
  SetSearchMode,
  SetWhiteLabel,
  TimeSlots,
  UpdateFilters,
  UpsertParticipant
} from './filters.action';
import { InitNavbar } from './navbar/navbar.action';
import { SetFilterResort } from './resort/resort-accommodation/accommodation.action';
import { LocalStorageResource } from './resource/local-storage.resource';
import { ParticipantResource } from './resource/participant.resource';
import { UrlManagerResource } from './resource/url-manager.resource';
import { SessionService } from './service/session.service';
import { FiltersAccommodation } from './shared/models/accommodation/filters-accommodation';
import { Activity } from './shared/models/activity/activity';
import { FiltersExperienceSearch } from './shared/models/activity/filters-experience-search';
import { Alert } from './shared/models/alert';
import { OrderBy } from './shared/models/const/order-by';
import { Criteria } from './shared/models/criteria';
import { DifficultyExperience } from './shared/models/enum/difficulty-experience.enum';
import { WhiteLabel } from './shared/models/enum/white-label.enum';
import { FiltersAccommodationSearch } from './shared/models/filters/filters-accommodation-search';
import { FiltersStation } from './shared/models/filters/filters-station';
import { CopyCart } from './shared/models/package/copy-cart';
import { Participant } from './shared/models/participant/participant';
import { TimeSlot } from './shared/models/partner/time-slot';
import { Period } from './shared/models/period';
import { Resort } from './shared/models/resort/resort';
import { Session } from './shared/models/session/session';
import { SessionCreate } from './shared/models/session/session-create';
import { DateUtils } from './utils/date-utils';
import { ParticipantUtils } from './utils/participant-utils';

export interface FiltersStateModel {
  startDate: Moment | null;
  endDate: Moment | null;
  participants: Participant[];
  activities: Activity[];
  activitiesSelected: Activity[];
  bounds: google.maps.LatLngBoundsLiteral;
  orderBy: OrderBy;
  geoBoundsActive: boolean;
  timeSlots: TimeSlot[];
  resort: Resort | null;
  sessionId: string;
  oldSessionId: string | null;
  isChangingFilters: boolean;
  whiteLabel: WhiteLabel;
  searchMode: 'all' | 'accommodation' | 'experience';
  filtersExperiences: FiltersExperienceSearch | null;
  filtersAccommodations: FiltersAccommodationSearch;
}

@State<FiltersStateModel>({
  name: 'filters',
  defaults: {
    startDate: null,
    endDate: null,
    participants: [],
    activities: [],
    activitiesSelected: [],
    bounds: {
      east: 15.279743671417222,
      north: 49.618420074289716,
      south: 41.862059405400565,
      west: -5.814006328582777
    },
    geoBoundsActive: true,
    searchMode: 'all',
    orderBy: OrderBy.RELEVANCE,
    timeSlots: [],
    resort: null,
    sessionId: LocalStorageResource.getSessionId(),
    oldSessionId: LocalStorageResource.getOldSessionId(),
    isChangingFilters: false,
    whiteLabel: WhiteLabel.NONE,
    filtersExperiences: null,
    filtersAccommodations: new FiltersAccommodationSearch({})
  }
})
@Injectable()
export class FiltersState {
  constructor(
    private participantResource: ParticipantResource,
    private sessionService: SessionService,
    private router: Router,
    private store: Store,
    private urlManager: UrlManagerResource
  ) {}

  @Selector()
  static getSelectedActivities(activities: Activity[]): number[] {
    if (!activities?.length) {
      return [];
    }

    return activities
      .filter((activity) => activity.selected)
      .map((activity) => activity.id);
  }

  @Selector()
  static startDate(state: FiltersStateModel): Moment | undefined {
    return state.startDate ? moment(state.startDate) : undefined;
  }

  @Selector()
  static endDate(state: FiltersStateModel): Moment | undefined {
    return state.endDate ? moment(state.endDate) : undefined;
  }

  @Selector()
  static period(state: FiltersStateModel): Period {
    return new Period({
      startDate: FiltersState.startDate(state) as Moment,
      endDate: FiltersState.endDate(state) as Moment
    });
  }

  @Selector()
  static dates(state: FiltersStateModel): Moment[] {
    const startDate = state.startDate;
    const endDate = state.endDate;

    if (!startDate || !endDate) {
      return [];
    }

    return DateUtils.allDatesBetween2Dates(moment(startDate), moment(endDate));
  }

  @Selector()
  static isPast(state: FiltersStateModel): boolean {
    return state.startDate
      ? moment(state.startDate).diff(moment(), 'day') < 0
      : false;
  }

  @Selector()
  static participants(state: FiltersStateModel): Participant[] {
    return state.participants.map(
      (participant) => new Participant(participant)
    );
  }

  @Selector()
  static activities(state: FiltersStateModel): Activity[] {
    return state.activities.map((activity) => new Activity(activity));
  }

  @Selector()
  static selectedActivities(state: FiltersStateModel): Activity[] {
    if (!state?.activitiesSelected.length) {
      return [];
    }

    return state.activitiesSelected.map((activity) => new Activity(activity));
  }

  @Selector()
  static notSelectedActivities(state: FiltersStateModel): Activity[] {
    return state.activities
      .map((activity) => new Activity(activity))
      .filter(
        (activity) =>
          state.activitiesSelected.map((act) => act.id).indexOf(activity.id) ===
          -1
      );
  }

  @Selector()
  static criteria(state: FiltersStateModel): Criteria {
    return new Criteria({
      startDate: state.startDate ? moment(state.startDate) : undefined,
      endDate: state.endDate ? moment(state.endDate) : undefined,
      participants: state.participants,
      stationName: state.resort?.name as string,
      activities: this.getSelectedActivities(state.activities),
      sessionId: state.sessionId
    });
  }

  @Selector()
  static sessionId(state: FiltersStateModel): string {
    return state.sessionId;
  }

  @Selector()
  static oldSessionId(state: FiltersStateModel): string | null {
    return state.oldSessionId;
  }

  @Selector()
  static filtersStation(state: FiltersStateModel): FiltersStation {
    return new FiltersStation({
      startDate: state.startDate as Moment,
      endDate: state.endDate as Moment,
      participants: state.participants,
      activities: state.activitiesSelected,
      orderBy: state.orderBy,
      ...(state.filtersAccommodations as FiltersAccommodation),
      mapPolygon: state.bounds as google.maps.LatLngBoundsLiteral,
      geoBoundsActive: state.geoBoundsActive
    });
  }

  @Selector()
  static timeSlots(state: FiltersStateModel): TimeSlot[] {
    return state.timeSlots;
  }

  @Selector()
  static resort(state: FiltersStateModel): Resort | null {
    return state.resort;
  }

  @Selector()
  static isChangingFilters(state: FiltersStateModel): boolean {
    return state.isChangingFilters;
  }

  @Selector()
  static searchMode(
    state: FiltersStateModel
  ): 'all' | 'accommodation' | 'experience' {
    return state.searchMode;
  }

  @Selector()
  static filtersExperiences(
    state: FiltersStateModel
  ): FiltersExperienceSearch | null {
    return new FiltersExperienceSearch({
      ...state.filtersExperiences,
      mapPolygon: state.bounds as google.maps.LatLngBoundsLiteral
    });
  }

  @Selector()
  static filtersAccommodations(state: FiltersStateModel): FiltersAccommodation {
    return state.filtersAccommodations;
  }

  @Selector()
  static bounds(state: FiltersStateModel): google.maps.LatLngBoundsLiteral {
    return state.bounds;
  }

  public static filtersExperiencesFromURL(
    filters: FiltersExperienceSearch,
    queryParams: ParamMap
  ) {
    return new FiltersExperienceSearch({
      ...filters,
      heart: filters.heart || JSON.parse(queryParams.get('heart') as string),
      promo: filters.promo || JSON.parse(queryParams.get('promo') as string),
      resorts: filters.resorts.length
        ? filters.resorts
        : JSON.parse(queryParams.get('resorts') ?? '[]').map((val: number) =>
            Number(val)
          ),
      activities: filters.activities.length
        ? filters.activities
        : JSON.parse(queryParams.get('activities') ?? '[]').map((val: number) =>
            Number(val)
          ),
      difficulties: filters.difficulties.length
        ? filters.difficulties
        : JSON.parse(queryParams.get('difficulties') ?? '[]').map(
            (val: DifficultyExperience) => val
          )
    });
  }

  @Selector()
  static whiteLabel(state: FiltersStateModel): WhiteLabel {
    return state.whiteLabel;
  }

  @Selector()
  static isFoncia(state: FiltersStateModel): boolean {
    return (
      state.whiteLabel === WhiteLabel.FONCIA ||
      state.whiteLabel === WhiteLabel.GSI
    );
  }

  @Action(UpdateFilters)
  updateFilters(ctx: StateContext<FiltersStateModel>, action: UpdateFilters) {
    const lastSession = LocalStorageResource.getLastSessionDate();
    if (lastSession?.isBefore(moment().add(-1, 'days'))) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      ctx.patchState({
        activities: [],
        participants: []
      });
      this.generateNewSessionId(ctx);
      LocalStorageResource.updateLastSessionDate();
      this.router.navigate(['/']);
      window.location.reload();
      return;
    }

    const sessionId = LocalStorageResource.getSessionId();
    const sessionMaster = action.queryParams.get('sessionMaster') as string;
    const noCart = Boolean(action.queryParams.get('noCart') as string);
    const sessionCreate: SessionCreate = new SessionCreate({
      id: sessionId,
      startDate: action.queryParams.get('startDate') as string,
      endDate: action.queryParams.get('endDate') as string,
      participants: ParticipantUtils.mapToParticipant(
        sessionId,
        Number(action.queryParams.get('nbAdults') as string),
        Number(action.queryParams.get('nbChildren') as string),
        Number(action.queryParams.get('nbSenior') as string)
      )
    });

    if (sessionMaster && !noCart) {
      this.store.dispatch(new FetchPropositionCart(sessionMaster));
      this.store.dispatch(
        new TriggerAlert(
          new Alert({
            level: 'success',
            message:
              'Nous vous avons préparé un séjour sur mesure, choisissez ce qui vous fait plaisir !',
            timeout: 10000,
            check: true
          })
        )
      );
    } else if (sessionMaster && noCart) {
      this.store
        .dispatch(
          new CreateCartMaster(
            new CopyCart({
              session: sessionMaster
            })
          )
        )
        .pipe(switchMap(() => this.sessionService.getSession(sessionCreate)))
        .subscribe((session) =>
          this.setupSession(session, sessionId, action, ctx)
        );
    } else {
      this.sessionService
        .getSession(sessionCreate)
        .subscribe((session) =>
          this.setupSession(session, sessionId, action, ctx)
        );
    }
  }

  setupSession(
    session: Session,
    sessionId: string,
    action: UpdateFilters,
    ctx: StateContext<FiltersStateModel>
  ) {
    if (!session.periodValid && !action.isHome) {
      session.startDate = moment();
      session.endDate = moment().add(3, 'days');
    }

    ctx.patchState({
      activities: session.activities,
      startDate: session.startDate,
      endDate: session.endDate,
      activitiesSelected: session.activitiesSelected,
      sessionId,
      participants: session.participants,
      isChangingFilters: false,
      filtersAccommodations: new FiltersAccommodationSearch({
        ...this.urlManager.fetchParams(
          Object.keys(new FiltersAccommodationSearch())
        ),
        accommodationsDisplayed:
          ctx.getState().filtersAccommodations.accommodationsDisplayed
      })
    });

    const selectedIds = session.activitiesSelected.map(
      (activity) => activity.id
    );
    const activitiesSelected = session.activities.map((activity) => {
      if (selectedIds.indexOf(activity.id) !== -1) {
        return new Activity({ ...activity, selected: true });
      }
      return activity;
    });

    this.store.dispatch(
      new InitNavbar(
        ctx.getState().participants,
        activitiesSelected,
        ctx.getState().filtersAccommodations.resorts,
        FiltersState.period(ctx.getState()),
        ctx.getState().filtersAccommodations.accommodationsDisplayed,
        ctx.getState().filtersAccommodations.packagesDisplayed,
        ctx.getState().filtersAccommodations.materialDisplayed
      )
    );

    if (session.periodValid) {
      this.urlManager.mergeParams({
        startDate: ctx.getState().startDate?.format('YYYY-MM-DD'),
        endDate: ctx.getState().endDate?.format('YYYY-MM-DD'),
        accommodationsDisplayed:
          ctx.getState().filtersAccommodations.accommodationsDisplayed
      });
    }

    localStorage.setItem(LocalStorageResource.SESSION_ID, sessionId);
    LocalStorageResource.updateLastSessionDate();
  }

  @Action(UpsertParticipant)
  addParticipant(
    ctx: StateContext<FiltersStateModel>,
    action: UpsertParticipant
  ): void {
    const state = ctx.getState();

    if (action.participant === null || action.participant === undefined) {
      return;
    }

    const participantsFiltered = [
      ...state.participants.filter(
        (participant) => action.participant.uuid !== participant.uuid
      )
    ];

    this.participantResource
      .upsert(action.participant, state.sessionId)
      .subscribe(() =>
        ctx.patchState({
          participants: [...participantsFiltered, action.participant]
        })
      );
  }

  @Action(DeleteParticipant)
  deleteParticipant(
    ctx: StateContext<FiltersStateModel>,
    action: DeleteParticipant
  ): void {
    const state = ctx.getState();

    if (action.participant === null || action.participant === undefined) {
      return;
    }

    const participants = [
      ...state.participants.filter(
        (participant) => action.participant.uuid !== participant.uuid
      )
    ];

    this.participantResource
      .deleteParticipant(action.participant, state.sessionId)
      .subscribe(() => {
        ctx.dispatch(new RemoveItemsWithNoParticipant(participants));
        ctx.patchState({
          participants
        });
      });
  }

  @Action(AddParticipants)
  addParticipants(
    ctx: StateContext<FiltersStateModel>,
    action: AddParticipants
  ): Observable<void> {
    if (
      action.participants === null ||
      action.participants === undefined ||
      action.participants.length === 0
    ) {
      return EMPTY;
    }

    const state = ctx.getState();

    return this.participantResource
      .upsertAll(action.participants, state.sessionId)
      .pipe(
        tap(() => {
          ctx.dispatch(new RemoveItemsWithNoParticipant(action.participants));
          ctx.patchState({
            participants: action.participants
          });
        })
      );
  }

  @Action(TimeSlots)
  timeSlots(ctx: StateContext<FiltersStateModel>, action: TimeSlots): void {
    if (action.timeSlots === null || action.timeSlots === undefined) {
      return;
    }

    ctx.patchState({
      timeSlots: action.timeSlots
    });
  }

  @Action(Bounds)
  bounds(ctx: StateContext<FiltersStateModel>, action: Bounds): void {
    ctx.patchState({
      bounds: action.bounds
        ? action.bounds
        : {
            east: 15.279743671417222,
            north: 49.618420074289716,
            south: 41.862059405400565,
            west: -5.814006328582777
          }
    });
  }

  @Action(FiltersNavbarChanged)
  filtersNavbarChanged(
    ctx: StateContext<FiltersStateModel>,
    action: FiltersNavbarChanged
  ) {
    const state = ctx.getState();

    if (action.navbarState.resorts?.length) {
      this.store.dispatch(new SetFilterResort(action.navbarState.resorts));
    }

    const participantsToDelete = state.participants.filter(
      (p) =>
        action.navbarState.participants
          .map((participant) => participant.uuid)
          .indexOf(p.uuid) === -1
    );

    participantsToDelete.forEach((participant) =>
      this.store.dispatch(new DeleteParticipant(participant))
    );

    const session = new Session({
      id: ctx.getState().sessionId,
      startDate: action.navbarState.startDate ?? moment().add(1, 'days'),
      endDate: action.navbarState.endDate ?? moment().add(3, 'days'),
      activitiesSelected: action.navbarState.activities.filter(
        (act) => act.selected
      ),
      participants: action.navbarState.participants
    });

    this.sessionService.upsert(session).subscribe();

    ctx.patchState({
      startDate: action.navbarState.startDate ?? moment().add(1, 'days'),
      endDate: action.navbarState.endDate ?? moment().add(3, 'days'),
      activities: action.navbarState.activities,
      activitiesSelected: action.navbarState.activities.filter(
        (act) => act.selected
      ),
      participants: action.navbarState.participants,
      filtersAccommodations: new FiltersAccommodationSearch({
        ...state.filtersAccommodations,
        resorts: action.navbarState.resorts
      })
    });

    return from(
      this.urlManager.mergeParams({
        startDate: action.navbarState.startDate?.format('YYYY-MM-DD'),
        endDate: action.navbarState.endDate?.format('YYYY-MM-DD'),
        accommodationsDisplayed: action.navbarState.withAccommodation
      })
    );
  }

  @Action(FiltersStationChangeOrderBy)
  filtersStationChangeOrderBy(
    ctx: StateContext<FiltersStateModel>,
    action: FiltersStationChangeOrderBy
  ): void {
    ctx.patchState({
      orderBy: action.orderBy
    });
  }

  @Action(GeoBoundsActiveChange)
  filtersStationChangeGeoBoundsActive(
    ctx: StateContext<FiltersStateModel>,
    action: GeoBoundsActiveChange
  ): void {
    ctx.patchState({
      geoBoundsActive: action.isActive
    });
  }

  @Action(SetResort)
  resort(ctx: StateContext<FiltersStateModel>, action: SetResort): void {
    ctx.patchState({
      resort: action.resort
    });
  }

  @Action(SetChangingFilters)
  setChangingFilters(
    ctx: StateContext<FiltersStateModel>,
    action: SetChangingFilters
  ): void {
    ctx.patchState({
      isChangingFilters: action.isChangingFilters
    });
  }

  @Action(SetSearchMode)
  setSearchMode(
    ctx: StateContext<FiltersStateModel>,
    action: SetSearchMode
  ): void {
    ctx.patchState({
      searchMode: action.searchMode
    });
  }

  @Action(SetFiltersAccommodations)
  setFiltersAccommodations(
    ctx: StateContext<FiltersStateModel>,
    action: SetFiltersAccommodations
  ): void {
    this.urlManager.mergeParams(action.filters.toQueryParams());

    ctx.patchState({
      filtersAccommodations: action.filters
    });
  }

  @Action(SetFiltersExperiences)
  setFiltersExperiences(
    ctx: StateContext<FiltersStateModel>,
    action: SetFiltersExperiences
  ): void {
    this.urlManager.mergeParams(action.filtersExperiences?.toQueryParams());

    ctx.patchState({
      filtersExperiences: action.filtersExperiences
    });
  }

  @Action(GenerateNewSessionId)
  generateNewSessionId(ctx: StateContext<FiltersStateModel>): void {
    const state = ctx.getState();
    const oldSessionId = state.sessionId;
    window.localStorage.removeItem(LocalStorageResource.SESSION_ID);
    window.localStorage.setItem(
      LocalStorageResource.OLD_SESSION_ID,
      oldSessionId
    );

    ctx.patchState({
      sessionId: LocalStorageResource.getSessionId(),
      oldSessionId
    });

    const session = new Session({
      id: LocalStorageResource.getSessionId(),
      startDate: ctx.getState().startDate as Moment,
      endDate: ctx.getState().endDate as Moment
    });

    if (ctx.getState().startDate && ctx.getState().endDate) {
      this.sessionService.upsert(session).subscribe();
    }

    this.urlManager.mergeParams({}, true);
    this.store.dispatch(new FetchCart());
  }

  @Action(SetWhiteLabel)
  setIsWhiteLabel(ctx: StateContext<FiltersStateModel>, action: SetWhiteLabel) {
    const isWhiteLabel =
      action.whiteLabel === WhiteLabel.FONCIA ||
      action.whiteLabel === WhiteLabel.GSI;
    const startDate = isWhiteLabel ? moment() : ctx.getState().startDate;
    const endDate = isWhiteLabel
      ? moment().add(7, 'days')
      : ctx.getState().endDate;

    ctx.patchState({
      whiteLabel: action.whiteLabel,
      startDate: startDate,
      endDate: endDate
    });

    const session = new Session({
      id: LocalStorageResource.getSessionId(),
      startDate: startDate as Moment,
      endDate: endDate as Moment
    });

    this.sessionService.upsert(session).subscribe();
  }
}
