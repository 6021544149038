import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { WindowResource } from 'atomic-lib';
import { filter } from 'rxjs/operators';
import { UrlManagerResource } from '../../resource/url-manager.resource';
import { FiltersService } from '../../service/filters.service';
import { MetaDescriptionService } from '../../service/meta-description.service';
import { ExperienceTemplateComponent } from '../../shared/component/experience-template.component';
import { ExperienceCategoryEnum } from '../../shared/models/enum/experience-category.enum';
import { ToHourPipe } from '../../shared/pipe/to-hour.pipe';

@Component({
  selector: 'vsk-resort-package',
  templateUrl: './resort-package.component.html',
  styleUrls: ['./resort-package.component.scss']
})
export class ResortPackageComponent extends ExperienceTemplateComponent {
  constructor(
    public windowResource: WindowResource,
    protected store: Store,
    protected hourPipe: ToHourPipe,
    protected translate: TranslateService,
    protected changeDetectionRef: ChangeDetectorRef,
    protected filtersService: FiltersService,
    protected activatedRoute: ActivatedRoute,
    public elRef: ElementRef,
    protected urlManager: UrlManagerResource,
    private metaDescriptionService: MetaDescriptionService
  ) {
    super(
      windowResource,
      store,
      hourPipe,
      translate,
      changeDetectionRef,
      filtersService,
      activatedRoute,
      urlManager
    );
    this.pageResort = true;
    this.type = ExperienceCategoryEnum.SKIPASS;

    this.register(
      this.resort$.pipe(filter((resort) => !!resort)).subscribe((resort) => {
        const title = `Forfaits à ${resort.name} · VeryMountain`;
        const description = `Pour profiter pleinement de votre séjour au ski, achetez vos forfaits à ${resort.name} en avance et gagnez du temps`;
        const url = `station/forfaits`;
        this.metaDescriptionService.updateMeta(title, description, url);
      })
    );
  }
}
