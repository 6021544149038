import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { WindowResource } from 'atomic-lib';
import hash from 'object-hash';
import { Observable, combineLatest, filter, of, skip, switchMap } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, tap } from 'rxjs/operators';
import { Bounds } from '../../filters.action';
import { FiltersState } from '../../filters.state';
import {
  FetchMoreEstablishment,
  ResetAccommodationState,
  SetFiltersAndFetchEstablishments
} from '../../resort/resort-accommodation/accommodation.action';
import { AccommodationState } from '../../resort/resort-accommodation/accommodation.state';
import { ResortState } from '../../resort/resort.state';
import { FiltersService } from '../../service/filters.service';
import { Establishment } from '../models/accommodation/establishment';
import { Criteria } from '../models/criteria';
import { FiltersAccommodationSearch } from '../models/filters/filters-accommodation-search';
import { FiltersInfoAccommodation } from '../models/filters/filters-info-accommodation';
import { MarkerWrapper } from '../models/marker-wrapper';
import { Participant } from '../models/participant/participant';
import { Period } from '../models/period';
import { Resort } from '../models/resort/resort';
import { MapComponent } from './map.component';

@Component({ standalone: true, template: '' })
export class AccommodationTemplateComponent
  extends MapComponent<Establishment>
  implements OnInit, OnDestroy
{
  init = false;
  loading = true;
  showPhotoPopup = false;
  nbNights = 0;
  nbParticipants = 0;
  pictures: string[] = [];
  filtersAccommodation: FiltersInfoAccommodation;
  pageResort = false;
  clearFilters = false;

  @Select(FiltersState.sessionId) sessionId$: Observable<string>;
  @Select(ResortState.establishments) establishments$: Observable<
    Establishment[]
  >;
  @Select(FiltersState.period) period$: Observable<Period>;
  @Select(FiltersState.resort) resort$: Observable<Resort>;
  @Select(FiltersState.criteria) criteria$: Observable<Criteria>;
  @Select(FiltersState.participants) participants$: Observable<Participant[]>;
  @Select(AccommodationState.filters)
  filtersSearch$: Observable<FiltersAccommodationSearch>;

  constructor(
    public activatedRoute: ActivatedRoute,
    public store: Store,
    protected filtersService: FiltersService,
    public windowResource: WindowResource,
    protected changeRef: ChangeDetectorRef
  ) {
    super(windowResource);
  }

  ngOnInit(): void {
    this.zoom = 5;

    this.register(
      combineLatest([
        this.period$.pipe(
          filter((period) => period.isValid),
          distinctUntilChanged(
            (prev, curr) =>
              prev.startDate.isSame(curr.startDate, 'day') &&
              prev.endDate.isSame(curr.endDate, 'day')
          ),
          tap(
            (period) =>
              (this.nbNights = period.endDate.diff(period.startDate, 'day'))
          )
        ),
        this.participants$.pipe(
          distinctUntilChanged(
            (prev, curr) =>
              hash(prev.map((participant) => participant.uuid)) ===
              hash(curr.map((participant) => participant.uuid))
          ),
          tap((participants) => (this.nbParticipants = participants.length))
        )
      ])
        .pipe(
          tap(() => (this.loading = true)),
          switchMap(([period, participants]) => {
            if (this.pageResort) {
              return combineLatest([
                of(period),
                of(participants),
                this.activatedRoute.queryParamMap.pipe(
                  debounceTime(100),
                  first()
                ),
                this.resort$.pipe(filter((resort) => !!resort))
              ]);
            }

            return combineLatest([
              of(period),
              of(participants),
              this.activatedRoute.queryParamMap.pipe(debounceTime(100))
            ]);
          }),
          switchMap(([period, participants, params, resort]) => {
            const filtersStore = this.store.selectSnapshot(
              FiltersState.filtersAccommodations
            );
            const bounds = this.store.selectSnapshot(FiltersState.bounds);

            const filters = new FiltersAccommodationSearch({
              ...filtersStore,
              startDate: period.startDate,
              endDate: period.endDate,
              resorts: this.pageResort
                ? [resort?.id as number]
                : filtersStore?.resorts || [],
              selectedRegions: this.pageResort
                ? []
                : filtersStore?.selectedRegions || [],
              selectedLabels: this.pageResort
                ? []
                : filtersStore?.selectedLabels || [],
              nbParticipants: participants.length,
              geoBoundsActive: this.refreshOnMoveMap.value || false,
              mapPolygon: bounds
            });

            this.init = true;

            return this.store.dispatch(
              new SetFiltersAndFetchEstablishments(filters, params)
            );
          })
        )
        .subscribe(() => {
          this.changeRef.markForCheck();
        })
    );

    this.register(
      this.filtersSearch$
        .pipe(
          skip(1),
          filter(
            (filters) =>
              filters.startDate?.isValid() &&
              filters.endDate?.isValid() &&
              !!filters.mapPolygon
          ),
          distinctUntilChanged((prev, curr) => hash(prev) === hash(curr)),
          tap(() => (this.loading = true)),
          debounceTime(500),
          switchMap((filters) => {
            if (this.pageResort) {
              filters.resorts = [
                this.store.selectSnapshot(FiltersState.resort)?.id as number
              ];
            }
            return this.filtersService.getFiltersAccommodationInfo(filters);
          })
        )
        .subscribe((filters) => {
          this.filtersAccommodation = filters;
          if (this.init) {
            this.loading = false;
          }

          this.changeRef.markForCheck();
        })
    );

    this.register(
      this.establishments$
        .pipe(skip(1), debounceTime(100))
        .subscribe((establishments) => {
          this.markers = establishments.map((establishment) => {
            const text = establishment.minPrice
              ? Math.round(establishment.minPrice) + ' €'
              : establishment.name;
            const marker = new MarkerWrapper<Establishment>(establishment, {
              clickable: true,
              position: {
                lat: Number(establishment.location.lat),
                lng: Number(establishment.location.lon)
              },
              title: establishment.name + ' - ' + establishment.partnerCode,
              label: {
                color: '#1f1f1f',
                text: text,
                fontWeight: '500',
                fontSize: '11px',
                className: 'pinpoint',
                fontFamily: 'General'
              },
              icon: {
                url: '../../../../assets/svg/pinpoint.svg',
                scaledSize: new google.maps.Size(
                  text.length * 5 + 40,
                  36,
                  'px',
                  'px'
                ),
                origin: new google.maps.Point(-13, -5)
              },
              anchorPoint: new google.maps.Point(0, 0),
              optimized: false,
              zIndex: 100
            });
            establishment.marker = marker;
            return marker;
          });

          this.placeMarkersOnMap();
        })
    );
  }

  openPhotosPopup(pictures: string[]) {
    this.pictures = pictures;
    this.showPhotoPopup = true;
  }

  loadMoreAccommodation() {
    window.scrollBy(0, -100);
    this.store.dispatch(new FetchMoreEstablishment());
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.store.dispatch(new ResetAccommodationState());
    this.store.dispatch(
      new Bounds({
        east: 15.279743671417222,
        north: 49.618420074289716,
        south: 41.862059405400565,
        west: -5.814006328582777
      })
    );
  }

  protected boundsChangedAction(
    bounds: google.maps.LatLngBoundsLiteral,
    refresh: boolean
  ): void {
    const filtersStore = this.store.selectSnapshot(
      FiltersState.filtersAccommodations
    );

    const filters = new FiltersAccommodationSearch({
      ...filtersStore,
      mapPolygon: bounds,
      geoBoundsActive: refresh
    });

    this.store.dispatch(
      new SetFiltersAndFetchEstablishments(
        filters,
        this.activatedRoute.snapshot.queryParamMap
      )
    );
  }
}
