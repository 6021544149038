import { Component, Input } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import moment from 'moment';
import { TriggerAlert } from '../../app.action';
import { AddPromoCode, DeletePromoCode } from '../../cart/cart.action';
import { WindowResource } from '../../resource/window.resource';
import { PromoService } from '../../service/promo.service';
import { DateUtils } from '../../utils/date-utils';
import { RxjsComponent } from '../component/rxjs.component';
import { Alert } from '../models/alert';
import { Cart } from '../models/cart/cart';
import { PromoCode } from '../models/promo-code';

@Component({
  selector: 'vsk-promo-code',
  templateUrl: './promo-code.component.html',
  styleUrls: ['./promo-code.component.scss']
})
export class PromoCodeComponent extends RxjsComponent {
  control: UntypedFormControl;

  @Input() promoCode: PromoCode | null;
  @Input() cart: Cart;
  @Input() paymentPage = false;

  colorMsg: 'success' | 'mandatory' = <const>'success';

  constructor(
    private promoService: PromoService,
    private store: Store,
    public windowResource: WindowResource
  ) {
    super();
    this.control = new UntypedFormControl('', Validators.required);
  }

  addPromoCode(): void {
    if (this.control.valid) {
      this.register(
        this.promoService.getPromoCode(this.control.value).subscribe(
          (code) => {
            if (
              DateUtils.isBetween(
                moment(),
                code.dateValidityStart,
                code.dateValidityEnd
              ) &&
              code.used < code.usedLimit
            ) {
              this.control.patchValue('');
              this.store.dispatch(new AddPromoCode(code));
              this.store.dispatch(
                new TriggerAlert(
                  new Alert({
                    level: 'success',
                    message: 'Le code promo a bien été appliqué',
                    timeout: 5000
                  })
                )
              );
            } else {
              this.store.dispatch(
                new TriggerAlert(
                  new Alert({
                    level: 'error',
                    message: "Le code promo est expiré ou n'est pas valide",
                    timeout: 5000
                  })
                )
              );
            }
          },
          () => {
            this.store.dispatch(
              new TriggerAlert(
                new Alert({
                  level: 'error',
                  message: "Le code promo n'existe pas",
                  timeout: 5000
                })
              )
            );
          }
        )
      );
    }
  }

  deletePromoCode() {
    this.store.dispatch(new DeletePromoCode());
  }

  isPromoCodeApplied() {
    if (this.cart && this.promoCode) {
      if (this.cart.hasPromoCode) {
        this.colorMsg = 'success';
        return 'Code promo appliqué';
      }

      this.colorMsg = 'mandatory';
      return `Code promo non appliqué (Min. de ${this.promoCode?.minPrice} € d'achat)`;
    }

    return '';
  }
}
