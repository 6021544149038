<section *ngIf="filtersAccommodation" class="filters-wrapper sticky">
  <vsk-filters-accommodation *ngIf="filtersAccommodation"
                             [(reset)]="clearFilters"
                             [filterByRegions]="true"
                             [filterByResorts]="true"
                             [filtersSearch]="filtersSearch$ | async"
                             [filters]="filtersAccommodation"
                             [nbParticipants]="nbParticipants"
                             [resorts]="allResorts$ | async"
                             class="filters-content">
  </vsk-filters-accommodation>
</section>

<div class="flex flex-col justify-between w-[100%] max-w-[1350px] mx-auto
            p-4 xl:px-0 sm:pt-0 md:pt-0
            xl:flex-col gap-[20px] lg:gap-[20px] md:gap-[0px]">
  <div *ngIf="!filtersAccommodation && (windowResource.width() | async | screen: 'tablet-above')"
       class="rounded-[20px] h-[70px]" vskSkeletonLoad></div>

  <div class="flex flex-col justify-between w-[100%]
              sm:mt-4 md:mt-4 mt-0
              xl:flex-col gap-[20px]">
    <h1 *ngIf="filtersAccommodation">
      <b> {{ filtersAccommodation.total }}</b>
      {{ filtersAccommodation.total > 1 ? 'Hébergements à louer' : 'Hébergement à louer' }} en montagne
    </h1>

    <div class="flex flex-row gap-x-4 justify-between w-full min-h-full
                xl:w-[1350px]">
      <div *ngIf="loading"
           class="flex flex-col gap-[20px] w-full mx-auto sm:gap-2 sm:pt-0 sm:px-4">
        <div *ngFor="let nb of [0,1]"
             class="rounded-[20px] h-[350px]" vskSkeletonLoad></div>
      </div>

      <ng-container *ngIf="establishments$ | async as establishments">
        <div *ngIf="!loading && !establishments.length"
             class="flex flex-col gap-y-4 w-full  justify-center items-center">
          <h3 class="text-center">
            Ooops, aucun hébergement disponible pour votre recherche ... <br>
            Ajustez vos dates ou ajustez vos filtres pour trouver la perle rare parmi nos offres !
          </h3>
          <atc-button (click)="resetFilters()"
                      color="secondary"
                      icon="trash"
                      label="Effacer les filtres ?"
                      size="medium">
          </atc-button>
        </div>

        <div (scrolled)="loadMoreAccommodation()"
             *ngIf="establishments?.length && !loading"
             [infiniteScrollDistance]="1.5"
             [infiniteScrollThrottle]="300"
             class="w-[100%] max-h-full pb-[5rem]
                    sm:gap-2
                    md:flex md:flex-col md:gap-4 md:pb-[2rem] slg:gap-4 slg:flex slg:flex-col
                    lg:flex-col grid-cols-1 lg:grid-cols-1 lg:grid lg:pb-[2rem] lg:gap-4
                    xl:flex-1 xl:grid-cols-1
                    2xl:grid-cols-1 xl:grid xl:gap-y-8 xl:gap-x-4"
             infiniteScroll>

          <atc-card-resort-scrollable (mouseenter)="overMarker(establishment.marker)"
                                      (mouseleave)="outMarker(establishment.marker)"
                                      (openPictures)="openPhotosPopup(establishment.pictures)"
                                      *ngFor="let establishment of establishments"
                                      [cardsLink]="establishment.linksCard"
                                      [label]="'logements'"
                                      [nbElements]="establishment.totalRooms"
                                      [nbNights]="nbNights"
                                      [nbParticipants]="nbParticipants"
                                      [nbPhotos]="establishment.pictures.length"
                                      [openInNewTab]="windowResource.width() | async | screen: 'small-desktop-above'"
                                      [pictures]="establishment.pictures"
                                      [resortName]="establishment.resorts[0].name"
                                      [title]="establishment.name">
          </atc-card-resort-scrollable>
        </div>
      </ng-container>

      <google-map
        (mapDragend)="boundsChanged()"
        *ngIf="windowResource.width() | async | screen: 'small-desktop-above'"
        [center]="center"
        [options]="options"
        [zoom]="zoom"
        class="sticky map-accommodation"
        height="500px"
        width="655px">

        <div class="refresh-map-on-zoom">
          <atc-input-checkbox [control]="refreshOnMoveMap">
            <p>Rafraîchir les hébergements en déplaçant la carte</p>
          </atc-input-checkbox>
        </div>

        <map-marker
          #mapMarker="mapMarker"
          (mapClick)="openInfoWindow(marker, mapMarker)"
          (mapMouseout)="outMarker(marker)"
          (mapMouseover)="overMarker(marker)"
          *ngFor="let marker of markers"
          [icon]="marker.getIcon()!"
          [label]="marker.getLabel()!"
          [options]="getOptions(marker)"
          [position]="marker.getPosition()!"
          [title]="marker.getTitle()!">
        </map-marker>

        <map-marker
          *ngIf="markerResort"
          [icon]="markerResort.getIcon()!"
          [options]="getOptions(markerResort)"
          [position]="markerResort.getPosition()!"
          [title]="markerResort.getTitle()!">
        </map-marker>

        <map-info-window>
          <atc-card-resort-scrollable *ngIf="pinpoint"
                                      [cardsLink]="pinpoint.linksCard"
                                      [label]="'logements'"
                                      [nbElements]="pinpoint.totalRooms"
                                      [nbNights]="nbNights"
                                      [nbParticipants]="nbParticipants"
                                      [nbPhotos]="pinpoint.pictures.length"
                                      [openInNewTab]="windowResource.width() | async | screen: 'small-desktop-above'"
                                      [pictures]="pinpoint.pictures"
                                      [pinpoint]="true"
                                      [resortName]="pinpoint.resorts[0].name"
                                      [title]="pinpoint.name">
          </atc-card-resort-scrollable>
        </map-info-window>
      </google-map>
    </div>
  </div>

  <atc-popup (closeChanged)="showPhotoPopup = false"
             *ngIf="showPhotoPopup"
             [showBody]="false"
             [slides]="pictures"></atc-popup>
</div>
