import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Experience } from '../shared/models/activity/experience';
import { ActivityFilters } from '../shared/models/const/activity-filters';
import { Criteria } from '../shared/models/criteria';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class ExperienceService extends RepositoryService {
  private URL_EXPERIENCE = 'api/webapp/experience';

  constructor(http: HttpClient) {
    super(http);
  }

  getExperienceById(
    criteria: Criteria,
    optionId: number
  ): Observable<Experience> {
    return this.post(
      this.URL_EXPERIENCE + '/by-id?id=' + optionId,
      criteria
    ).pipe(map((option) => new Experience({ ...option })));
  }

  getExperiencesByResortName(
    criteria: Criteria,
    resortFilters: ActivityFilters
  ): Observable<Experience[]> {
    return this.post(
      this.URL_EXPERIENCE +
        '/by-criteria?resortFilters=' +
        resortFilters +
        '&category=GENERAL',
      criteria
    ).pipe(
      map((experiences) =>
        experiences.map(
          (experience: Experience) => new Experience({ ...experience })
        )
      )
    );
  }

  getSkiExperiencesByResortName(
    criteria: Criteria,
    resortFilters: ActivityFilters
  ): Observable<Experience[]> {
    return this.post(
      this.URL_EXPERIENCE +
        '/by-criteria?resortFilters=' +
        resortFilters +
        '&category=SKIPASS',
      criteria
    ).pipe(
      map((experiences) =>
        experiences.map(
          (experience: Experience) => new Experience({ ...experience })
        )
      )
    );
  }

  getSkiClassExperiences(
    criteria: Criteria,
    resortFilters: ActivityFilters
  ): Observable<Experience[]> {
    return this.post(
      this.URL_EXPERIENCE +
        '/by-criteria?resortFilters=' +
        resortFilters +
        '&category=SKILESSON',
      criteria
    ).pipe(
      map((experiences) =>
        experiences.map(
          (experience: Experience) => new Experience({ ...experience })
        )
      )
    );
  }

  getExperiencesBySessionId(sessionId: string): Observable<Experience[]> {
    return this.get(
      this.URL_EXPERIENCE + '/by-session-id?sessionId=' + sessionId
    ).pipe(
      map((experiences) =>
        experiences.map(
          (experience: Experience) => new Experience({ ...experience })
        )
      )
    );
  }

  getSimilarExpByActivity(criteria: Criteria): Observable<Experience[]> {
    return this.post(
      this.URL_EXPERIENCE + '/similar-activities?&page=0&size=30',
      criteria
    ).pipe(
      map((experiences) =>
        experiences.map(
          (experience: Experience) => new Experience({ ...experience })
        )
      )
    );
  }
}
