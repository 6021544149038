import { Component, Input } from '@angular/core';
import { PricingUtils } from '../../utils/pricing-utils';
import { Cart } from '../models/cart/cart';

@Component({
  selector: 'vsk-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss']
})
export class PriceComponent {
  protected readonly PricingUtils = PricingUtils;

  promoPriceReduction: boolean;
  priceReduction: boolean;
  _cart: Cart;

  @Input() set cart(value: Cart) {
    this._cart = value;
    if (value) {
      this.priceReduction = value.getPublicPrice() !== value.getPrice();
      this.promoPriceReduction =
        value.getPublicPrice() !== value.getPriceWithPromo();
    }
  }

  get cart(): Cart {
    return this._cart;
  }
}
