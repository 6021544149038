<div *ngIf="cart$ | async as cart" class="vsk__content">
  <atc-stepper [currentOrder]="1">
    <!-- Step 1 -->
    <atc-step [done]="isDone(Step.CONNEXION)"
              [order]="0"
              [show]="step === Step.CONNEXION"
              class="flex flex-col gap-4"
              label="Connexion">
      <div class="flex flex-row justify-between items-center w-full pt-4">

        <atc-button (click)="goBackToResort()"
                    class="h-[26px]"
                    color="simple"
                    icon="chevron-left"
                    label="Retour à la station">
        </atc-button>
        <h1>Détails de Paiement</h1>
      </div>

      <!-- Mobile -->
      <div *ngIf="!(windowResource.width() | async | screen : 'small-desktop-above')"
           class="flex flex-col gap-y-2 md:mt-4 lg:mt-4">
        <div class="bg-white rounded-[30px] py-2 mb-2">
          <h2 class="text-center">Total TTC :
            <vsk-price [cart]="cart"></vsk-price>
          </h2>

          <h3>Paiement jusqu'à 3 fois sans frais</h3>

          <div class="mb-1 flex flex-col gap-4 relative">
            <vsk-promo-code [cart]="cart"
                            [paymentPage]=true
                            [promoCode]="cart.promoCode"
                            class="xl:flex-1 max-w-[80%] m-auto">
            </vsk-promo-code>
          </div>

          <p>Une question ? N'hésitez pas à contacter notre équipe au <br><a class="font-bold"
                                                                             href="tel:+33465847469">04
            65 84 74 69</a></p>
        </div>
        <vsk-step-connexion (next)="next()"
                            [endDate]="endDate$ | async"
                            [startDate]="startDate$ | async">
        </vsk-step-connexion>
      </div>

      <!-- Tablet & above -->
      <div *ngIf="windowResource.width() | async | screen : 'small-desktop-above'"
           class="flex flex-row gap-x-4">
        <vsk-step-connexion (next)="next()"
                            [endDate]="endDate$ | async"
                            [startDate]="startDate$ | async">
        </vsk-step-connexion>

        <div class="vsk_column flex-1 vsk__spacer px-8 py-4 mb-4 bg-white rounded-[30px] flex flex-col">
          <div class="vsk__header-payment flex items-center mb-[0.5rem]">
            <div>
              <h2 class="pr-2">Total TTC :
                <vsk-price [cart]="cart"></vsk-price>
              </h2>
              <h3>Paiement jusqu'à 3 fois sans frais</h3>
            </div>
            <vsk-promo-code [cart]="cart"
                            [paymentPage]=true
                            [promoCode]="cart.promoCode"
                            class="xl:flex-1 max-w-[55%]">
            </vsk-promo-code>
          </div>
          <p>Une question ? N'hésitez pas à contacter notre équipe au <a class="font-bold"
                                                                         href="tel:+33465847469">04 65
            84 74 69</a></p>
          <vsk-cart-content (redirectToEstablishmentChanged)="redirectToEstablishment($event)"
                            (redirectToExperienceChanged)="redirectToExperience($event)"
                            (redirectToMapChanged)="redirectToMap()"
                            [cart]="cart"
                            [isEcoTourismeChecked]="isEcoTourismeChecked$ | async"
                            [isPayment]="true"
                            class="mt-4">
          </vsk-cart-content>
        </div>
      </div>

    </atc-step>

    <!-- Step 2 -->
    <atc-step [done]="isDone(Step.PARTICIPANT)"
              [order]="1"
              [show]="step === Step.PARTICIPANT"
              class="flex flex-col gap-8 xl:pt-4 mt-4"
              label="Informations voyageurs">

      <div class="flex flex-row justify-between items-center w-full pt-4">

        <atc-button (click)="goBackToResort()"
                    class="h-[26px]"
                    color="simple"
                    icon="chevron-left"
                    label="Retour à la station">
        </atc-button>
        <h1>Détails de Paiement</h1>
      </div>

      <div class="flex flex-row gap-x-4">
        <div class="flex flex-col flex-1 justify-between">

          <ng-container *ngIf="!(windowResource.width() | async | screen : 'small-desktop-above')">
            <div class="bg-white rounded-[30px] mb-4 pb-4">

              <h2 class="text-center">Total TTC :
                <vsk-price [cart]="cart"></vsk-price>
              </h2>
              <h3>Paiement jusqu'à 3 fois sans frais</h3>

              <vsk-promo-code [cart]="cart"
                              [paymentPage]=true
                              [promoCode]="cart.promoCode"
                              class="xl:flex-1 max-w-[80%] m-auto">
              </vsk-promo-code>

              <p class="mb-4">Une question ? N'hésitez pas à contacter notre équipe au <br><a
                class="font-bold"
                href="tel:+33465847469">04
                65 84
                74 69</a></p>
            </div>
          </ng-container>
          <vsk-step-participants-information (next)="next()"
                                             (previous)="previous()"
                                             [cart]="cart$ | async"
                                             [participants]="participantsWithItems$ | async"
                                             [userSession]="user$ | async">
          </vsk-step-participants-information>
        </div>
        <div *ngIf="windowResource.width() | async | screen : 'small-desktop-above'"
             class="vsk_column vsk__spacer px-8 py-4 mb-4 bg-white rounded-[30px] flex flex-col">
          <div class="vsk__header-payment flex items-center mb-[0.5rem]">
            <div>
              <h2 class="pr-2">Total TTC :
                <vsk-price [cart]="cart"></vsk-price>
              </h2>
              <h3>Paiement jusqu'à 3 fois sans frais</h3>
            </div>
            <vsk-promo-code [cart]="cart"
                            [paymentPage]=true
                            [promoCode]="cart.promoCode"
                            class="xl:flex-1 max-w-[55%]">
            </vsk-promo-code>
          </div>
          <p>Une question ? N'hésitez pas à contacter notre équipe au <a class="font-bold"
                                                                         href="tel:+33465847469">04 65
            84 74 69</a></p>
          <vsk-cart-content (redirectToEstablishmentChanged)="redirectToEstablishment($event)"
                            (redirectToExperienceChanged)="redirectToExperience($event)"
                            [cart]="cart"
                            [isEcoTourismeChecked]="isEcoTourismeChecked$ | async"
                            [isPayment]="true"
                            class="mt-4">
          </vsk-cart-content>
        </div>
      </div>
    </atc-step>


    <!-- Step 3 -->
    <atc-step [done]="isDone(Step.PAYMENT)"
              [order]="2"
              [show]="step === Step.PAYMENT"
              class="flex flex-col gap-8 xl:pt-4 mt-4"
              label="Paiement">

      <div class="flex flex-row justify-between items-center w-full pt-4">

        <atc-button (click)="goBackToResort()"
                    class="h-[26px]"
                    color="simple"
                    icon="chevron-left"
                    label="Retour à la station">
        </atc-button>
        <h1>Détails de Paiement</h1>
      </div>
      <div class="flex flex-row gap-x-4">


        <div class="flex flex-col flex-1 sticky top-0 max-h-[1000px]">
          <ng-container *ngIf="!(windowResource.width() | async | screen : 'small-desktop-above')">
            <div class="bg-white rounded-[30px] mb-4 pb-4">

              <h2 class="text-center">Total TTC :
                <vsk-price [cart]="cart"></vsk-price>
              </h2>

              <h3>Paiement jusqu'à 3 fois sans frais</h3>

              <vsk-promo-code [cart]="cart"
                              [paymentPage]=true
                              [promoCode]="cart.promoCode"
                              class="xl:flex-1 max-w-[80%] m-auto">
              </vsk-promo-code>

              <p>Une question ? N'hésitez pas à contacter notre équipe au <br><a class="font-bold"
                                                                                 href="tel:+33465847469">04 65
                84 74
                69</a></p>
            </div>
          </ng-container>
          <vsk-step-payment (loading)="changeLoading($event)"
                            (paymentSuccess)="paymentSuccess()"
                            (previous)="previous()"
                            [error]="error"
                            [initForm]="step === Step.PAYMENT">
          </vsk-step-payment>
        </div>
        <div *ngIf="windowResource.width() | async | screen : 'small-desktop-above'"
             class="vsk_column vsk__spacer px-8 py-4 mb-4 bg-white rounded-[30px] flex flex-col">
          <div class="vsk__header-payment flex items-center mb-[0.5rem]">
            <div>
              <h2 class="pr-2">Total TTC :
                <vsk-price [cart]="cart"></vsk-price>
              </h2>
              <h3>Paiement jusqu'à 3 fois sans frais</h3>
            </div>
            <vsk-promo-code [cart]="cart"
                            [paymentPage]=true
                            [promoCode]="cart.promoCode"
                            class="xl:flex-1 max-w-[55%]">
            </vsk-promo-code>
          </div>
          <p>Une question ? N'hésitez pas à contacter notre équipe au <a class="font-bold"
                                                                         href="tel:+33465847469">04 65
            84 74 69</a></p>
          <vsk-cart-content (redirectToEstablishmentChanged)="redirectToEstablishment($event)"
                            (redirectToExperienceChanged)="redirectToExperience($event)"
                            [cart]="cart"
                            [isEcoTourismeChecked]="isEcoTourismeChecked$ | async"
                            [isPayment]="true"
                            class="mt-4">
          </vsk-cart-content>
        </div>

      </div>
    </atc-step>

    <!-- Step 4 -->
    <atc-step [done]="isDone(Step.CONFIRMATION)"
              [order]="3"
              [show]="step === Step.CONFIRMATION"
              class="vsk__inline"
              label="Votre séjour">
    </atc-step>
  </atc-stepper>
</div>

<div *ngIf="loading" class="vsk__container">
  <atc-loader color="secondary"></atc-loader>
</div>

<vsk-footer [isHome]="false"></vsk-footer>
