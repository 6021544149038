import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PromoCode } from '../shared/models/promo-code';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class PromoService extends RepositoryService {
  private URL_STATION = 'api/webapp/promo';

  constructor(http: HttpClient) {
    super(http);
  }

  getPromoCode(name: string): Observable<PromoCode> {
    return this.get(
      this.URL_STATION + '/get-promo-code-by-name?name=' + name
    ).pipe(map((promoCode: any) => new PromoCode(promoCode)));
  }

  getPromoCodeOrder(transactionId: string): Observable<PromoCode> {
    return this.get(
      this.URL_STATION + '/get-promo-code-order?transactionId=' + transactionId
    ).pipe(map((promoCode: any) => new PromoCode(promoCode)));
  }
}
