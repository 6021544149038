<atc-button (click)="showCart = true"
            *ngIf="!(windowResource.width() | async | screen: 'small-desktop-above')"
            [fullWidth]="true"
            class="flex flex-1"
            color="ternary"
            label="Voir le détail de mon panier">
</atc-button>

<div *ngIf="openRegisterCard" class="bg-white rounded-[30px] flex flex-col gap-4 p-8">
  <h3>Inscription</h3>

  <p class="text-center mb-4">
    Veuillez compléter ce formulaire ou vous connecter pour finaliser votre réservation et profiter d'un séjour sans
    soucis.
  </p>

  <p *ngIf="error" class="vsk__error-text">{{ error }}</p>
  <ng-container *ngIf="!inscriptionFinalize">
    <div class="flex flex-row gap-2">
      <!-- Prénom -->
      <atc-input [control]="firstNameForm"
                 errorMessage="Champs obligatoire"
                 id="id__input-step-connexion-firstname"
                 label="Prénom"
                 placeholder="Julie">
      </atc-input>

      <!-- Nom -->
      <atc-input [control]="lastNameForm"
                 [focus]="true"
                 errorMessage="Champs obligatoire"
                 id="id__input-step-connexion-lastname"
                 label="Nom"
                 placeholder="Dupont">
      </atc-input>
    </div>

    <div class="flex flex-row gap-2">
      <!-- Email-->
      <atc-input [control]="emailForm"
                 [errorMessage]="'Email invalide'"
                 errorMessage="Adresse email malformée"
                 id="id__input-step-connexion-email"
                 label="E-mail"
                 placeholder="julie@gmail.com"
                 type="email">
      </atc-input>

      <!-- Date de naissance -->
      <atc-input-date (dateChanged)="birthdateChange($event)"
                      *ngIf="emailForm.valid"
                      [addValidator]="[Validators.required]"
                      [markAsTouched]="formTouched"
                      errorMessage="Date de naissance obligatoire"
                      id="id__input-step-connexion-birthdate"
                      label="Date de naissance">
      </atc-input-date>
    </div>

    <!-- Password -->
    <atc-input *ngIf="emailForm.valid"
               [control]="passwordForm"
               [errorMessage]="getPasswordErrorMessage()"
               id="id__input-step-connexion-password"
               label="Mot de passe"
               placeholder="***********"
               type="password">
    </atc-input>

    <!-- Password confirmation -->
    <atc-input *ngIf="emailForm.valid"
               [control]="passwordConfirmationForm"
               errorMessage="Les mots de passe ne sont pas similaires"
               id="id__input-step-connexion-password-confirmation"
               label="Confirmation de mot de passe"
               placeholder="***********"
               type="password">
    </atc-input>

    <p *ngIf="passwordConfirmationForm.valid">Vous joindre</p>

    <!-- Address -->
    <div *ngIf="passwordConfirmationForm.valid" class="flex flex-col gap-0">
      <atc-google-search-places
        (addressChanged)="geocodeAddress($event)"
        [control]="addressForm"
        [googleMapsService]="getLoader()"
        id="id__input-edit-resort-address"
        label="Adresse">
      </atc-google-search-places>
      <p *ngIf="addressForm.touched && addressForm.value.length <= 5"
         class="error">Champs obligatoire</p>
    </div>

    <div class="flex flex-row gap-2">
      <!-- City -->
      <atc-input *ngIf="addressForm.valid && addressForm.value && addressForm.value.length > 5"
                 [control]="cityForm"
                 errorMessage="Champs obligatoire"
                 icon="map"
                 id="id__input-my-account-city"
                 label="Ville"
                 placeholder="Ville"
                 type="text"></atc-input>

      <!-- Post code -->
      <atc-input *ngIf="addressForm.valid && addressForm.value && addressForm.value.length > 5"
                 [control]="postCodeForm"
                 errorMessage="Champs obligatoire"
                 id="id__input-my-account-postCode"
                 label="Code postal"
                 mask="00000"
                 placeholder="69000"
                 type="number"></atc-input>
    </div>

    <div class="flex flex-row gap-2">
      <!-- Pays -->
      <atc-select *ngIf="addressForm.valid && addressForm.value && addressForm.value.length > 5"
                  [control]="countryForm"
                  [optionElements]="countries"
                  id="id__input-my-account-country"
                  label="Pays"></atc-select>

      <!-- Phone -->
      <atc-input *ngIf="passwordConfirmationForm.valid" [control]="phoneForm" [dropSpecialCharacters]="false"
                 [specialCharacters]="[' ']"
                 errorMessage="Champs obligatoire ne pouvant contenir uniquement des chiffres*" icon="phone"
                 label="Téléphone"
                 mask="00 00 00 00 00" placeholder="06 01 02 03 04"></atc-input>
    </div>

    <!-- CGU -->
    <atc-input-checkbox *ngIf="passwordConfirmationForm.valid"
                        [control]="cguCheckedForm"
                        [mandatory]="true"
                        [markAsTouched]="formTouched"
                        errorMessage="Champs obligatoire">
      <p>En cochant cette case, j'accepte les
        <a class="underline" href="/terms-of-use" target="_blank">Conditions générales d'utilisation</a> et les
        <a class="underline" href="/selling-conditions" target="_blank">Conditions générales de vente</a> de
        VeryMountain
      </p>
    </atc-input-checkbox>

    <atc-input-checkbox *ngIf="passwordConfirmationForm.valid" [control]="newsletter">
      <p>Je souhaites recevoir les bons plans VeryMountain</p>
    </atc-input-checkbox>

    <p *ngIf="formTouched" class="text-center error">
      Merci de finaliser votre inscription pour passer à l'étape suivante et payer.
    </p>

    <!-- Confirmation -->
    <atc-button (click)="registerAccount()"
                [fullWidth]="true"
                class="flex"
                color="primary"
                id="id__button-step-connexion-signup"
                label="S'inscrire">
    </atc-button>
    <hr class="my-4" />
    <div class="flex align-center justify-center">
      <atc-button
        (click)="openLoginPopup = true"
        color="ternary"
        icon="person"
        id="id__button-connect-google"
        label="S'inscrire avec Google">
      </atc-button>
    </div>
  </ng-container>
</div>
<h3 class="w-full text-center">ou</h3>
<div class="bg-white rounded-[30px] flex flex-col gap-4 p-8 justify-center items-center xl:mt-4">
  <h3>J'ai déjà un compte</h3>
  <atc-button (click)="openLoginPopup = true"
              color="ternary"
              icon="person"
              id="id__button-connect-account"
              label="J'ai déjà un compte">
  </atc-button>
</div>
<div *ngIf="loading" class="vsk__container">
  <atc-loader color="secondary"></atc-loader>
</div>

<vsk-login (closeChanged)="openLoginPopup = false" *ngIf="openLoginPopup">
</vsk-login>

<vsk-cart [(showCart)]="showCart"
          [canDeleteItem]="false">
</vsk-cart>
