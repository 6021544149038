import { FormControl, FormGroup } from '@angular/forms';
import { OptionElement } from 'atomic-lib';
import { FilterCount } from '../shared/models/filters/filter-count';

export class FormUtils {
  public static findInvalidControls(form: FormGroup): string[] {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  public static disabledWithNoQuantity(
    form: FormControl<any>,
    quantity: number
  ) {
    if (quantity) {
      form.enable();
    } else {
      form.disable();
    }
  }

  public static disabledControlWithNoQuantity(
    element: OptionElement<any>,
    filterCount: number
  ) {
    if (filterCount) {
      element.quantity = filterCount;
      element.control?.enable();
    } else {
      element.quantity = 0;
      element.control?.disable();
    }
  }

  public static disabledControlByFilterCountWithNoQuantity(
    element: OptionElement<any>,
    filterCount: FilterCount<any> | undefined
  ) {
    if (filterCount?.quantity) {
      element.quantity = filterCount.quantity;
      element.control?.enable();
    } else {
      element.quantity = 0;
      element.control?.disable();
    }
  }

  public static upsertControlForFilter(
    elements: OptionElement<any>[],
    filtersCount: FilterCount<any>[],
    selected: any[] = []
  ) {
    if (!elements?.length) {
      return filtersCount.map((filter) => {
        const control = new FormControl<boolean>(
          !!selected.find((val) => val === filter.id)
        );

        if (filter.quantity === 0) {
          control.disable();
        }

        return {
          id: filter.id,
          label: filter.name,
          control: control,
          quantity: filter.quantity,
          hideQuantity: filter.hideQuantity
        };
      });
    } else {
      elements.forEach((element) => {
        const filterCount = filtersCount.find(
          (filter) => filter.id === element.id
        );

        if (selected?.length) {
          element.control?.setValue(
            !!selected?.find((val) => val === element.id)
          );
        }

        FormUtils.disabledControlByFilterCountWithNoQuantity(
          element,
          filterCount
        );
      });
      return elements;
    }
  }
}
