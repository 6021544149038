import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestReceipt } from '../shared/models/request-receipt';
import { TokenPayment } from '../shared/models/stripe/token-payment';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService extends RepositoryService {
  private URL_STATION = 'api/webapp/payment';

  constructor(http: HttpClient) {
    super(http);
  }

  pay(
    promoCode: string | null,
    sessionId: string,
    ecoTourismChart: boolean
  ): Observable<TokenPayment> {
    return this.post(this.URL_STATION + '/pay', {
      sessionId,
      promo: promoCode,
      ecoTourismChart: ecoTourismChart
    });
  }

  completePayment(requestReceipt: RequestReceipt): Observable<TokenPayment> {
    return this.post(this.URL_STATION + '/complete-payment', requestReceipt);
  }
}
