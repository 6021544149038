import { DecimalPipe, Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppState } from '../app.state';
import { ChangeCartDrawerState } from '../cart/cart.action';
import { CartState } from '../cart/cart.state';
import { FiltersState } from '../filters.state';
import { WindowResource } from '../resource/window.resource';
import { NavigateService } from '../service/navigate.service';
import { StationService } from '../service/station.service';
import { RxjsComponent } from '../shared/component/rxjs.component';
import { ActivitiesByCategory } from '../shared/models/activity/activities-by-category';
import { Activity } from '../shared/models/activity/activity';
import { Cart } from '../shared/models/cart/cart';
import { WhiteLabel } from '../shared/models/enum/white-label.enum';
import { Participant } from '../shared/models/participant/participant';
import { Period } from '../shared/models/period';
import { Resort } from '../shared/models/resort/resort';
import { UserSession } from '../shared/models/user-session';
import { NavbarState } from './navbar.state';

@Component({
  selector: 'vsk-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent extends RxjsComponent implements OnInit {
  @Select(AppState.user) user$: Observable<UserSession>;
  @Select(AppState.isUserLoggedIn) loggedIn$: Observable<boolean>;
  @Select(CartState.quantityItems) quantityItems$: Observable<number>;
  @Select(CartState.cart) cart$: Observable<Cart>;
  @Select(NavbarState.period) period$: Observable<Period>;
  @Select(NavbarState.activities) activities$: Observable<Activity[]>;
  @Select(NavbarState.activitiesByCategories)
  activitiesByCategories$: Observable<ActivitiesByCategory[]>;
  @Select(NavbarState.selectedActivities) selectedActivities$: Observable<
    Activity[]
  >;
  @Select(NavbarState.participants) participants$: Observable<Participant[]>;
  @Select(FiltersState.isChangingFilters)
  isChangingFilters$: Observable<boolean>;
  @Select(FiltersState.isFoncia) isFoncia$: Observable<boolean>;
  @Select(FiltersState.whiteLabel) whiteLabel$: Observable<WhiteLabel>;
  @Select(FiltersState.searchMode)
  searchMode$: Observable<'all' | 'accommodation' | 'experience'>;
  @Select(NavbarState.showInNavbar) showInNavbar$: Observable<boolean>;
  @Select(NavbarState.isDatePickerOpen) isDatePickerOpen$: Observable<boolean>;
  @Select(NavbarState.isActivityPickerOpen)
  isActivityPickerOpen$: Observable<boolean>;
  @Select(NavbarState.isParticipantPickerOpen)
  isParticipantPickerOpen$: Observable<boolean>;
  @Select(NavbarState.isFiltersPickerOpen)
  isFiltersPickerOpen$: Observable<boolean>;
  @Select(NavbarState.withAccommodation)
  withAccommodation$: Observable<boolean>;
  @Select(NavbarState.withSkiPackage) withSkiPackage$: Observable<boolean>;
  @Select(NavbarState.withMaterial) withMaterial$: Observable<boolean>;
  @Select(NavbarState.hasAlterParticipants)
  hasAlterParticipants$: Observable<boolean>;
  @Select(CartState.propositionCart) propositionCart$: Observable<Cart>;
  @Select(CartState.isOpen) isCartOpen$: Observable<boolean>;

  showMenu = false;
  currentPage: 'Home' | 'Map' | 'Resort' | 'none';
  resorts$: Observable<Resort[]>;

  @Input() isLandingPage = false;
  @Input() isMapPage = false;
  @Input() isPaymentPage = false;
  @Input() showSearchPopup = false;

  constructor(
    private router: Router,
    public navigateService: NavigateService,
    private location: Location,
    private decimalPipe: DecimalPipe,
    private stationService: StationService,
    public windowResource: WindowResource,
    private store: Store
  ) {
    super();
  }

  get isDesktop() {
    return this.windowResource.isAboveTablet();
  }

  ngOnInit(): void {
    this.router.events.subscribe(() => {
      if (this.location.path().indexOf('/recherche-sejours') !== -1) {
        this.currentPage = 'Map';
        return;
      }

      this.currentPage =
        this.location.path().indexOf('/station') !== -1 ||
        this.location.path().indexOf('/station/informations') !== -1
          ? 'Resort'
          : 'none';
    });

    this.resorts$ = this.stationService.getAllStations();
    this.propositionCart$.subscribe((value) =>
      this.store.dispatch(new ChangeCartDrawerState(!!value))
    );
  }

  openCart(): void {
    this.store.dispatch(new ChangeCartDrawerState(true));
  }

  openMenu(): void {
    this.showMenu = true;
  }

  showDatePicker(isVisible: boolean) {
    return (
      (!this.isPaymentPage && isVisible && this.isLandingPage) ||
      (!this.isPaymentPage && !this.isLandingPage)
    );
  }

  goBack() {
    this.location.back();
  }

  labelPayment(cart: Cart) {
    if (!cart || cart.isEmpty) {
      return '';
    }

    return `Réserver (${this.decimalPipe.transform(cart.getPrice(), '1.2-2')} €)`;
  }

  goToPayment() {
    if (this.store.selectSnapshot(FiltersState.isFoncia)) {
      this.navigateService.navigateWithQueryParams('/paiement', {
        isFoncia: true
      });
      return;
    }

    this.navigateService.navigateWithQueryParams('/paiement');
  }
}
