import { FormControl } from '@angular/forms';
import moment, { Moment } from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { Origin } from '../enum/origin.enum';
import { SkiPass } from '../enum/ski-pass.enum';
import { Participant } from '../participant/participant';
import { Pass } from '../pass';
import { Package } from './package';
import { PackageGroup } from './package-group';

export class ItemCart {
  uuid = uuidv4();
  timeSlotModelId: number;
  internshipId: number;
  origin: Origin;
  item?: Package;
  group?: PackageGroup;
  resort: string;
  startDate: Moment;
  endDate: Moment;
  participants: Participant[] = [];
  pass?: Pass;
  withAccommodation: boolean;
  partner: string;
  pricePass: number;
  selectForm = new FormControl<boolean>(true);

  constructor(obj: Partial<ItemCart>) {
    Object.assign(this, obj);
    this.startDate = moment(this.startDate);
    this.endDate = moment(this.endDate);

    if (this.item) {
      this.item = new Package({ ...this.item });
    }

    if (this.group) {
      this.group = new PackageGroup({ ...this.group });
    }

    if (this.participants) {
      this.participants = this.participants.map(
        (participant) => new Participant({ ...participant })
      );
    }
  }

  get price(): number {
    const pricePassTotal = this.getPricePassTotal();

    if (this.group) {
      return this.group.price + pricePassTotal;
    }

    return (
      (this.item?.priceByParticipantSizeAndDates(
        this.participants.length,
        this.startDate
      ) ?? 0) *
        this.participants.length +
      pricePassTotal
    );
  }

  get pricePublic(): number {
    const pricePassTotal = this.getPricePassTotal();

    if (this.group) {
      return this.group.publicPrice + pricePassTotal;
    }

    return (
      (this.item?.priceByParticipantSizeAndDates(
        this.participants.length,
        this.startDate,
        true
      ) ?? 0) *
        this.participants.length +
      pricePassTotal
    );
  }

  get title(): string {
    if (this.item) {
      return this.activity + ' · ' + this.item.experience.name;
    }

    return (
      this.activity + ' · ' + this.group?.packages[0].experience.name || ''
    );
  }

  get partnerName(): string {
    if (this.item) {
      return this.partner;
    }

    return this.group?.packages[0].experience.partnerName || '';
  }

  get activity(): string {
    if (this.item) {
      return this.item.experience.activity.name;
    }

    return this.group?.packages[0].experience.activity.name || '';
  }

  get photo(): string {
    if (this.item) {
      return this.item.experience.pictures[0];
    }

    return this.group?.packages[0].experience.pictures[0] || '';
  }

  get ski(): boolean {
    if (this.item) {
      return this.item.experience.ski;
    }

    return this.group?.packages[0].experience.ski || false;
  }

  get equipment(): boolean {
    if (this.item) {
      return this.item.experience.equipment;
    }

    return this.group?.packages[0].experience.equipment || false;
  }

  get skiPass(): Pass | undefined {
    if (this.item) {
      return new Pass({
        price: this.pricePass,
        type: this.item.experience.skiPassType
      });
    }

    return new Pass({
      price: this.pricePass,
      type: this.group?.packages[0].experience.skiPassType
    });
  }

  private getPricePassTotal() {
    let pricePassTotal = 0;

    if (this.ski) {
      const pricePass = this.skiPass?.price as number;
      const totalParticipant = this.participants.filter((participant) => {
        if (this.skiPass?.type === SkiPass.SKIDATA) {
          return (
            (!participant.cardSkidata && participant.hasCard) ||
            !participant.hasCard
          );
        }

        return (
          (!participant.cardAxess && participant.hasCard) ||
          !participant.hasCard
        );
      }).length;

      pricePassTotal = pricePass * totalParticipant;
    }
    return pricePassTotal;
  }
}
