<section *ngIf="filtersAccommodation" [ngClass]="{'sticky': sticky$ | async}" class="filters-wrapper z-[3]">
  <vsk-filters-accommodation *ngIf="filtersAccommodation"
                             [(reset)]="clearFilters"
                             [filtersSearch]="filtersSearch$ | async"
                             [filters]="filtersAccommodation"
                             [resort]="(resort$ | async)?.id"
                             class="filters-content">
  </vsk-filters-accommodation>
</section>

<div class="flex flex-col justify-between w-[100%] max-w-[1350px] mx-auto p-4 sm:pt-2 md:pt-2 mb-16
            xl:px-0 xl:flex-col gap-[20px] lg:gap-[20px] md:gap-[0px]">
  <div *ngIf="!filtersAccommodation && (windowResource.width() | async | screen: 'tablet-above')"
       class="rounded-[20px] h-[70px]" vskSkeletonLoad></div>

  <ng-container *ngIf="establishments$ | async as establishments">
    <div class="flex flex-col justify-between w-[100%]
                  xl:flex-col gap-[20px]">

      <h1 *ngIf="filtersAccommodation">
        <b> {{ filtersAccommodation.total }}</b>
        {{ filtersAccommodation.total > 1 ? 'Hébergements à louer · ' : 'Hébergement à louer · ' }}
        <ng-container *ngIf="resort$ | async as resort">
          {{ resort.name }}
        </ng-container>
      </h1>

      <div class="flex flex-row gap-x-4 justify-between w-full min-h-full
                  xl:w-[1350px]">

        <div (scrolled)="loadMoreAccommodation()"
             [infiniteScrollContainer]="elRef.nativeElement.parentElement"
             [infiniteScrollDistance]="2"
             class="w-[100%] max-h-full pb-[5rem]
                    sm:gap-2
                    md:flex md:flex-col md:gap-4 md:pb-[2rem] slg:gap-4 slg:flex slg:flex-col
                    lg:flex-col grid-cols-1 lg:grid-cols-1 lg:grid lg:pb-[2rem] lg:gap-4
                    xl:flex-1 xl:grid-cols-1
                    2xl:grid-cols-1 xl:grid xl:gap-y-8 xl:gap-x-4"
             infiniteScroll>

          <ng-container *ngIf="loading">
            <div *ngFor="let nb of [0,1,2]" class="rounded-[20px] h-[350px] w-[100%]" vskSkeletonLoad></div>
          </ng-container>

          <ng-container *ngIf="establishments?.length && !loading">
            <atc-card-resort-scrollable (mouseenter)="overMarker(establishment.marker)"
                                        (mouseleave)="outMarker(establishment.marker)"
                                        (openPictures)="openPhotosPopup(establishment.pictures)"
                                        *ngFor="let establishment of establishments"
                                        [cardsLink]="establishment.linksCard"
                                        [label]="'logements'"
                                        [nbElements]="establishment.totalRooms"
                                        [nbNights]="nbNights"
                                        [nbPhotos]="establishment.pictures.length"
                                        [pictures]="establishment.pictures"
                                        [title]="establishment.name">
            </atc-card-resort-scrollable>
          </ng-container>
        </div>

        <google-map
          (mapDragend)="boundsChanged()"
          *ngIf="windowResource.width() | async | screen: 'small-desktop-above'"
          [center]="center"
          [options]="options"
          [zoom]="zoom"
          class="sticky map-accommodation"
          height="500px">

          <div class="refresh-map-on-zoom">
            <atc-input-checkbox [control]="refreshOnMoveMap">
              <p>Rafraîchir les hébergements en déplaçant la carte</p>
            </atc-input-checkbox>
          </div>

          <map-marker
            #mapMarker="mapMarker"
            (mapClick)="openInfoWindow(marker, mapMarker)"
            (mapMouseout)="outMarker(marker)"
            (mapMouseover)="overMarker(marker)"
            *ngFor="let marker of markers"
            [icon]="marker.getIcon()!"
            [label]="marker.getLabel()!"
            [options]="getOptions(marker)"
            [position]="marker.getPosition()!"
            [title]="marker.getTitle()!">
          </map-marker>

          <map-marker
            *ngIf="markerResort"
            [icon]="markerResort.getIcon()!"
            [options]="getOptions(markerResort)"
            [position]="markerResort.getPosition()!"
            [title]="markerResort.getTitle()!">
          </map-marker>

          <map-info-window>
            <atc-card-resort-scrollable *ngIf="pinpoint"
                                        [cardsLink]="pinpoint.linksCard"
                                        [label]="'logements'"
                                        [nbElements]="pinpoint.totalRooms"
                                        [nbNights]="nbNights"
                                        [nbParticipants]="nbParticipants"
                                        [nbPhotos]="pinpoint.pictures.length"
                                        [pictures]="pinpoint.pictures"
                                        [pinpoint]="true"
                                        [resortName]="pinpoint.resorts[0].name"
                                        [title]="pinpoint.name">
            </atc-card-resort-scrollable>
          </map-info-window>
        </google-map>
      </div>
    </div>

    <ng-container *ngIf="!establishments.length && !loading">
      <h3 class="flex w-full my-4 justify-center items-center text-center">
        Ooops, aucun hébergement disponible pour votre recherche ... <br>
        Ajustez vos dates ou ajustez vos filtres pour trouver la perle rare parmi nos offres !
      </h3>

      <div class="flex flex-row gap-x-4 mb-64 items-center mx-auto">
        <atc-button (click)="clearFilters = true"
                    color="secondary"
                    icon="trash"
                    label="Effacer les filtres ?"
                    size="medium">
        </atc-button>

        <a queryParamsHandling="merge"
           routerLink="/recherche-hebergements"
           routerLinkActive="true">
          <atc-button color="ternary"
                      icon="home"
                      label="Voir les hébergements de nos autres stations">
          </atc-button>
        </a>
      </div>
    </ng-container>

    <atc-popup (closeChanged)="showPhotoPopup = false"
               *ngIf="showPhotoPopup"
               [showBody]="false"
               [slides]="pictures"></atc-popup>
  </ng-container>
</div>

<vsk-footer [isHome]="false"></vsk-footer>
